/*!
 * FLAT KIT v1.2.0
 *
 * Copyright MosaicPro
 * http://www.mosaicpro.biz
 *
 * Designed and built exclusively for sale @Envato Marketplaces.
 */
body {
    /*min-width: 800px;*/
    font-family: 'Roboto', sans-serif;
    background: #ffffff;
    font-size: 13px;
    color: #7c7c7c;
    position: relative;
}
*,
a:focus {
    outline: none !important;
}
button:focus {
    outline: none !important;
}
button::-moz-focus-inner {
    border: 0;
}
a:hover {
    color: #333333;
    cursor: pointer;
}
a:hover,
a:hover .text-underline {
    text-decoration: none;
}
a {
    color: var(--secondary-color);
    outline: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
    margin: 0 0 5px;
    font-weight: 400;
    color: #4d4d4d;
}
h1 span span,
h2 span span,
h3 span span,
h4 span span,
h5 span span,
h6 span span {
    color: #afafaf;
    font-weight: 300;
    padding: 0 5px;
    font-style: italic;
}
h1 span span,
h2 span span,
h3 span span {
    font-size: 50%;
    text-transform: none;
    padding: 0;
    position: relative;
    top: -3px;
    font-weight: 300;
}
h4 {
    font-weight: normal;
}
h1 {
    line-height: 40px;
}
h2,
h3 {
    line-height: 30px;
}
h2 {
    font-size: 26px;
}
.heading-buttons h1,
.heading-buttons h2,
.heading-buttons h3,
.heading-buttons h4,
.heading-buttons h5,
.heading-buttons h6 {
    display: inline-block;
    margin: 0 15px;
}
.heading-buttons .buttons {
    margin-top: 3px;
    margin-right: 15px;
    text-align: right;
}
.heading-buttons .btn + .btn {
    margin-left: 15px;
}
p {
    margin: 0 0 10px;
    line-height: normal;
}
ul.icons {
    text-align: left;
    padding: 0;
}
ul.icons li {
    display: block;
    padding: 0 0 0 25px;
    line-height: 22px;
    color: #7c7c7c;
}
ul.icons li i:before {
    color: #7c7c7c;
    font-size: 17px;
    left: 0;
    top: 4px;
}
input,
button,
select,
textarea {
    font-family: 'Roboto', sans-serif;
}
blockquote {
    margin: 0;
    padding: 0 0 0 5px;
}
blockquote,
blockquote.pull-right {
    border: none;
}
blockquote p {
    font-weight: 400;
    font-size: 13px;
    margin: 0 0 10px;
}
blockquote small {
    color: var(--secondary-color);
}
.separator {
    padding: 15px 0;
    display: block;
}
.separator.bottom {
    padding: 0 0 15px;
}
.separator.top {
    padding: 15px 0 0;
}
hr.separator {
    padding: 0;
}
hr.separator.top {
    margin: 15px 0 0;
}
hr.separator.bottom {
    margin: 0 0 15px;
}
@media print {
    html,
    body {
        height: auto !important;
        min-height: none !important;
    }
    @page {
        size: auto;
        margin: 10mm 10mm 10mm 10mm;
    }
    #content {
        margin: 0 !important;
        padding: 0 !important;
    }
    .breadcrumb,
    .filter-bar {
        display: none !important;
    }
    .table td,
    .table th {
        vertical-align: middle;
    }
    .table th {
        padding: 3px;
        text-align: center;
    }
    .table img {
        float: left;
    }
}
.map_canvas img {
    max-width: none !important;
}
h1 [class*='icon-'],
h2 [class*='icon-'],
h3 [class*='icon-'],
h4 [class*='icon-'],
h5 [class*='icon-'],
h6 [class*='icon-'] {
    font-size: 80%;
    vertical-align: middle;
    margin-top: -3px;
}
p.separator {
    display: block;
    height: 48px;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
}
p.separator:before {
    content: '';
    position: absolute;
    display: block;
    background: #f4f4f4;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    right: 0;
}
p.separator i {
    background: #fff;
    padding: 0 10px;
    margin-top: -18px;
    margin-left: -25px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
}
.innerLR {
    position: relative;
}
.innerAll {
    padding: 15px;
    position: relative;
}
.innerT {
    padding-top: 15px;
    position: relative;
}
.center {
    text-align: center !important;
}
.margin-bottom-none {
    margin-bottom: 0 !important;
}
.margin-right-none {
    margin-right: 0 !important;
}
.margin-none {
    margin: 0 !important;
}
.padding-none {
    padding: 0 !important;
}
.border-none {
    border: none !important;
}
.box-shadow-none {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.text-weight-regular {
    font-weight: normal !important;
}
.text-primary {
    color: var(--secondary-color) !important;
}
.text-regular {
    color: #444 !important;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-large {
    font-size: 35px !important;
    line-height: 40px !important;
}
.text-underline {
    text-decoration: underline;
}
.text-right {
    text-align: right !important;
}
.inline-block {
    display: inline-block !important;
}
.overflow-y-auto {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
}
.overflow-hidden {
    position: relative;
    overflow: hidden;
}
.row-merge {
    position: relative;
}
.row-merge > [class*='span'] ~ [class*='span'] {
    margin-left: 0;
}
.row-merge > [class*='span'] ~ [class*='span']:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #efefef;
    content: '';
}
.row-merge > [class*='span'] ~ [class*='span3'] {
    width: 25.26315789473684%;
}
.row-merge > [class*='span'] ~ [class*='span4'] {
    width: 33.68421052631579%;
}
.row-merge > [class*='span'] ~ [class*='span5'] {
    width: 42.10526315789473%;
}
.row-merge > [class*='span'] ~ [class*='span6'] {
    width: 50.74626865671642%;
}
.row-merge > [class*='span'] ~ [class*='span8'] {
    width: 67.66169154228855%;
}
.row-merge > [class*='span'] ~ [class*='span9'] {
    width: 75.78947368421052%;
}
.row-merge.border-top,
.row-merge ~ .row-merge {
    border-top: 1px solid #efefef;
}
.row-merge.border-bottom {
    border-bottom: 1px solid #efefef;
}
.row-merge .fill-column:before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: inherit;
    background: #f4f4f4;
    content: '';
}
#wrapper {
    overflow: hidden;
    /*height:100%;*/
    /*overflow-y: scroll;*/
    -webkit-overflow-scrolling: touch;
    float: left;
    width: 100%;
    background-color: #f7f7f7;
    background-image: url(../vendor/img/new_bz_logo.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
}
html body > .container-fluid {
    padding: 0;
    background: #ffffff;
}
html body > .container-fluid.fixed {
    margin: 30px auto 30px;
    margin: 0 auto;
    width: 1024px;
}
html.sidebar body > .container-fluid.fixed.menu-left {
    border-right: 1px solid #efefef;
}
html.sidebar body > .container-fluid.fixed.menu-right {
    border-left: 1px solid #efefef;
}
html:not(.sidebar) body > .container-fluid.fixed.menu-left {
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
}
#content {
    position: relative;
    overflow: hidden;
    margin: 0;
    /*min-width: 320px; */
    /* REMOVED BY BLOCKBASE */
    min-height: 500px;
    /*padding: 0 0 15px;*/
}
#content .view-content-wrapper > .menubar {
    border-left: none;
    border-right: none;
}
#content .view-content-wrapper > p {
    padding: 0 15px 5px;
}
#content .view-content-wrapper > h1,
#content .view-content-wrapper > h2,
#content .view-content-wrapper > h3,
#content .view-content-wrapper > h4,
#content .view-content-wrapper > h5,
#content .view-content-wrapper > h6 {
    margin: 10px 15px 10px;
}
#content .view-content-wrapper > div.innerLR.innerLR-head > h1 {
    font-size: 28px;
    color: #ffffff;
    display: inline;
    padding-right: 15px;
    margin-right: 10px;
    border-right: solid 1px #ffffff;
}
#content .view-content-wrapper > .filter-bar {
    border-right: none;
    border-left: none;
}
#content .heading-buttons {
    margin-top: 15px;
}
#content .heading-arrow {
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    margin: 0 0 15px;
    padding: 0 15px;
    font-size: 11pt;
    height: 35px;
    line-height: 30px;
    position: relative;
}
#content .heading-arrow:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 8px solid #efefef;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    bottom: 0;
    left: 15px;
}
#content .heading-arrow.margin-bottom-none {
    margin-bottom: 0;
}
#content .view-content-wrapper > .widget {
    border-left: 0;
    border-right: 0;
}
#content .view-content-wrapper > .widget .widget-head,
#content .view-content-wrapper > .widget .widget-body {
    border-left: 0;
    border-right: 0;
}
#content .view-content-wrapper > .widget:last-child {
    margin: 0;
}
#content .view-content-wrapper > .table {
    border-left: none;
    border-right: none;
}
#content .view-content-wrapper > .table tr td:first-child {
    border-left: none;
}
#footer {
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 10000;
    background: #f4f4f4;
    text-align: center;
    padding: 0 10px;
    height: 48px;
    line-height: 48px;
}
#footer a {
    color: #686868;
    text-decoration: underline;
}

html.sticky-top:not(.animations-gpu) .navbar.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
@media (min-width: 768px) {
    html.sticky-sidebar.sidebar:not(.animations-gpu) body {
        overflow: hidden;
    }
    html.sticky-sidebar.sidebar:not(.animations-gpu) body.login {
        overflow: auto;
    }
    html.sticky-sidebar.sidebar:not(.animations-gpu),
    html.sticky-sidebar.sidebar:not(.animations-gpu) body,
    html.sticky-sidebar.sidebar:not(.animations-gpu) body > .container-fluid,
    html.sticky-sidebar.sidebar:not(.animations-gpu) #wrapper {
        min-height: 100%;
        height: 100%;
    }
    html.sticky-sidebar.sidebar:not(.animations-gpu) #wrapper {
        margin-bottom: -48px;
    }
    html.sticky-sidebar.sidebar:not(.animations-gpu) #menu {
        position: fixed;
        bottom: 0;
    }
    html.sticky-sidebar.sidebar:not(.animations-gpu) #content {
        /*padding-bottom: 43px;*/
    }
}

@media (min-width: 768px) {
    html.sticky-top:not(.animations-gpu) #content {
        /*padding-top: 10px;*/
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        border-bottom: none;
        border-top: 1px solid #333333;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main .topnav > .dropdown > a .caret {
        border-top-color: transparent;
        border-top-width: 0;
        border-bottom-color: #999999;
        border-bottom-width: 4px;
        border-bottom-style: solid;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main .topnav > .dropdown > .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom
        .navbar.main
        .topnav
        > .dropdown
        > .dropdown-menu
        > .dropdown
        > .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-bottom: -28px;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main .topnav > .dropdown > .dropdown-menu:before {
        top: auto;
        bottom: -7px;
        border-top-width: 7px;
        border-top-color: #fff;
        border-bottom-width: 0;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main .topnav > .dropdown > .dropdown-menu:after {
        top: auto;
        bottom: -6px;
        border-top-width: 6px;
        border-top-color: #fff;
        border-bottom-width: 0;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom .navbar.main .topnav > .mega-menu .mega-sub {
        top: auto;
        bottom: 100%;
        margin-bottom: 1px;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom.front .navbar.main {
        border-top: 1px solid #dbdbdb;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom.front #content {
        padding-top: 0;
        padding-bottom: 88px;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom #footer {
        display: none;
    }
    html.sticky-top:not(.animations-gpu).menuh-bottom #content {
        padding-top: 0;
    }
}
html.fixed,
html.fixed body {
    min-height: auto;
    height: auto;
    overflow: visible;
}
body > .container-fluid.fixed {
    min-height: auto;
    height: auto;
}
body > .container-fluid.fixed #wrapper {
    position: relative;
    overflow: hidden;
    min-height: auto;
    height: auto;
    margin: 0;
}
body > .container-fluid.fixed #menu {
    position: absolute;
    height: 100%;
    min-height: 100%;
    bottom: auto;
}
.ui-resizable-helper.menu {
    border: 1px dotted gray;
    border-left: none;
    background: rgba(0, 0, 0, 0.3);
}
.ui-resizable-handle {
    width: 3px;
    border-style: solid;
    border-width: 0;
    border-color: rgba(0, 0, 0, 0.4);
}
.dropdown-menu {
    z-index: 100000;
}
html.sidebar-full #menu > ul li:hover:not(.active) > a {
    background: #3e3e3e;
    color: #ffffff;
}
html.sidebar-full #menu > ul li:not(.active) > a.go-premium {
    color: #ffffff;
    background: var(--secondary-color);
}
html.sidebar-full #menu > ul li:not(.active) > a.go-premium i:before {
    color: #ffffff;
}
html.sidebar-full #menu > ul li:hover:not(.active) > a.go-premium {
    background: var(--secondary-color);
}
html.sidebar-full #menu > ul li:hover:not(.active) > a.go-premium i:before {
    color: #ffffff;
}
html.sidebar-full #menu > ul li ul li:hover > a {
    background: #313131;
}
html.sidebar-full #menu > ul li ul li ul li:hover > a,
html.sidebar-full #menu > ul li ul li ul li.active > a {
    background: #252525 !important;
}
html.sidebar-full.sidebar-collapsible #menu {
    overflow: hidden !important;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li {
    overflow: hidden;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li > a > span {
    margin-left: 10px;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li ul {
    margin: 0;
    padding: 0;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li ul ul {
    background: #2c2c2c;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li ul li {
    height: 43px;
    line-height: 43px;
    display: block;
    padding: 0;
    border-bottom: 1px solid #333333;
}
html.sidebar-full.sidebar-collapsible #menu > ul > li ul li a {
    cursor: pointer;
    display: block;
    color: #999999;
    font-weight: 600;
    text-decoration: none;
    padding: 0 7.5px 0 15px;
    height: 43px;
    line-height: 43px;
}
html.sidebar-full.sidebar-collapsible .menu-hidden #menu > ul > li {
    overflow: hidden;
}
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu .slim-scroll > ul > li.dropdown .dropdown-menu,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dropdown-menu {
    right: 100%;
    margin-right: 1px;
    left: auto;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dropdown-menu.pull-left,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dropdown-menu.pull-left {
    right: 100%;
    left: auto;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dropdown-menu.submenu-show,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dropdown-menu.submenu-show {
    margin-top: 0 !important;
    padding: 0;
    top: -1px;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dropdown-menu.submenu-show.pull-right,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dropdown-menu.submenu-show.pull-right {
    right: 100% !important;
    left: auto !important;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu:before,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown.dd-1 > .dropdown-menu:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu:before,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dd-1 > .dropdown-menu:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    .typeahead:before,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown.dd-1 .typeahead:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    .typeahead:before,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dd-1 .typeahead:before {
    border: 7px solid transparent;
    right: -7px;
    left: auto;
    border-right-width: 0;
    border-left-color: #999;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu:after,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown.dd-1 > .dropdown-menu:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu:after,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dd-1 > .dropdown-menu:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    .typeahead:after,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown.dd-1 .typeahead:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    .typeahead:after,
html.sidebar-full.sidebar-dropdown .menu-right:not(.menu-hidden) #menu > ul > li.dropdown .dd-1 .typeahead:after {
    border: 7px solid transparent;
    border-right-width: 0;
    border-left-color: #ffffff;
    right: -6px;
    left: auto;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:before,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:after {
    left: auto;
    right: 7px;
    margin: 0;
}
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    > .dropdown-menu.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown.dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    .slim-scroll
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:after,
html.sidebar-full.sidebar-dropdown
    .menu-right:not(.menu-hidden)
    #menu
    > ul
    > li.dropdown
    .dd-1
    .typeahead.pull-right:after {
    right: 8px;
}
html.sidebar-full #menu {
    width: 150px;
    background-color: var(--secondary-color);;
    overflow: visible !important;
    z-index: 1000;
}
html.sidebar-full #menu > ul {
    border-top: 1px solid #333333;
    overflow: visible !important;
    margin: 0;
}
html.sidebar-full #menu > ul > li {
    position: relative;
    height: 43px;
    line-height: 43px;
    display: block;
    padding: 0;
    border-bottom: 1px solid #333333;
}
html.sidebar-full #menu > ul > li a {
    cursor: pointer;
}
html.sidebar-full #menu > ul > li a .badge {
    float: right;
    position: relative;
    top: 50%;
    margin-top: -10px;
}
html.sidebar-full #menu > ul > li > a {
    display: block;
    color: #999999;
    font-weight: 600;
    text-decoration: none;
    padding: 0 15px;
    height: 43px;
    line-height: 43px;
}
html.sidebar-full #menu > ul > li > a [class*='icon-chevron-'] {
    float: right;
    height: 43px;
    line-height: 43px;
    margin: 0;
}
html.sidebar-full #menu > ul > li.open > a {
    background: #3e3e3e;
    color: #ffffff;
}
html.sidebar-full #menu > ul > li.active > a {
    background: #ffffff;
    color: #4d4d4d;
}
html.sidebar-full #menu > ul > li:last-child.open .dropdown-menu {
    right: 0;
}
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu {
    right: auto;
    left: 100%;
    top: 0;
    width: 180px;
}
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu.pull-left {
    left: 100%;
}
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu.submenu-show {
    margin-top: 0 !important;
    top: -1px;
}
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu li > a,
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu li > a:hover,
html.sidebar-full #menu > ul > li.dropdown .dropdown-menu li > span {
    height: 23px;
    line-height: 23px;
    padding: 0 10px;
    display: block;
    background: #363432;
    color: #c8c8c8;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu:before,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead:before {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    position: absolute;
    display: block;
    left: -7px;
    border-left-width: 0;
    border-right-color: #ffffff;
    top: 5px;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu:after,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead:after {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    top: 5px;
    border-left-width: 0;
    border-right-color: #ffffff;
    position: absolute;
    display: block;
    left: -6px;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu.pull-right:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu.pull-right:before,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead.pull-right:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead.pull-right:before,
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead.pull-right:after {
    left: auto;
    right: 7px;
    margin: 0;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 > .dropdown-menu.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 > .dropdown-menu.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead.pull-right:after,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead.pull-right:after {
    right: 8px;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .typeahead,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .typeahead {
    margin-top: 13px !important;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu.pull-right,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu.pull-right {
    border-radius: 0 0 0 0;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu {
    background: #fff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 0;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu.submenu-show,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu.submenu-show {
    margin-top: -5px !important;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu li,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu li {
    border: none;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu li a,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu li a,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu li span,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu li span {
    background-image: none !important;
    filter: none !important;
    background: #fff;
    color: #939494;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu li a i:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu li a i:before,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu li span i:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu li span i:before {
    color: #cccccc;
    font-size: 15px !important;
    top: 5px !important;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu > li:hover > a,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu > li:hover > a,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu > li.active > a,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu > li.active > a {
    font-weight: 600;
    color: #000;
}
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu > li:hover > a i:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu > li:hover > a i:before,
html.sidebar-full #menu > ul > li.dropdown.dd-1 .dropdown-menu > li.active > a i:before,
html.sidebar-full #menu > ul > li.dropdown .dd-1 .dropdown-menu > li.active > a i:before {
    color: var(--secondary-color);
}
html.sidebar-full #menu > ul > li.dropdown.dd-flags .dropdown-menu img {
    margin: -3px 4px 0 0;
}
html.sidebar-full #menu > ul .dropdown > a .caret {
    border-top-color: #999999;
    top: 16px;
    position: relative;
}
#menu {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    background: #1f2123;
}
#menu .profile {
    display: block;
    padding: 10px 0;
}
#menu .profile a {
    display: inline-block;
    text-decoration: none;
    width: 74px;
    height: 74px;
    border: 1px solid #333333;
    padding: 5px;
    /*background: #fff;*/
}
#menu .profile a:hover {
    border-color: #1a1a1a;
}
body .menu-right #menu {
    left: auto;
    right: 0;
    z-index: 1000;
}
body .menu-right.fixed #content {
    border-radius: 5px 0 0 0;
}
body .menu-right.fixed .navbar.main,
body .menu-right.fixed .navbar.main .wrapper {
    border-radius: 5px 0 0 0;
}
html.sidebar.sidebar-full body .menu-right {
    /* IE8 fallback */
}
html.sidebar.sidebar-full body .menu-right #menu > ul {
    border-right: none;
    border-left: 1px solid #333333;
}
html.sidebar.sidebar-full body .menu-right #menu > ul > li > a {
    text-align: right;
}
html.sidebar.sidebar-full body .menu-right #menu > ul > li > a [class*='icon-chevron-'] {
    float: left;
}
html.sidebar.sidebar-full body .menu-right #menu > ul > li.active > a [class*='icon-chevron-'],
html.sidebar.sidebar-full body .menu-right #menu > ul > li:hover > a [class*='icon-chevron-'],
html.sidebar.sidebar-full body .menu-right #menu > ul > li.open > a [class*='icon-chevron-'] {
    margin: 0;
}
html.sidebar.sidebar-full body .menu-right #content {
    margin-right: 150px;
}
html.sidebar.sidebar-full body .menu-right.menu-hidden {
    /* IE8 fallback */
}
html.sidebar.sidebar-full body .menu-right.menu-hidden #menu > ul > li > a {
    text-align: center;
}
html.sidebar.sidebar-full body .menu-right.menu-hidden #menu > ul > li > a [class*='icon-chevron-'] {
    float: left;
}
html.sidebar.sidebar-full body .menu-right.menu-hidden #menu > ul > li.active > a [class*='icon-chevron-'],
html.sidebar.sidebar-full body .menu-right.menu-hidden #menu > ul > li:hover > a [class*='icon-chevron-'],
html.sidebar.sidebar-full body .menu-right.menu-hidden #menu > ul > li.open > a [class*='icon-chevron-'] {
    margin: 0;
}
html.sidebar.sidebar-full body .menu-right.menu-hidden #content {
    margin-right: 60px;
}
html.sidebar-full .menu-hidden #menu {
    width: 60px;
}
html.sidebar-full .menu-hidden #menu .profile {
    padding: 0;
}
html.sidebar-full .menu-hidden #menu .profile a {
    border: none;
    width: 60px;
    height: 60px;
    padding: 0;
    height: 100%;
}
html.sidebar-full .menu-hidden #menu .profile a img {
    max-height: 60px;
}
html.sidebar-full .menu-hidden #menu .menu-hidden-element {
    display: none;
}
html.sidebar-full .menu-hidden #menu > ul > li > a {
    /*display: none;*/
    /* REMOVE BY BLOCKBASE */
}
html.sidebar-full .menu-hidden #content {
    margin-left: 60px;
}
.navbar .btn-navbar,
#menu .btn-navbar {
    padding: 0 10px;
    margin: 0;
    display: none;
    width: 100%;
    border: none;
    border-radius: 0 0 0 0;
    height: 43px;
    line-height: 43px;
    background: var(--secondary-color);;
    border-bottom: 1px solid #333333;
}
.navbar .btn-navbar .icon-bar,
#menu .btn-navbar .icon-bar {
    display: block;
    width: 18px;
    height: 2px;
    background: #999999;
    margin: 0 auto 3px;
}
.navbar .btn-navbar:hover,
#menu .btn-navbar:hover {
    background: #343434;
}
#menu .btn-navbar {
    display: block;
}
/*html.sidebar-hat #menu .btn-navbar {
  display: none;
}*/

html.sidebar-hat .menu-hidden .navbar .btn-navbar,
html.sidebar-hat .menu-hidden #menu .btn-navbar {
    display: block;
}
@media (max-width: 767px) {
    html.sidebar .container-fluid.sidebar-hidden-phone #menu {
        display: none !important;
    }
    html.sidebar .container-fluid.sidebar-hidden-phone #content {
        margin: 0 !important;
    }
}
html.top-full .navbar.main .topnav > li:hover:not(.active) > a {
    background: var(--secondary-color);
    color: #ffffff;
}
html.top-full .navbar.main .btn-navbar {
    height: 43px;
    float: none;
    background: var(--secondary-color);;
    border: none;
    border-bottom: 1px solid #333333;
}
html.top-full .navbar.main .btn-navbar span {
    background: #ffffff;
    margin: 0 5px 3px;
}
html.top-full .navbar.main .topnav.pull-left + .topnav.pull-left {
    border-left: none;
}
html.top-full .navbar.main .topnav.pull-left + .topnav.pull-left > li:first-child {
    border-left: none;
}
html.top-full .navbar.main .topnav:last-of-type > li:last-of-type {
    border: none;
}
html.top-full .navbar.main .topnav > li {
    position: relative;
    float: left;
    height: 33px;
    line-height: 43px;
    display: block;
    padding: 0;
}
html.top-full .navbar.main .topnav > li a {
    cursor: pointer;
}
html.top-full .navbar.main .topnav > li .badge {
    top: 7px;
    right: 7px;
    position: absolute;
    border: 1px solid var(--secondary-color);;
    height: 15px;
    width: 15px;
    line-height: 15px;
}
html.top-full .navbar.main .topnav > li > a {
    display: block;
    color: white;
    text-decoration: none;
    padding: 0 15px;
    height: 43px;
    line-height: 43px;
    font-weight: 600;
}
html.top-full .navbar.main .topnav > li > a > img {
    margin: 0 0 3px;
}
html.top-full .navbar.main .topnav > li.open > a {
    background: #3e3e3e;
    color: #ffffff;
}
html.top-full .navbar.main .topnav > li.active > a {
    height: 44px;
    background: #ffffff;
    color: #4d4d4d;
}
html.top-full .navbar.main .topnav > li:last-child.open .dropdown-menu {
    right: 0;
}
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu {
    background: #fff;
    border: none;
    right: 0;
    width: 180px;
}
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu.pull-left {
    left: 0;
}
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu li > a,
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu li > a:hover,
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu li > span {
    height: 23px;
    line-height: 23px;
    padding: 0 10px;
    display: block;
}
html.top-full .navbar.main .topnav > li.dropdown .dropdown-menu li .btn {
    margin: 3px 0;
}
html.top-full .navbar.main .topnav > li.dropdown.account > .dropdown-menu {
    width: 230px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu:before,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead:before {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    position: absolute;
    display: block;
    left: 7px;
    border-top-width: 0;
    /*border-bottom-color: #ffffff;*/
    top: -7px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu:after,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead:after {
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    top: -6px;
    border-top-width: 0;
    /*border-bottom-color: #ffffff;*/
    position: absolute;
    display: block;
    left: 8px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu.pull-right:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu.pull-right:before,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead.pull-right:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead.pull-right:before,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead.pull-right:after {
    left: auto;
    right: 7px;
    margin: 0;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 > .dropdown-menu.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 > .dropdown-menu.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead.pull-right:after,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead.pull-right:after {
    right: 8px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu {
    background: var(--secondary-color);
    /*border: 1px solid #ffffff;*/
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    border-radius: 0 0 0 0;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li {
    border: none;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li span,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li span {
    background-image: none !important;
    filter: none !important;
    color: #fff;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li a i:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li a i:before,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li span i:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li span i:before {
    color: #cccccc;
    font-size: 15px !important;
    top: 5px !important;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li:hover > a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li:hover > a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li.active > a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li.active > a {
    font-weight: 600;
    color: #000;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li:hover > a i:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li:hover > a i:before,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li.active > a i:before,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li.active > a i:before {
    color: var(--secondary-color);
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile {
    background: #ffffff;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile a:hover,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile a:hover {
    color: #7c7c7c;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a:hover,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a:hover,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > span,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > span {
    height: auto;
    line-height: normal;
    padding: 5px 10px 10px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a .heading,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a .heading,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a:hover .heading,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a:hover .heading,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > span .heading,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > span .heading {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a .heading a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a .heading a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a:hover .heading a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a:hover .heading a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > span .heading a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > span .heading a {
    text-transform: none;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a .img,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a .img,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a:hover .img,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a:hover .img,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > span .img,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > span .img {
    display: inline-block;
    float: left;
    margin: 0 10px 0 0;
    position: relative;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a .clearfix,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a .clearfix,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > a:hover .clearfix,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > a:hover .clearfix,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu li.profile > span .clearfix,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu li.profile > span .clearfix {
    display: block;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .typeahead,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .typeahead {
    margin-top: 13px !important;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu.pull-right,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu.pull-right {
    border-radius: 0 0 0 0;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li > a:hover,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li > a:hover,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-menu > li > a:focus,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-menu > li > a:focus,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-submenu:hover > a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-submenu:hover > a,
html.top-full .navbar.main .topnav > li.dropdown.dd-1 .dropdown-submenu:focus > a,
html.top-full .navbar.main .topnav > li.dropdown .dd-1 .dropdown-submenu:focus > a {
    background-image: none !important;
    filter: none !important;
}
html.top-full .navbar.main .topnav > li.dropdown.dd-flags .dropdown-menu img {
    margin: -3px 4px 0 0;
}
html.top-full .navbar.main .topnav .dropdown > a .caret {
    border-top-color: #999999;
    vertical-align: middle;
    margin-top: 0;
    position: relative;
}
html.top-full .navbar.main .topnav .dropdown-menu {
    border-radius: 0 0 0 0;
}
.navbar.main {
    margin: 0;
    position: relative;
    z-index: 1000;
}
.navbar.main .topnav {
    margin: 0;
    padding: 0;
    list-style: none;
}
.submenu-show {
    display: block;
    margin-top: -29px !important;
    position: absolute;
}
.submenu-show.pull-left {
    left: auto !important;
    right: 100% !important;
}
.submenu-show.pull-right {
    right: auto !important;
    left: 100% !important;
}
.submenu-hide {
    display: none !important;
    position: relative;
    top: auto;
    float: left;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner ul li a:hover {
    color: orangered;
}
.navbar.main .topnav > li.mega-menu {
    position: static !important;
}
.navbar.main .topnav > li.mega-menu .mega-sub {
    display: none;
    position: absolute;
    background: var(--secondary-color);
    width: 70%;
    left: 0;
    top: 57px;
}
.navbar.main .topnav > li.mega-menu .mega-sub h4 {
    color: #ffffff;
    padding: 15px 0;
    margin: 0;
    font-size: 160%;
    font-weight: 400;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner {
    margin: 0;
    padding: 15px 30px;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner ul {
    list-style: none;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner ul li {
    display: block;
    line-height: 25px;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner ul li a {
    display: block;
    font-size: 14px;
    color: white;
}
.navbar.main .topnav > li.mega-menu.mega-menu-open .mega-sub {
    display: block;
}
.filter-bar {
    background: #f4f4f4;
    margin: 0 auto 15px;
    border: 1px solid #efefef;
    line-height: 30px;
    height: 40px;
    padding: 0 10px;
}
.filter-bar.border-top-none {
    border-top: none;
}
.filter-bar div {
    padding: 5px 0;
    display: block;
    line-height: 30px;
}
.filter-bar div div {
    padding: 0;
    margin: 0;
}
.filter-bar div div:not(.helper) {
    margin: 0 15px 0 0;
}
.filter-bar div div input {
    margin: 3px 0 0;
}
.filter-bar div div.input-append {
    margin: 0;
}
.filter-bar div div.input-append .add-on {
    padding: 1px 3px;
    margin: 3px 0 0 -1px;
    background: #fff;
    color: #d8d9da;
    border-color: #d8d9da;
}
.filter-bar div div.input-append .add-on i:before {
    top: 4px;
    left: 4px;
    font-size: 14px;
    color: #d0d1d1;
}
.filter-bar div div.input-append button.add-on {
    width: 24px;
    height: 24px;
}
.filter-bar div div:last-child {
    margin: 0;
}
.filter-bar div:not(.helper) {
    float: left;
}
.filter-bar div.clearfix {
    padding: 0;
    clear: both;
    float: none;
    display: block;
    height: 0;
    margin: 0;
}
.filter-bar form {
    margin: 0;
}
.filter-bar form input,
.filter-bar form select {
    margin: 0 5px 3px 0;
    padding: 1px 5px;
    color: #a7a7a7;
}
.filter-bar form select {
    padding: 1px;
    height: 23px;
}
.filter-bar form label {
    float: left;
    margin: 0 5px;
    display: block;
    line-height: 30px;
}
.filter-bar.border-none {
    border: none;
}
.filter-bar.dark {
    background: #64625f;
    color: #fff;
}
.filter-bar.dark div div.input-append .add-on {
    background: #42403e;
    color: #fff;
    border-color: #42403e;
}
.filter-bar.dark div div.input-append .add-on i:before {
    color: #767473;
    text-shadow: none;
}
.filter-bar.dark form input:focus {
    /*  border-color: #42403e;*/
}
.filter-bar.dark form input,
.filter-bar.dark form select {
    /*border-color: #42403e;*/
}
.menubar {
    height: 30px;
    position: relative;
    overflow: hidden;
    border: 1px solid #efefef;
    background: #fafafa;
    margin: 0;
    font-size: 9pt;
}
.menubar ul {
    margin: 0;
    padding: 0 5px;
    list-style: none;
    height: 30px;
}
.menubar ul li {
    float: left;
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
}
.menubar ul li.divider {
    border-left: 1px solid #ebebeb;
    width: 0px;
    padding: 0;
    margin: 0 8px;
}
.menubar ul li a {
    color: #7c7c7c;
    font-weight: 700;
    font-size: 9pt;
}
.menubar.links {
    border: none;
    background: none;
    height: 25px;
    padding: 0 0 5px;
}
.menubar.links ul {
    padding: 0;
    height: 25px;
}
.menubar.links ul li {
    height: 25px;
    line-height: 25px;
}
.menubar.links ul li:first-child {
    padding-left: 0;
}
.menubar.links.primary ul li a {
    color: var(--secondary-color);
}
.widget {
    background: #ffffff;
    margin: 0 auto 15px;
    position: relative;
    border: 1px solid #efefef;
}
.widget .tab-content {
    padding: 0;
}
.widget .widget-head {
    background-color: #f9f9f9;
    background-image: linear-gradient(to bottom, #fdfdfd, #f4f4f4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffdfdfd', endColorstr='#fff4f4f4', GradientType=0);
    border-bottom: 1px solid #efefef;
    text-shadow: 0 1px 0 0 1px 0 #ffffff;
    height: 35px;
    line-height: 35px;
    position: relative;
    padding: 0 15px 0 0;
    overflow: hidden;
}
.widget .widget-head.progress {
    border-radius: 0 0 0 0;
    margin: 0;
    padding: 0;
}
.widget .widget-head .heading {
    margin: 0;
    color: #7c7c7c;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    float: left;
}
.widget .widget-head a {
    text-shadow: none;
}
.widget .widget-head .dropdown-menu li > a:hover,
.widget .widget-head .dropdown-menu li > a:focus,
.widget .widget-head .dropdown-submenu:hover > a {
    background-color: var(--secondary-color);
    background-image: linear-gradient(to bottom, var(--secondary-color), #d24343);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffda4c4c', endColorstr='#ffd24343', GradientType=0);
}
.widget .details {
    font-size: 8pt;
    color: #7c7c7c;
}
.widget .widget-body {
    padding: 15px;
}
.widget .widget-body form {
    margin: 0;
}
.widget .widget-body > pre:last-child,
.widget .widget-body > p:last-child {
    margin: 0;
}
.widget .widget-body.list {
    color: #7c7c7c;
    padding: 0;
}
.widget .widget-body.list .count {
    font-size: 15pt;
    font-weight: 400;
}
.widget .widget-body.list ul {
    margin: 0;
    list-style: none;
}
.widget .widget-body.list ul li {
    padding: 0 10px;
    height: 39px;
    border-bottom: 1px solid #efefef;
    position: relative;
    line-height: 39px;
    text-align: left;
    text-shadow: 0 1px 0 #ffffff;
    clear: both;
}
.widget .widget-body.list ul li .badge {
    position: absolute;
    right: 8px;
    top: 10px;
}
.widget .widget-body.list ul li:first-child {
    border-top: none;
}
.widget .widget-body.list ul li:last-child {
    border-bottom: none;
}
.widget .widget-body.list ul li .count {
    float: right;
    color: #000000;
}
.widget .widget-body.list ul li a {
    color: #000000;
    font-weight: bold;
}
.widget .widget-body.list.products li {
    height: 60px;
    line-height: 60px;
}
.widget .widget-body.list.products .img {
    float: left;
    display: inline-block;
    text-align: center;
    background: #272729;
    margin: 6px 8px 0 0;
    cursor: pointer;
}
.widget .widget-body.list.products .title {
    line-height: normal;
    display: inline-block;
    padding: 13px 0 0;
    text-transform: uppercase;
}
.widget .widget-body.list.products .title strong {
    font-family: 'Roboto', sans-serif;
    text-transform: none;
}
.widget .widget-body.list.fluid ul li {
    height: auto;
    line-height: normal;
    padding: 10px;
}
.widget .widget-body.list.list-2 ul li {
    border-bottom: 1px solid #d8d9da;
    border-top: none;
    background: #f8f8f8;
}
.widget .widget-body.list.list-2 ul li.active {
    border-color: #dddddd;
    background: #fff;
}
.widget .widget-body.list.list-2 ul li.active i:before {
    font-weight: normal;
    background: var(--secondary-color);
    color: #fff;
    text-shadow: none;
}
.widget .widget-body.list.list-2 ul li.active a {
    color: var(--secondary-color);
}
.widget .widget-body.list.list-2 ul li:last-child {
    border-bottom: none;
}
.widget .widget-body.list.list-2 ul li a {
    display: block;
    color: #222;
    padding: 0 0 0 30px;
}
.widget .widget-body.list.list-2 ul li a i:before {
    color: #555;
    width: 20px;
    height: 17px;
    font-size: 14px;
    border: 1px solid #ccc;
    background: #dddddd;
    top: 9px;
    left: 0;
    text-align: center;
    vertical-align: middle;
    padding-top: 3px;
    text-shadow: 0 1px 0 #fff;
}
.widget .widget-footer {
    background: #fafafa;
    height: 25px;
    line-height: 25px;
    border-top: 1px solid #ebebeb;
}
.widget.margin-bottom-none {
    margin-bottom: 0;
}
.widget.widget-gray {
    background: #f5f5f5;
}
.widget.widget-gray .widget-head {
    background: #e9e9e9;
    border-color: #d1d2d3;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
}
.widget.widget-gray .widget-head .heading {
    color: #555555;
    text-shadow: 0 1px 0 #fff;
}
.widget.widget-2:before,
.widget.widget-2:after {
    display: none;
}
.widget.widget-2 .widget-head {
    background: #e2e2e4;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0);
    height: 36px;
    line-height: 36px;
    border-color: #d1d2d3;
}
.widget.widget-2 .widget-head .heading {
    line-height: 36px;
    color: #555555;
    text-shadow: 0 1px 0 #fff;
    font-size: 12pt;
}
.widget.widget-2 .widget-head .heading i:before {
    color: #555555;
    background: none;
    border: none;
    left: 3px;
    top: 2px;
}
.widget.widget-2 .widget-body {
    border: 1px solid #d1d2d3;
    border-top: 0;
    background: #f5f5f5;
}
.widget.widget-2.primary .widget-head {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.6), inset -1px -1px 1px rgba(0, 0, 0, 0.1);
}
.widget.widget-2.primary .widget-head .heading {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.widget.widget-2.primary .widget-head .heading i:before {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.widget.widget-3:before,
.widget.widget-3:after {
    display: none;
}
.widget.widget-3 .widget-head {
    height: 36px;
    line-height: 36px;
}
.widget.widget-3 .widget-head .heading {
    display: block;
    text-align: center;
    float: none;
    line-height: 36px;
    height: 36px;
    text-transform: uppercase;
    font-size: 12pt;
}
.widget.widget-3 .widget-head .heading i:before {
    background: none;
    border: none;
    left: 3px;
    top: 5px;
    font-size: 20px;
    color: #45484d;
}
.widget.widget-3 .widget-body {
    border-bottom: 1px solid #efefef;
}
.widget.widget-3 .widget-body.large {
    font-size: 26pt;
    font-weight: bold;
    text-align: center;
    padding: 25px 0;
    vertical-align: middle;
    line-height: normal;
}
.widget.widget-3 .widget-footer {
    background: #fafafa;
    border-top: none;
}
.widget.widget-3 .widget-footer a {
    float: none;
    color: #dddddd;
    width: auto;
    padding: 0 10px 0 30px;
    border-right: 1px solid #efefef;
    text-decoration: none;
}
.widget.widget-3 .widget-footer a i:before {
    color: #dddddd;
    left: 5px;
}
.widget.widget-3 .widget-footer a:hover {
    color: var(--secondary-color);
}
.widget.widget-3 .widget-footer a:hover i:before {
    color: var(--secondary-color);
}
.widget.widget-3 .widget-footer.align-center {
    text-align: center;
}
.widget.widget-3 .widget-footer.align-center a:first-child {
    border-left: 1px solid #efefef;
}
.widget.widget-3 .widget-footer.align-right {
    text-align: right;
}
.widget.widget-3 .widget-footer.align-right a:first-child {
    border-left: 1px solid #efefef;
}
.widget.widget-3 .widget-footer.align-right a:last-child {
    border-right: none;
}
.widget.widget-4 {
    border: none;
    background: none;
}
.widget.widget-4 .widget-head {
    background: none;
    padding: 10px;
    border: none;
}
.widget.widget-4 .widget-head .heading {
    color: #544f49;
    text-shadow: none;
    padding: 0;
    margin: 0;
}
.widget.widget-4 .widget-body {
    padding: 0 10px 10px;
    border: none;
}
.widget.widget-4 .widget-body.list ul li {
    padding: 0;
}
.widget.widget-heading-simple {
    border: none;
    background: none;
}
.widget.widget-heading-simple > .widget-head {
    border: none;
    background: none;
    background-image: none !important;
    filter: none !important;
    padding: 0 0 5px;
    height: 25px;
    line-height: 25px;
}
.widget.widget-heading-simple > .widget-head .heading {
    padding: 0;
    height: 25px;
    line-height: 25px;
}
.widget.widget-heading-simple > .widget-body {
    border: 1px solid #efefef;
}
.widget.widget-body-simple {
    border-radius: 0 !important;
}
.widget.widget-body-simple > .widget-body {
    background: none;
    border: none;
    padding: 0;
    border-radius: 0 !important;
}
.widget.widget-body-white > .widget-body {
    background: #ffffff;
}
.widget.widget-body-gray > .widget-body {
    background: #f4f4f4;
}
.widget.widget-body-primary > .widget-body {
    background: var(--secondary-color);
}
.widget.widget-body-primary > .widget-body * {
    color: #fff;
    text-shadow: none;
}
.widget.widget-body-inverse > .widget-body {
    background: #424242;
}
.widget.widget-body-inverse > .widget-body *,
.widget.widget-body-inverse > .widget-body i:before {
    color: #fff;
    text-shadow: none;
}
.widget.widget-body-default > .widget-body {
    background: #bbbbbb;
}
.widget.widget-body-default > .widget-body *,
.widget.widget-body-default > .widget-body i:before {
    color: #fff;
    text-shadow: none;
}
.widget.widget-body-multiple {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.widget.widget-body-multiple > .widget-body {
    margin-bottom: 7.5px;
}
.widget.widget-body-multiple > .widget-body:last-of-type {
    margin-bottom: 0;
}
.widget-flat {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0 !important;
}
.widget-flat > .widget-body {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
}
.widget-activity > .widget-body {
    padding: 0 15px;
}
.widget-activity ul.list {
    list-style: none;
    margin: 0 -15px;
    padding: 0;
}
.widget-activity ul.list li {
    height: 39px;
    line-height: 39px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #efefef;
    padding: 0 15px;
    transition: background 0.5s ease;
}

.widget-messages ul li {
    border: 1px solid #e5e5e5;
    padding: 5px;
    margin: 0 0 10px;
    position: relative;
}
.widget-messages ul li .meta span {
    color: #a3a3a3;
    font-size: 12px;
}
.box-generic {
    border: 1px solid #efefef;
    padding: 0;
    position: relative;
    background: #ffffff;
    margin: 0 0 15px;
}
.box-generic.box-generic-gray {
    background: #f4f4f4;
}
.breadcrumb {
    display: inline;
    height: 43px;
    line-height: 39px;
    padding: 0 0px;
    border-radius: 0 0 0 0;
    margin: 0;
    background: none;
    border: none;
    /*border-bottom: 1px solid #efefef;*/
    color: #ffffff;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    padding-left: 15px;
}
.breadcrumb li {
    text-shadow: none;
    line-height: 38px;
    height: 38px;
}
.breadcrumb li a {
    color: #ffffff;
    /*font-weight: 600;*/
}
.breadcrumb > li > .divider {
    /*margin: 0 5px 0 7px;*/
    vertical-align: top;
    display: inline-block;
    width: auto;
    color: #ffffff;
}
.well {
    z-index: 1;
    position: relative;
    padding: 15px;
    margin: 0 0 15px;
    border-radius: 0 0 0 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    background: #f4f4f4;
    border-color: #efefef;
}
.well.small {
    padding: 10px;
}
.well .popover {
    z-index: 2;
    font-size: 13px;
}
.well.white {
    background: #fff;
}
.well.primary {
    background: var(--secondary-color);
}
.well.primary,
.well.primary * {
    color: #fff;
}
.well.inverse {
    background: #424242;
}
.well.inverse,
.well.inverse * {
    color: #fff;
}
.well.info {
    background: #5cc7dd;
}
.well.info,
.well.info * {
    color: #fff;
}
.well.default {
    background: #bbbbbb;
}
.well.default,
.well.default * {
    color: #fff;
}
.well.success {
    background: #008000;
}
.well.success,
.well.success * {
    color: #fff;
}
[data-toggle='collapse-widget'] .collapse-toggle {
    position: relative;
    height: 35px;
    width: 30px;
    display: block;
    cursor: pointer;
    float: right;
    margin-right: -10px;
}
[data-toggle='collapse-widget'].widget-heading-simple .collapse-toggle {
    margin-right: 0;
    height: 25px;
}
[data-toggle='collapse-widget'].widget-heading-simple .collapse-toggle:before {
    height: 25px;
    line-height: 25px;
}
[data-toggle='collapse-widget'] a ~ .collapse-toggle {
    margin-right: 0;
}
[data-toggle='collapse-widget'][data-collapse-closed='false'] .collapse-toggle:before {
    content: '\e192';
}
[data-toggle='collapse-widget'][data-collapse-closed='true'] .collapse-toggle:before {
    content: '\e191';
}
[data-toggle='collapse-widget'][data-collapse-closed='true'] .widget-head {
    border-bottom: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
[data-toggle='collapse-widget'] .collapse:not(.list) {
    transition: padding 0.1s ease;
    padding: 0px;
}
[data-toggle='collapse-widget'] .collapse:not(.list).in {
    padding: 15px;
}
.hero-unit {
    background: none;
    border: none;
    border-radius: 0 !important;
}
.widget-search div {
    padding-right: 12px;
}
.widget-search input {
    width: 100%;
}
.widget.widget-tabs {
    border-color: #efefef;
}
.widget.widget-tabs .tab-content {
    padding: 0;
}
.widget.widget-tabs > .widget-head {
    background: #ffffff;
    border-color: #efefef;
    overflow: visible;
}
.widget.widget-tabs > .widget-head ul {
    position: relative;
    overflow: visible;
    list-style: none;
    height: 35px;
    margin: 0;
    display: inline-block;
}
.widget.widget-tabs > .widget-head ul li {
    height: 35px;
    line-height: 35px;
    float: left;
    display: block;
    border-right: 1px solid #efefef;
}
.widget.widget-tabs > .widget-head ul li a {
    width: auto;
    height: 35px;
    display: block;
    padding: 0 15px;
    position: relative;
    overflow: hidden;
    color: #7c7c7c;
    text-decoration: none;
}
.widget.widget-tabs > .widget-head ul li a i:before {
    left: 5px;
    top: 5px;
    color: #9d9d9d;
    text-shadow: 0 1px 0 #ffffff;
}
.widget.widget-tabs > .widget-head ul li.active {
    height: 36px;
    background: #fff;
    color: #505050;
}
.widget.widget-tabs > .widget-head ul li.active a {
    color: #505050;
}
.widget.widget-tabs > .widget-head ul li.active a i:before {
    color: #505050;
}
.widget.widget-tabs > .widget-body.large {
    padding: 25px;
}
.widget.widget-tabs-double > .widget-head {
    height: 40px;
}
.widget.widget-tabs-double > .widget-head ul {
    height: 40px;
}
.widget.widget-tabs-double > .widget-head ul li,
.widget.widget-tabs-double > .widget-head ul li.active {
    height: 40px;
    line-height: 20px;
}
.widget.widget-tabs-double > .widget-head ul li a,
.widget.widget-tabs-double > .widget-head ul li.active a {
    height: 35px;
    padding: 5px 0 0;
}
.widget.widget-tabs-double > .widget-head ul li a span,
.widget.widget-tabs-double > .widget-head ul li.active a span {
    display: block;
    line-height: 15px;
}
.widget.widget-tabs-double-2 {
    border: none;
}
.widget.widget-tabs-double-2 > .widget-head {
    border: 1px solid #efefef;
    border-bottom: none;
    height: 70px;
}
.widget.widget-tabs-double-2 > .widget-head.border-bottom {
    border-bottom: 1px solid #efefef;
}
.widget.widget-tabs-double-2 > .widget-head ul {
    height: 70px;
}
.widget.widget-tabs-double-2 > .widget-head ul li {
    height: 70px;
    line-height: 20px;
}
.widget.widget-tabs-double-2 > .widget-head ul li a {
    transition: color 1s ease;
    height: 70px;
    padding: 5px 0 0;
    text-decoration: none;
    text-align: center;
}
.widget.widget-tabs-double-2 > .widget-head ul li a span {
    display: block;
    line-height: 30px;
}
.widget.widget-tabs-double-2 > .widget-head ul li.active {
    height: 70px;
    background: #f4f4f4;
}
.widget.widget-tabs-double-2 > .widget-head ul li.active a {
    color: #444;
}
.widget.widget-tabs-double-2 > .widget-head ul li.active a i:before {
    color: #444;
}
.widget.widget-tabs-double-2 > .widget-body {
    padding: 0;
}
.widget.widget-tabs-double-2 .widget-body-regular {
    padding: 10px;
    border: 1px solid #efefef;
}
.widget.widget-tabs-double-2 .widget-body-regular p:last-child {
    margin: 0;
}
.widget.widget-tabs-vertical .widget-head {
    border: none;
    height: auto;
    padding: 0;
}
.widget.widget-tabs-vertical .widget-head ul {
    display: block;
    height: auto;
}
.widget.widget-tabs-vertical .widget-head ul li {
    float: none;
    display: block;
    border-right: none;
    border-bottom: 1px solid #efefef;
}
.widget.widget-tabs-vertical .widget-head ul li:last-child {
    border: none;
}
.widget.widget-tabs-vertical .widget-body {
    padding: 0;
}
.widget.widget-tabs-vertical .widget-body .tab-content {
    padding: 12px 15px;
}
.widget.widget-tabs-2 {
    border-bottom: 1px solid #efefef;
}
.widget.widget-tabs-2.border-bottom-none {
    border-bottom: none;
}
.widget.widget-tabs-2 > .widget-head {
    background: #fff;
    border: none;
    border-bottom: 1px solid #efefef;
    padding: 0 10px;
}
.widget.widget-tabs-2 > .widget-head ul {
    border-color: #efefef;
}
.widget.widget-tabs-2 > .widget-head ul li {
    border: none;
}
.widget.widget-tabs-2 > .widget-head ul li.active {
    background-color: #f7f7f7;
    background-image: linear-gradient(to bottom, #f7f7f7, #ffffff);
    background-repeat: repeat-x;
}
.widget.widget-tabs-2 > .widget-head ul li a {
    border: 1px solid #dddddd;
    border-bottom: none;
    border-right: none;
    color: #222;
    text-transform: uppercase;
    font-weight: 600;
}
.widget.widget-tabs-2 > .widget-head ul li:first-child a {
    border-radius: 0 0 0 0;
}
.widget.widget-tabs-2 > .widget-body {
    background: #fff;
    border: none;
    padding: 15px;
}
.widget.tabs-right .widget-head ul {
    float: right;
}
.widget.widget-tabs-icons-only .widget-body {
    padding: 0;
}
.widget.widget-tabs-icons-only .widget-body p:last-child {
    margin: 0;
}
.widget.widget-tabs-icons-only .widget-head {
    padding: 0 0 10px;
}
.widget.widget-tabs-icons-only .widget-head ul {
    display: block;
    overflow: hidden;
    position: relative;
    list-style: none;
    margin: 0;
    height: 30px;
    line-height: 30px;
    padding: 0;
}
.widget.widget-tabs-icons-only .widget-head ul li {
    color: #949494;
    float: left;
    display: block;
    padding: 0 10px 0 0;
    font-size: 8pt;
    line-height: 30px;
}
.widget.widget-tabs-gray {
    background: #f4f4f4;
}
.widget.widget-tabs-gray > .widget-head {
    background: #dbdbdb;
}
.widget.widget-tabs-gray > .widget-head ul li,
.widget.widget-tabs-gray > .widget-head ul li i:before {
    color: #7c7c7c !important;
    text-shadow: none;
}
.widget.widget-tabs-gray > .widget-head ul li.active {
    background: #f4f4f4;
}
.widget.widget-tabs-gray > .widget-head ul li.active,
.widget.widget-tabs-gray > .widget-head ul li.active i:before {
    color: #444 !important;
    text-shadow: none;
}
.nav-tabs > li > a:hover {
    background: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
    color: #fff;
}
.nav-tabs {
    border-bottom-color: var(--secondary-color);
}
.nav-tabs > li > a {
    padding: 6px 8px;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
    background: var(--secondary-color);
    border-top-color: var(--secondary-color);
    border-left-color: var(--secondary-color);
    border-right-color: var(--secondary-color);
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), inset -1px -1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), inset -1px -1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), inset -1px -1px 1px rgba(0, 0, 0, 0.2);
}
.tab-content {
    overflow: visible;
    padding: 0;
}
.btn {
    font-weight: 600;
    border-radius: 0 0 0 0;
    text-shadow: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.btn.active,
.btn:active {
    outline: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
}
.btn-block {
    width: 100%;
}
/*.btn + .btn:not(.btn-block) {*/

.btn {
    margin: 0 0 0 5px;
    padding: 3px 12px;
    /*margin: 0 5px 5px 0;*/
}
@media (max-width: 480px) {
    .btn {
        margin: 0 0 0 2px;
        padding: 3px 5px;
    }
    .dropdown-menu {
        min-width: 0px;
    }
}
.input-append .btn + .btn,
.input-prepend .btn + .btn {
    margin-left: -1px;
}
.btn-group .btn + .btn:not(.btn-block) {
    margin-left: -1px;
}
.btn-group.dropup .btn:first-child {
    border-radius: 0 0 0 0;
}
.btn-default {
    background: #e7e7e7;
    border-color: #e7e7e7;
}
.btn-group.open .btn-default.dropdown-toggle,
.btn-default.disabled,
.btn-default[disabled],
.btn-default:hover,
.btn-default:focus {
    background: #e8e8e8;
}
.btn-default:active,
.btn-default.active {
    background: #e8e8e8;
    color: #fff;
    text-shadow: none;
}
.btn-default .caret {
    border-top-color: rgba(0, 0, 0, 0.5);
}
.btn-warning {
    background: #ab7a4b;
    border-color: #ab7a4b;
    color: #ffffff;
}
.btn-group.open .btn-warning.dropdown-toggle,
.btn-warning.disabled,
.btn-warning[disabled],
.btn-warning:hover,
.btn-warning:focus {
    background: #ab7a4b;
    color: #fff;
}
.btn-warning:active,
.btn-warning.active {
    background: #ab7a4b;
    color: #fff;
}
.btn-warning .caret {
    border-top-color: rgba(255, 255, 255, 0.7);
    border-bottom-color: rgba(255, 255, 255, 0.7);
}
.btn-info {
    background: #5cc7dd;
    border-color: #5cc7dd;
    color: #ffffff;
}
.btn-group.open .btn-info.dropdown-toggle,
.btn-info.disabled,
.btn-info[disabled],
.btn-info:hover,
.btn-info:focus {
    background: #5cc7dd;
    color: #fff;
}
.btn-info:active,
.btn-info.active {
    background: #5cc7dd;
    color: #fff;
}
.btn-info .caret {
    border-top-color: rgba(255, 255, 255, 0.7);
    border-bottom-color: rgba(255, 255, 255, 0.7);
}
.btn-danger {
    background: #ff0000;
    border-color: #ff0000;
    color: #ffffff;
}
.btn-group.open .btn-danger.dropdown-toggle,
.btn-danger.disabled,
.btn-danger[disabled],
.btn-danger:hover,
.btn-danger:focus {
    background: #ff0000;
    color: #fff;
}
.btn-danger:active,
.btn-danger.active {
    background: #ff0000;
    color: #fff;
}
.btn-danger .caret {
    border-top-color: rgba(255, 255, 255, 0.7);
    border-bottom-color: rgba(255, 255, 255, 0.7);
}
.btn-primary {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.btn-zone,
.btn-zone:hover {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.btn-tag,
.btn-tag:hover {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.btn-document {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.btn-document:hover {
    opacity: 0.5;
}
.btn-group.open .btn-primary.dropdown-toggle,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary:hover,
.btn-primary:focus {
    background: var(--secondary-color);
    color: #fff;
}
.btn-primary:active,
.btn-primary.active {
    background: var(--secondary-color);
    color: #fff;
}
.btn-primary .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.btn-inverse {
    background: #424242;
    border-color: #424242;
    color: #ffffff;
}
.btn-group.open .btn-inverse.dropdown-toggle,
.btn-inverse.disabled,
.btn-inverse[disabled],
.btn-inverse:hover,
.btn-inverse:focus {
    background: #424242;
    color: #fff;
}
.btn-inverse:active,
.btn-inverse.active {
    background: #424242;
    color: #fff;
}
.btn-inverse .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.btn-success {
    background: #008000;
    border-color: #008000;
    color: #ffffff;
}
.btn-group.open .btn-success.dropdown-toggle,
.btn-success.disabled,
.btn-success[disabled],
.btn-success:hover,
.btn-success:focus {
    background: #008000;
    color: #fff;
}
.btn-success:active,
.btn-success.active {
    background: #008000;
    color: #fff;
}
.btn-success .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.btn-group .btn-primary:not(.dropdown-toggle),
.btn-group .btn-primary:not('[data-toggle*=""]') {
    margin-right: 2px;
}
.btn-group-vertical.block {
    display: block;
}
.btn-group.btn-block {
    padding: 0;
    position: relative;
}
.btn-group.btn-block .dropdown-toggle {
    width: 30px;
    float: right;
    box-sizing: border-box;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
.btn-icon-stacked {
    text-align: left;
    padding: 5px 5px 5px 45px;
}
.btn-icon-stacked i {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 10px;
}
.btn-icon-stacked i:before {
    left: auto;
    top: auto;
    position: relative;
    text-align: center;
}
.btn-icon-stacked span {
    display: block;
    font-size: 13px;
    line-height: normal;
}
.btn-facebook {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    background: #5d7cb6;
    border: 1px solid #5d7cb6;
}
.btn-facebook i:before {
    color: #fff;
}
.btn-facebook:hover {
    background: #8098c6;
    color: #fff;
}
.btn-google {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    background: #9e3838;
    border: 1px solid #9e3838;
}
.btn-google i:before {
    color: #fff;
}
.btn-google:hover {
    background: #bf4a4a;
    color: #fff;
}
.btn-toggle-code {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    border-radius: 0 0 0 0;
}
#demo_buttons .btn-block {
    max-width: 200px;
    margin: 0 auto;
}
#demo_buttons thead th {
    width: 25%;
}
.form-inline.small input,
.form-inline.small select {
    margin: 0 5px 0 0;
    border-radius: 0 0 0 0;
    padding: 1px 5px;
    border-color: #d8d9da;
    color: #a7a7a7;
}
.form-inline.small select {
    padding: 1px 0;
    height: 23px;
}
.form-inline.small select:last-child {
    margin-right: 0;
}
.form-inline.small label {
    float: left;
    margin: 0 5px 0 0;
    display: block;
}
.form-inline.small .input-append.block {
    display: block;
}
.form-inline.small .input-append input {
    margin: 0;
    width: 85%;
}
.form-inline.small .input-append .add-on {
    padding: 1px 3px;
    margin: 0 0 0 -1px;
    border-radius: 0 0 0 0;
    background: #fff;
    color: #d8d9da;
    border-color: #d8d9da;
}
.form-inline.small .input-append .add-on i:before {
    top: 4px;
    left: 4px;
    font-size: 14px;
    color: #d0d1d1;
}
textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
    border-color: #efefef;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
    font-size: 13px;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0 0 0 0;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
    margin-left: 3px;
}
.checkbox.inline {
    padding-bottom: 1px;
}
label,
input,
button,
select,
textarea {
    font-size: 13px;
}
.input-full input {
    width: 83%;
    height: 17px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu {
    font-size: 13px;
}
.strong {
    font-weight: bold;
}

.control-label.center {
    text-align: center;
}
.form-actions {
    background: none;
    border: none;
    margin: 0;
}
.form-horizontal .form-actions {
    margin-top: 0;
    padding: 0;
}
.form-horizontal .controls {
    margin-left: 145px;
}
.form-horizontal .control-label {
    width: 125px;
}
.form-horizontal .control-group:last-child {
    margin: 0;
}
.input-prepend .add-on,
.input-append .add-on {
    background: #fff;
    border-color: #efefef;
}
.input-prepend .add-on i:before,
.input-append .add-on i:before {
    color: #d0d1d1;
}
.input-prepend .add-on icon,
.input-append .add-on icon {
    margin-top: 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child,
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child,
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child,
.input-append input,
.input-append select,
.input-append .uneditable-input,
.fileupload-new .input-append .btn-file,
.btn-group > .btn:first-child,
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle,
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
    border-radius: 0 0 0 0;
}
.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background: var(--secondary-color);
}
.ms-container .custom-header {
    height: 30px;
    line-height: 30px;
    background-color: #f9f9f9;
    background-image: linear-gradient(to bottom, #fdfdfd, #f4f4f4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffdfdfd', endColorstr='#fff4f4f4', GradientType=0);
    padding: 0 15px;
    font-weight: 600;
    border: 1px solid #efefef;
}
.ms-container .custom-header:not(.custom-footer) {
    border-bottom: none;
}
.ms-container .custom-header.custom-footer {
    border-top: none;
}
img.flag {
    height: 10px;
    width: 15px;
    padding-right: 10px;
    z-index: 99999;
}
.bootstrap-timepicker-widget table td {
    font-size: 13px;
}
.bootstrap-timepicker > [class*='icon-'] {
    vertical-align: text-top;
    margin: 0 0 0 -22.5px;
    pointer-events: none;
    position: relative;
    cursor: pointer;
}
.toggle-button {
    vertical-align: middle;
    border-color: #bebebe;
    border-radius: 0 0 0 0;
}
.toggle-button label {
    background-image: none !important;
    filter: none !important;
    border-radius: 0 0 0 0;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.toggle-button.primary {
    border-color: var(--secondary-color);
}
.toggle-button.primary span {
    background: var(--secondary-color);
    color: #fff;
    text-shadow: none;
    text-shadow: 0 1px 0 #444;
}
.toggle-button.danger {
    border-color: #ff0000;
}
.toggle-button.danger span {
    background: #ff0000;
    color: #fff;
    text-shadow: none;
    text-shadow: 0 1px 0 #444;
}
.toggle-button.warning {
    border-color: #ab7a4b;
}
.toggle-button.warning span {
    background: #ab7a4b;
    color: #fff;
    text-shadow: none;
    text-shadow: 0 1px 0 #444;
}
.toggle-button.success {
    border-color: #008000;
}
.toggle-button.success span {
    background: #008000;
    color: #fff;
    text-shadow: none;
    text-shadow: 0 1px 0 #444;
}
.toggle-button.inverse {
    border-color: #424242;
}
.toggle-button.inverse span {
    background: #424242;
    color: #fff;
    text-shadow: none;
    text-shadow: 0 1px 0 #444;
}
.table {
    margin: 0 0 15px;
    position: relative;
    border-radius: 0 0 0 0;
}
.table.table-white {
    background: #fff;
}
.table th,
.table td {
    border-top-color: #ebebeb;
}
.table td.center,
.table th.center {
    text-align: center;
}
.table td.right,
.table th.right {
    text-align: right;
}
.table-condensed {
    font-size: 10pt;
}
.table-condensed th,
.table-condensed td {
    padding: 4px 10px;
}
.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
    background-color: #fafafa;
}
.table-bordered {
    border-color: #efefef;
    width: 100%;
    /*text-align:center;*/
    /* Reset rounded corners */
}
.table-bordered th,
.table-bordered td {
    border-color: #efefef;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child,
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child,
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
    border-radius: 0 0 0 0;
}
.table-fill td {
    background: #f8f8f8;
}
.table .alert:last-child {
    margin: 0;
}
.table-large-spacing td {
    padding: 20px 15px;
}
.table .thead td {
    padding: 8px;
    font-weight: bold;
}
.table-vertical-center td,
.table-vertical-center th {
    vertical-align: middle;
}
.table-thead-simple thead th {
    background: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #ebebeb;
    color: #7c7c7c;
    border-radius: 0 0 0 0;
    text-shadow: none;
    text-transform: none;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0), inset -1px -1px 1px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0), inset -1px -1px 1px rgba(0, 0, 0, 0);
}
.table-thead-simple.table-thead-border-none {
    border-top: none;
    border-radius: 0 0 0 0;
}
.table-thead-simple.table-thead-border-none thead th {
    border-bottom: none;
}
.table-thead-simple.table-thead-border-none thead:first-child tr:first-child > th:last-child,
.table-thead-simple.table-thead-border-none tbody:first-child tr:first-child > td:last-child,
.table-thead-simple.table-thead-border-none tbody:first-child tr:first-child > th:last-child {
    border-radius: 0 0 0 0;
}
.table-primary {
    border-color: #efefef;
    border-top: none;
}
.table-primary thead th {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
    font-size: 14px;
}
.table-primary tbody td {
    color: #7c7c7c;
    background: #fafafa;
    border-width: 0px;
}
.table-primary tbody td.important {
    color: var(--secondary-color);
    font-weight: 600;
}
.table-primary tbody td.actions {
    padding-right: 1px;
}
.table-primary.table-bordered tbody td {
    border-color: #efefef;
    border-width: 1px;
    /*
			box-shadow: 0 0 0 1px #fff inset;
			-webkit-box-shadow: 0 0 0 1px #fff inset;
			-moz-box-shadow: 0 0 0 1px #fff inset;
			*/
}
.table-primary tbody tr:nth-child(odd) td,
.table-primary tbody tr:nth-child(odd) th {
    background: #ffffff;
}
.table-primary tbody tr.selectable td {
    cursor: pointer;
}
.table-primary tbody tr.selected td,
.table-primary tbody tr.selectable:hover td {
    background: #dbdbdb;
}
.row-fluid [class*='span']:last-of-type .dataTables_filter {
    text-align: right;
}
@media only screen and (max-width: 979px) {
    .table-responsive.swipe-horizontal {
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;
        width: 100%;
        border-left: 1px solid #ddd;
    }
    .table-responsive.swipe-horizontal th,
    .table-responsive.swipe-horizontal td {
        display: block;
        margin: 0;
        vertical-align: top;
    }
    .table-responsive.swipe-horizontal th {
        text-align: right;
        border-bottom: 0;
        border-left: 0;
    }
    .table-responsive.swipe-horizontal td {
        min-height: 1.25em;
        text-align: left;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top: 0;
        font-size: 13px;
    }
    .table-responsive.swipe-horizontal thead {
        display: block;
        float: left;
    }
    .table-responsive.swipe-horizontal thead tr {
        display: block;
    }
    .table-responsive.swipe-horizontal tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
        word-spacing: 0;
        letter-spacing: 0;
        font-size: 0;
    }
    .table-responsive.swipe-horizontal tbody tr {
        display: inline-block;
        vertical-align: top;
        border-left: 1px solid #e5e5e5;
    }
    .table-responsive.block {
        /* Force table to not be like tables anymore */
        /* Hide table headers (but not display: none;, for accessibility) */
        /* Label the data */
    }
    .table-responsive.block table,
    .table-responsive.block thead,
    .table-responsive.block tbody,
    .table-responsive.block th,
    .table-responsive.block td,
    .table-responsive.block tr {
        display: block;
    }
    .table-responsive.block thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .table-responsive.block tr {
        border: 1px solid #e5e5e5;
    }
    .table-responsive.block td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: left;
    }
    .table-responsive.block td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        vertical-align: middle;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: right;
        font-weight: bold;
    }
    .table-responsive.block td:before {
        content: attr(data-title);
    }
}
.label.large {
    height: 29px;
    padding: 0 10px;
    line-height: 29px;
    background: #e5e5e5;
    color: #64625f;
    text-shadow: 0 1px 0 #fff;
}
.label.label-primary {
    background: var(--secondary-color);
}
.label.label-warning {
    background: #ab7a4b;
}
.label.label-important {
    background: #ff0000;
}
.label.label-success {
    background: #008000;
}
.label.label-block {
    display: block;
}
.badge.fix {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    padding: 0;
    border-radius: 50% 50% 50% 50%;
    border: none;
}
.badge.badge-primary {
    background: var(--secondary-color);
}
.badge.badge-warning {
    background: #ab7a4b;
}
.badge.badge-danger {
    background: #ff0000;
}
.badge.badge-success {
    background: #008000;
}
.badge.badge-info {
    background: #5cc7dd;
}
.badge.badge-block {
    display: block;
}
.tooltip .tooltip-inner {
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: 0 0 0 0;
}
.tooltip.right .tooltip-arrow {
    border-right-color: var(--secondary-color);
}
.tooltip.left {
    margin-left: -13px;
}
.tooltip.left .tooltip-arrow {
    border-left-color: var(--secondary-color);
}
.tooltip.top .tooltip-arrow {
    border-top-color: var(--secondary-color);
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: var(--secondary-color);
}
.modal-backdrop {
    z-index: 10000;
    background: #000000;
}
.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.modal {
    z-index: 100001;
    border-color: #efefef;
    background: #ffffff;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0 0 0 0;
}
.modal .modal-header {
    padding: 0 10px;
    height: 29px;
    line-height: 29px;
    border-color: #efefef;
    background-image: none !important;
    filter: none !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    background: #f4f4f4;
    border-radius: 0 0 0 0;
}
.modal .modal-header h3 {
    font-size: 14px;
}
.modal .modal-header .close {
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0;
    margin: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #7c7c7c;
    text-shadow: none;
}
.modal .modal-body {
    padding: 10px;
}
.modal .modal-body p:last-child {
    margin: 0;
}
.modal .modal-footer {
    border-radius: 0 0 0 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #ebebeb;
    padding: 10px;
    background: #fafafa repeat;
}
.modal.modal-inverse {
    background: #424242;
    border-color: #424242;
    color: #fff;
}
.modal.modal-inverse .modal-header {
    background: #424242;
    border-color: #5c5c5c;
}
.modal.modal-inverse .modal-header,
.modal.modal-inverse .modal-header * {
    color: #fff;
}
.modal.modal-inverse .modal-footer {
    background: #424242;
    border-color: #5c5c5c;
}
.modal.modal-primary {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;
}
.modal.modal-primary .modal-header {
    background: var(--secondary-color);
    border-color: #eb6a5a;
}
.modal.modal-primary .modal-header,
.modal.modal-primary .modal-header * {
    color: #fff;
}
.modal.modal-primary .modal-footer {
    background: var(--secondary-color);
    border-color: #eb6a5a;
}
.modal.modal-info {
    background: #5cc7dd;
    border-color: #5cc7dd;
    color: #fff;
}
.modal.modal-info .modal-header {
    background: #5cc7dd;
    border-color: #86d5e6;
}
.modal.modal-info .modal-header,
.modal.modal-info .modal-header * {
    color: #fff;
}
.modal.modal-info .modal-footer {
    background: #5cc7dd;
    border-color: #86d5e6;
}
.modal.modal-warning {
    background: #ab7a4b;
    border-color: #ab7a4b;
    color: #fff;
}
.modal.modal-warning .modal-header {
    background: #ab7a4b;
    border-color: #be946b;
}
.modal.modal-warning .modal-header,
.modal.modal-warning .modal-header * {
    color: #fff;
}
.modal.modal-warning .modal-footer {
    background: #ab7a4b;
    border-color: #be946b;
}
.modal.modal-success {
    background: #008000;
    border-color: #008000;
    color: #fff;
}
.modal.modal-success .modal-header {
    background: #008000;
    border-color: #79ae69;
}
.modal.modal-success .modal-header,
.modal.modal-success .modal-header * {
    color: #fff;
}
.modal.modal-success .modal-footer {
    background: #008000;
    border-color: #79ae69;
}
.progress {
    background: #f0f0f0;
    margin: 0 0 10px;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0 0 0 0;
}
.progress .bar {
    background-image: none !important;
    filter: none !important;
    border-radius: 0 0 0 0;
    background: #7a7a7a;
}
.progress .bar.right {
    text-align: right;
    text-indent: 10px;
    direction: rtl;
}
.progress.white {
    background: #fff;
}
.progress.progress-success .bar {
    background: #008000;
}
.progress.progress-warning .bar {
    background: #ab7a4b;
}
.progress.progress-primary .bar {
    background: var(--secondary-color);
}
.progress.progress-danger .bar {
    background: #ff0000;
}
.progress.progress-inverse .bar {
    background: #424242;
}
.progress.progress-small {
    height: 16px;
}
.progress.progress-small .bar {
    font-size: 8px;
    line-height: 16px;
}
.progress.progress-small .bar.right {
    text-indent: 2px;
}
.progress.progress-mini {
    height: 10px;
}
.pager li > a,
.pager li > span {
    border-radius: 0 0 0 0;
    border-color: #dbdbdb;
    background: #dbdbdb;
    color: #444;
}
.pager li:hover > a,
.pager li:hover > span {
    background: #424242;
    border-color: #424242;
    color: #fff;
}
.pager li.disabled > a,
.pager li.disabled > span {
    border-color: #efefef;
}
.pagination ul {
    background: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    vertical-align: middle;
}
.pagination ul > li > a:hover,
.pagination ul > li.primary > a {
    border-color: #424242;
    background: #424242;
    color: #fff;
    text-shadow: none;
}
.pagination ul > .active > a,
.pagination ul > .active > span {
    border-color: #424242;
    background: #424242;
    color: #fff;
}
.pagination ul > li > a,
.pagination ul > li > span {
    border-color: #dbdbdb;
    background: #dbdbdb;
    border-radius: 0 !important;
    text-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #444;
    font-weight: 600;
}
.pagination ul > li > a {
    margin-right: 1px !important;
}
.pagination ul > .primary.disabled > a,
.pagination ul > .disabled > a,
.pagination ul > .disabled > span {
    border-color: #efefef;
    background: #ffffff;
    color: #dddddd;
    font-weight: 600;
    text-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.pagination ul > .disabled > a:hover {
    border-color: #efefef;
    background: #ffffff;
    color: #dddddd;
    text-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.ui-widget-header {
    border-color: var(--secondary-color);
    background: var(--secondary-color);
    color: #fff;
}
.ui-state-default,
.ui-widget-content .ui-state-default {
    background: #fafafa;
    border-color: #dddddd;
    color: #797979;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
.calendar-state-for {
    background: var(--secondary-color);
    color: #fff;
}
.ui-widget-content {
    border: none;
}
.widget-body-gray .ui-datepicker {
    background: none;
    padding: 0;
    font-size: 12px;
}
.widget-body-gray .ui-datepicker .ui-widget-header {
    background: none;
    color: #4d4d4d;
    border: none;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar {
    border-collapse: collapse;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar thead {
    background: #cecece;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar thead th {
    padding: 0.5em 0.3em;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar tbody td {
    padding: 0;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar tbody td span {
    text-align: center;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar tbody td a {
    text-align: center;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar tbody td a.ui-state-default {
    background: none;
    color: #4d4d4d;
    font-weight: 600;
}
.widget-body-gray .ui-datepicker .ui-datepicker-calendar tbody td a.ui-state-active {
    background: var(--secondary-color);
    color: #fff;
}
.ui-datepicker-inline {
    width: 100%;
    padding: 0;
}
.datepicker-inline {
    margin: 0 0 5px;
}
@media (max-width: 480px) {
    /* badges only on small screens */
}
.nav-timeline > li {
    margin: 0 0 5px;
}
.nav-timeline > li > a {
    background: #b3b3b3;
    border: none;
    margin: 0 !important;
    font-weight: 600;
    border-radius: 0 0 0 0;
}
.nav-timeline > li.active > a {
    background: var(--secondary-color);
}
.thumb {
    background-color: #dedede;
    display: block;
    padding: 3px;
    border: 1px solid #efefef;
    max-height: 186px;
    /*margin: -4px;
  /*background: #fff;*/
}
.thumb.small {
    max-width: 80px;
}
.thumbnails {
    margin: 0;
}
.thumbnails > li {
    margin-bottom: 15px;
}
.thumbnails > li .thumbnail {
    border-color: #efefef;
    background: #ffffff;
    border-radius: 0 0 0 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.thumbnails > li .thumbnail .caption {
    color: #7c7c7c;
}
.thumbnails > li a.thumbnail:hover,
.thumbnails > li a.thumbnail:focus {
    border-color: var(--secondary-color);
    box-shadow: 0 1px 4px rgba(229, 65, 45, 0.25);
    text-decoration: none;
}
.thumbnail {
    border-color: #efefef;
}
a.thumbnail .caption {
    display: block;
}
a.thumbnail:hover,
a.thumbnail:focus {
    border-color: var(--secondary-color);
    box-shadow: 0 1px 4px rgba(229, 65, 45, 0.25);
    text-decoration: none;
}
#calendar .btn-group .btn-primary {
    margin-right: 2px;
}
html.front.top-full .navbar.main {
    height: auto;
    left: 0 !important;
    background: var(--secondary-color);;
    border-bottom-color: var(--secondary-color);;
}
html.front.top-full .navbar.main .btn-navbar {
    height: 30px;
    float: none;
    background: #f4f4f4;
    border: none;
}
html.front.top-full .navbar.main .btn-navbar span {
    background: #dbdbdb;
}
html.front.top-full .navbar.main > .container-960 > .topnav > li {
    height: 57px;
    line-height: 57px;
    border-right: none;
}
html.front.top-full .navbar.main > .container-960 > .topnav > li > a {
    height: 57px;
    line-height: 57px;
    font-size: 14px;
    color: #999999;
}
html.front.top-full .navbar.main > .container-960 > .topnav > li.active > a {
    background: none;
    color: #ffffff;
}
html.front.top-full .navbar.main .secondary {
    height: 30px;
    line-height: 30px;
    position: relative;
    overflow: hidden;
    background-color: #f4f4f4;
    border-bottom: 1px solid #dbdbdb;
}
html.front.top-full .navbar.main .secondary .topnav > li {
    height: 30px;
    line-height: 30px;
    border-color: #dbdbdb;
}
html.front.top-full .navbar.main .secondary .topnav > li > a {
    height: 30px;
    line-height: 30px;
    color: #dbdbdb;
}
html.front.top-full .navbar.main .secondary .topnav > li:hover > a {
    background: #f4f4f4;
    color: #4d4d4d;
}
html.front.top-full .navbar.main .secondary .topnav > li.follow a {
    font-weight: 400;
}
html.front.top-full .navbar.main .secondary .topnav > li.follow:hover {
    background: #f4f4f4;
}
html.front.top-full .navbar.main .secondary .topnav > li.follow:hover a {
    background: none;
    color: #dbdbdb;
}
html.front.top-full .navbar.main .secondary .topnav > li.follow:hover span {
    color: #4d4d4d;
}
html.front.top-full .navbar.main .secondary .topnav > li.follow [class*='icon-']:hover {
    color: var(--secondary-color);
}
html.front.top-full .navbar.main .secondary .topnav > li.inverse:not(:hover) {
    background: #424242;
}
html.front.top-full .navbar.main .secondary .topnav > li.inverse:not(:hover) a,
html.front.top-full .navbar.main .secondary .topnav > li.inverse:not(:hover) a i:before {
    color: #fff;
}
html.front.top-full .navbar.main .secondary .topnav > li.primary:not(:hover) {
    background: var(--secondary-color);
}
html.front.top-full .navbar.main .secondary .topnav > li.primary:not(:hover) a,
html.front.top-full .navbar.main .secondary .topnav > li.primary:not(:hover) a i:before {
    color: #fff;
}
html.front.top-full .navbar.main .secondary .topnav > li.active:not(:hover) a {
    color: #4d4d4d;
}
html.front.top-full .navbar.main .secondary .topnav > li.active:not(:hover) a i:before {
    color: var(--secondary-color);
}
html.front.top-full .navbar.main .secondary .topnav > li.active:hover a i:before {
    color: var(--secondary-color);
}
html.front #content {
    margin: 0;
    padding: 0;
}
html.front #footer {
    height: auto;
    line-height: 20px;
    background: #1f2123;
    color: #919192;
}
html.front #footer a {
    color: var(--secondary-color);
    text-decoration: none;
}
html.front #footer .copy {
    color: #efefef;
    text-align: left;
}
html.front #footer .box-generic {
    background: #191a1c;
    border: none;
}
html.front #footer h4 {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;
    color: #fff;
    margin: 0 0 8px;
}
html.front #footer ul {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
}
html.front #footer ul li {
    display: block;
    background: #191a1c;
    margin: 0 0 1px;
    line-height: 30px;
    height: 30px;
}
html.front #footer ul li a {
    line-height: 30px;
    height: 30px;
    display: block;
    padding: 0 10px;
}
html.front #footer ul.icons li {
    padding: 0 10px 0 35px;
}
html.front #footer ul.icons li i:before {
    top: 7px;
    left: 10px;
}
.separator-line {
    width: 100%;
    height: 1px;
    background: #f4f4f4;
    margin-bottom: 10px;
}
.container-960 {
    width: 960px;
    margin: 0 auto;
}
.lt-ie9 .widget .widget-body.list ul li {
    border-bottom: 1px solid #efefef;
}
.lt-ie9 .filter-bar div div.input-append .add-on {
    width: 24px;
    padding-right: 0;
    padding-left: 0;
}
.lt-ie9 .filter-bar div div.input-append .add-on i {
    width: 24px;
    height: 24px;
    display: block;
    vertical-align: middle;
    line-height: 24px;
}
.lt-ie9 .filter-bar div div.input-append .add-on i:before {
    width: 24px;
    height: 24px;
    line-height: 24px;
    top: auto;
}
.lt-ie9 .navbar.main .topnav .dropdown > a .caret {
    vertical-align: middle;
    margin-top: 0;
    top: 0;
}
/* Desktop wide */

/* Desktop regular */

@media (min-width: 980px) and (max-width: 1199px) {
    body > .container-fluid.fixed {
        width: 98%;
        margin: 10px auto 10px;
    }
    .hidden-desktop-1 {
        display: none !important;
    }
}
/* Tablet Landscape */

@media (max-width: 1199px) {
    /*#menu .btn-navbar { display: block !important; }*/
}
/* Phone & Tablet Portrait */

/* Tablet */

@media (max-width: 979px) and (min-width: 768px) {
    /* Dashboard Fix */

    .tablet-column-reset {
        float: none !important;
        width: 100% !important;
        margin-left: 0 !important;
        clear: both !important;
    }
    .container-960 {
        width: auto;
    }
    #content .view-content-wrapper > .container-960,
    #content [id*='landing'] .container-960 {
        margin: 0 10px;
    }
}
@media (max-width: 979px) {
    body > .container-fluid.fixed {
        width: auto;
        border: none;
        margin: 0;
    }
}
@media (max-width: 767px) {
    body {
        padding: 0;
    }
    .container-960 {
        width: auto;
    }
    .nav-tabs > li > a {
        font-size: 10pt;
    }
    .nav-tabs > li > a {
        padding-left: 5px;
        padding-right: 5px;
    }
    .phone-margin.bottom {
        margin: 0 0 10px;
    }
    .well {
        padding: 5px;
    }
    .margin-bottom-phone {
        margin-bottom: 15px !important;
    }
    .padding-bottom-none-phone {
        padding-bottom: 0 !important;
    }
    .layout-timeline ul.timeline > li .alert-gray {
        padding: 8px;
    }
    .widget .widget-body {
        padding: 5px;
    }
    .widget.margin-bottom-none {
        margin-bottom: 10px;
    }
    .widget.widget-tabs .widget-head {
        height: auto;
        padding: 0;
    }
    .widget.widget-tabs .widget-head ul {
        display: block;
        border-right: none;
        height: auto;
    }
    .widget.widget-tabs .widget-head ul li {
        float: none;
        display: block;
        width: 100%;
    }
    .widget.widget-tabs-icons-only .widget-head {
        height: auto;
    }
    .widget-chat .media .pull-left,
    .widget-chat .media .pull-right {
        margin: 0;
    }
    .widget-chat .chat-controls {
        height: 60px;
    }
    .widget-chat {
        padding-bottom: 90px !important;
    }
    .widget-chat .media small.author {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        width: auto;
    }
    .widget-chat .media small.date {
        border: none;
    }
    .layout-timeline {
        padding: 0;
    }
    .layout-timeline ul.timeline {
        padding: 0;
    }
    .layout-timeline ul.timeline > li,
    .layout-timeline ul.timeline > li:nth-child(odd) {
        left: auto;
        margin-left: 0;
        width: auto;
    }
    .layout-timeline ul.timeline > li .date,
    .layout-timeline ul.timeline > li:nth-child(odd) .date {
        position: relative;
        left: auto;
        display: block;
        top: auto;
        right: auto;
    }
    .layout-timeline ul.timeline > li .type,
    .layout-timeline ul.timeline > li:nth-child(odd) .type {
        position: relative;
        left: auto;
        top: auto;
        width: auto;
        margin: 0 60px 10px 0;
        right: auto;
        padding: 0 35px 0 0;
    }
    .layout-timeline ul.timeline > li .type i:before,
    .layout-timeline ul.timeline > li:nth-child(odd) .type i:before {
        left: auto;
        right: 0;
    }
    .layout-timeline ul.timeline > li .type:before,
    .layout-timeline ul.timeline > li:nth-child(odd) .type:before,
    .layout-timeline ul.timeline > li .type:after,
    .layout-timeline ul.timeline > li:nth-child(odd) .type:after {
        display: none;
        left: auto;
    }
    .layout-timeline ul.timeline > li .type .time,
    .layout-timeline ul.timeline > li:nth-child(odd) .type .time {
        top: 0;
        right: -40px;
        left: auto;
    }
    .layout-timeline ul.timeline > li .separator .type,
    .layout-timeline ul.timeline > li:nth-child(odd) .separator .type {
        top: auto;
    }
    .layout-timeline ul.timeline > li.active .separator .type,
    .layout-timeline ul.timeline > li:nth-child(odd).active .separator .type {
        margin-bottom: 0;
    }
    .widget.widget-tabs.widget-tabs-icons-only-2 .widget-head {
        height: 35px;
    }
    .widget.widget-tabs.widget-tabs-icons-only-2 .widget-head ul li {
        float: left;
        width: auto;
    }
    .media {
        text-align: center;
    }
    .media .pull-left,
    .media .pull-right {
        border: none;
        padding-bottom: 0;
        margin: 0;
    }
    .media img.pull-left,
    .media img.pull-right {
        display: inline-block;
    }
    .media blockquote {
        padding: 0;
        text-align: center;
    }
    .media blockquote.pull-right p,
    .media blockquote.pull-right small {
        text-align: center;
    }
    .menu-right #menu {
        left: 0;
        right: auto;
    }
    .menu-right.menu-hidden #content {
        margin: 0;
    }
    .row-merge > [class*='span'] ~ [class*='span'] {
        width: auto;
    }
    .row-merge > [class*='span'] ~ [class*='span']:after {
        display: none;
    }
    .row-merge.border-top,
    .row-merge ~ .row-merge {
        border-top: 1px solid #e5e5e5;
    }
    body.login #login {
        padding: 50px 5px;
    }
    html.top-full .topnav .dropdown.submenu {
        height: auto;
    }
    html.top-full .topnav .dropdown.submenu .submenu-show {
        border: none !important;
        display: block !important;
        position: relative;
        margin: 0 !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
    }
    html.sidebar.sidebar-full body .menu-right #menu > ul > li.open .dropdown-menu.submenu-show.pull-right {
        border: none !important;
        display: block !important;
        position: relative;
        margin: 0 !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
    }
    html.top-full .container-fluid:not(.menu-hidden) .navbar.main .topnav > li {
        display: block;
    }
    html.front.top-full .navbar.main {
        height: 88px;
        overflow: hidden;
    }
    html.front.top-full .navbar.main .topnav > li.active.dropdown {
        display: block;
        border-bottom: none;
    }
    html.top-full .navbar.main {
        height: 44px;
        border-bottom: none;
    }
    html.top-full .navbar.main .topnav {
        border-left: none;
        border-right: none;
        /*float: none;*/
        /* REMOVE BY BLOCKBASE */
    }
    html.top-full .navbar.main .topnav > li {
        /*display: none;*/
        /* REMOVE BY BLOCKBASE */
        float: none;
        border-bottom: 1px solid #333333;
        border-left: none;
        border-right: none;
    }
    html.top-full .navbar.main .topnav > li.active {
        display: block;
    }
    html.top-full .navbar.main .topnav > li .dropdown.submenu .dropdown-menu {
        position: relative;
        float: none;
        display: block !important;
        padding: 0;
        border: none;
        box-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
    .menu-hidden .navbar.main {
        height: 50px;
    }
}
@media (max-width: 480px) {
    h1,
    h2,
    h3 {
        line-height: 30px;
        font-size: 18pt;
    }
    .heading-buttons .buttons {
        margin-top: 10px;
        float: none;
        padding: 0 10px;
        text-align: center;
    }
    .heading-buttons .buttons .btn {
        margin: 0;
        float: none;
    }
    .separator {
        padding: 5px 0;
        margin: 0;
    }
    .table-condensed th,
    .table-condensed td {
        padding: 4px 5px;
    }
}
/* MODIFICATION BLOCKBASE */

@media (min-width: 768px) and (max-width: 1141px) {
    .row-merge.layout-timeline > [class*='span'] ~ [class*='span8'] {
        padding: 0 49px 49px 49px;
    }
    #login > div > div.span3 > div.widget.widget-heading-simple {
        width: 100%;
        min-width: 300px;
    }
}
/* END MODIFICATION BLOCKBASE */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v128/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}
@font-face {
    font-family: 'Font BZ';
    font-style: normal;
    font-weight: 400;
    src: url(../vendor/fonts/font-bz.eot);
    src: local('Font BZ'), local('FontBZ-Regular'), url(../vendor/fonts/font-bz.ttf) format('truetype');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    -webkit-font-feature-settings: 'liga';
       -moz-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}
html.top-full .navbar.main {
    height: 57px;
    background-color: var(--primary-color);
}
[class^='bz-icon-'],
[class*=' bz-icon-'] {
    font-family: 'Font BZ' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
}

.bz-icon-user-dropdown-admin-panel-settings:before {
    content: '\e900';
}
.bz-icon-user-dropdown-dashboard:before {
    content: '\e901';
}
.bz-icon-user-dropdown-devices:before {
    content: '\e902';
}
.bz-icon-user-dropdown-form-templates:before {
    content: '\e903';
}
.bz-icon-user-dropdown-help-center:before {
    content: '\e904';
}
.bz-icon-user-dropdown-log-out:before {
    content: '\e905';
}
.bz-icon-user-dropdown-my-account:before {
    content: '\e906';
}
.bz-icon-user-dropdown-my-contact:before {
    content: '\e907';
}
.bz-icon-user-dropdown-project-templates:before {
    content: '\e908';
}

.material-icons.clickable {
    cursor: pointer;
}
.material-icons.md-14 {
    font-size: 14px;
}
.material-icons.md-15 {
    font-size: 15px;
}
.material-icons.md-16 {
    font-size: 16px;
}
.material-icons.md-17 {
    font-size: 17px;
}
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-19 {
    font-size: 19px;
}
.material-icons.md-20 {
    font-size: 20px;
}
.material-icons.md-21 {
    font-size: 21px;
}
.material-icons.md-22 {
    font-size: 22px;
}
.material-icons.md-23 {
    font-size: 23px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-25 {
    font-size: 25px;
}
.material-icons.md-28 {
    font-size: 28px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}
.material-icons.prio-levelO {
    color: #7c7c7c;
}
.material-icons.prio-level1 {
    color: #339966;
}
.material-icons.prio-level2 {
    color: #669933;
}
.material-icons.prio-level3 {
    color: #ffcc33;
}
.material-icons.prio-level4 {
    color: #ff9900;
}
.material-icons.prio-level5 {
    color: #ff6633;
}
.material-icons.prio-level6 {
    color: #990000;
}
.layout,
.layout-column,
.layout-row {
    display: -ms-flexbox;
}
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}
.welcome-title {
    color: #e7e7e7;
    text-transform: uppercase;
}
.welcome-text {
    color: #e7e7e7;
}
.Css-Center-Modal {
    bottom: 10%;
}
@media (min-width: 768px) {
    .Css-Center-Modal {
        width: 80%;
        margin-left: -40%;
        /* position: fixed;
      top: 10%;
      left: 18.5%;
      z-index: 1050;
      width: 80%;
      height: 80%;
      margin-left: -10%;*/
    }
}
.strike-text {
    text-decoration: line-through;
}
.bold-text {
    font-weight: 800;
}
hr {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, #eee, #888, #eee);
}
h5.filter-title {
    color: #333;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 10px;
}

.btn {
    border-radius: 4px;
}
.btn-active {
    background-color: #999 !important;
}
.btn-active:hover {
    background-color: #999 !important;
}
.btn:not(.btn-primary):not(.btn-zone):not(.btn-tag):not(.btn-document):not(.btn-warning):not(.btn-danger):not(.btn-success) {
    background-color: #f5f5f5;
    background-image: none;
    border: none;
}
.btn:not(.btn-primary):not(.btn-warning):not(.btn-danger):not(.btn-success):not(.btn-header):hover {
    background-color: #dadada !important;
}
.btn[disabled='disabled'],
.btn:disabled:not(.btn-primary) {
    background-color: #dadada;
}
.done {
    background: #008000;
    border-color: #008000;
    color: #ffffff;
}
.in-progress {
    background: #ab7a4b;
    border-color: #ab7a4b;
    color: #ffffff;
}
.todo {
    background: #ff0000;
    border-color: #ff0000;
    color: #ffffff;
}
.table-bordered th,
.table-bordered td {
    border: none;
    vertical-align: middle;
}

.list-breadcrumb {
    background-color: var(--secondary-color);
    z-index: 10;
    height: 39px;
}

.innerLR.innerLR-head.forms-list-breadcrumb {
    background-color: var(--secondary-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin: 57px 0 0 0;
}
.innerLR.innerLR-head.forms-list-breadcrumb .md-button {
    color: white;
}
.myprogress-bar-success {
    background-color: #5cb85c !important;
}
.myprogress-bar-warning {
    background-color: #f0ad4e !important;
}
.myprogress-bar-danger {
    background-color: #d9534f !important;
}
.myprogress {
    height: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.myprogress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease;
}
.myprogress-bar.clickable > span {
    cursor: pointer;
}
.myprogress-bar-striped,
.myprogress-striped .myprogress-bar {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 40px 40px;
}
.loading-mask:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(70, 70, 70, 0.3);
    z-index: 1;
}
.tags-list-search {
    background-color: #f7f7f7 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin: 82px 0 0 0;
    padding: 15px 10px 10px 10px;
}
.navbar.main {
    left: 0 !important;
}
#content {
    margin: 0 !important;
}
.md-datepicker-button {
    display: none;
}
.md-datepicker-input {
}
.project-drawer .md-datepicker-input {
    width: 184px;
}
md-input-container label:not(.md-container-ignore) {
    left: 2px;
    margin-bottom: 0;
}
.list-item-no-hover .md-button:hover {
    background-color: rgba(0, 0, 0, 0);
}
md-input-container label:not(.md-container-ignore) + :not(textarea) {
    bottom: 95%;
}
md-input-container.tag-input input {
    border-radius: 4px;
    background: #aaa;
    padding: 0px;
}
md-input-container input[type='password'].md-input,
md-input-container input[type='text'].md-input,
md-input-container input[type='email'].md-input,
md-input-container input[type='number'].md-input {
    -ms-flex-order: 2;
        order: 2;
    display: block;
    margin-top: 0;
    padding: 2px 2px 1px;
    border-width: 0 0 1px;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left;
}
.form-template .contact-book {
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
}
.form-template .shared-form {
    opacity: 0.54;
}
.form-template.active {
    background-color: #eee;
}
.contact-book:hover {
    background-color: #dbdbdb;
}
#forms-sidenav {
    width: 450px;
    z-index: 60;
    overflow: hidden;
}
#form-builder-container {
    height: 100%;
}
#contacts-sidenav {
    width: 250px;
    z-index: 10;
}
md-input-container input[type='number'] ~ .md-errors-spacer,
.forms-template-search .md-errors-spacer,
md-input-container.pb-desc-field .md-errors-spacer,
md-input-container.report-name-input .md-errors-spacer,
.user-edit-modal md-input-container .md-errors-spacer,
#drawer-projects md-input-container .md-errors-spacer,
md-input-container.project-input-search .md-errors-spacer,
.note-titles-text-input .md-errors-spacer,
.resignin-card md-input-container .md-errors-spacer,
.project-drawer md-input-container .md-errors-spacer,
.md-dialog-content md-input-container .md-errors-spacer,
.resignin-card-wrapper md-input-container .md-errors-spacer,
.plan-create-tasks-date-picker md-input-container .md-errors-spacer,
.form-builder-card md-input-container .md-errors-spacer,
md-input-container#account-password-input .md-errors-spacer,
.report-share-email-block md-input-container .md-errors-spacer,
md-input-container.account-input-container .md-errors-spacer,
.block-card md-input-container .md-errors-spacer {
    display: none;
}
.sign-container .signature canvas {
    border: 1px solid #999;
    margin: 0 auto;
    cursor: pointer;
}
.sign-result {
    border: 1px solid blue;
    margin: 30px auto 0 auto;
    width: 500px;
    height: 225px;
}
md-input-container.pb-desc-field label {
}
md-input-container.pb-desc-field {
    margin: 11px;
}
md-input-container.pb-desc-field .md-input {
}
/* When the input is focused */
md-input-container.md-input-focused label {
    color: blue;
}
.note-drawer-obsevation-input .md-errors-spacer {
    display: none;
}
.md-date-range-picker > div > div {
    margin: 0;
}
.note-drawer-obsevation-input {
    height: 100px;
    margin: 0;
    resize: vertical;
    overflow: auto;
    width: 98%;
    border-style: none;
    border-color: Transparent;
    overflow: scroll;
    margin-top: 8px;
}
.note-drawer-obsevation-input:focus + button {
    color: white;
    background-color: var(--secondary-color);
    opacity: 1;
    transition: all 0.3s;
}
.note-drawer-obsevation-input:focus + button:hover {
    color: white !important;
    background-color: #008000 !important;
}
.note-drawer-obsevation-save {
    margin: 0;
    background: rgba(101, 101, 101, 0.5);
    position: absolute;
    bottom: -37px;
    left: 2px;
}
.form-field.form-field-movable {
    border-radius: 4px;
    background-color: rgb(228, 228, 228);
    padding: 4px;
}
.form-field.form-field-multiple-move {
    opacity: 0.2;
}
.form-field.form-field-move-target {
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
}

.form-field.form-field-movable:hover {
    border: none;
    border-radius: 4px;
    background-color: rgb(214, 214, 214);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.form-field.form-field-movable.form-field-separator-break {
    margin-top: 15px !important;
}

.form-field-separator {
    background-color: darkslateblue;
    padding: 15px;
    border-radius: 6px;
}
.formblock-open {
    color: orangered;
    cursor: pointer;
    text-decoration: underline;
}
.form-field-separator h3 {
    color: whitesmoke;
}
.form-field-separator h4 {
    color: whitesmoke;
}
.form-field-separator h5 {
    color: whitesmoke;
}
.form-builder-card hr {
    margin: 10px 0;
}
md-select.md-input-invalid .md-select-value {
    border-bottom-color: rgb(213, 0, 0);
    color: rgb(213, 0, 0) !important;
}
button.md-input-invalid {
    color: rgb(213, 0, 0) !important;
    background: rgba(213, 0, 0, 0.16) !important;
}
.form-builder-card .md-input-invalid md-select md-select-value > span,
.md-input-invalid label {
    color: rgb(213, 0, 0);
}
.form-builder-card .md-input-invalid md-select.md-greyLabelTheme-theme .md-select-value {
    border-bottom-color: rgb(213, 0, 0);
    border-width: 0 0 2px;
}
.dndDraggingSource {
    display: none;
}
.form-input-invalid {
    background-color: rgba(238, 162, 162, 0.39) !important;
}
.dndPlaceholder {
    display: block;
    background-color: #eee;
    border-radius: 4px;
    padding: 10px 15px;
    min-height: 42px;
}
.handle {
    cursor: move;
    position: absolute;
    top: 14px;
}
.btn-accept-invitation {
    width: 49%;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}
.btn-accept-invitation > span {
    color: white !important;
    background: none !important;
}
.maps-labels {
    color: #333;
    background: mintcream;
    border: 1px #333;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    opacity: 1;
    pointer-events: none;
    text-align: center;
    width: 60px;
    white-space: nowrap;
}
md-input-container > i {
    top: 8px;
    left: 2px;
    right: auto;
    position: absolute;
}
.md-select-value > span:not(.md-select-icon) .md-text {
    color: #333;
}
.drawer-image-block-container {
    height: 300px;
    overflow: hidden;
    width: 100%;
}
.drawer-image-block-container img {
    width: 100%;
    height: 100%;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: cover;
       object-fit: cover;
}
.drawer-observation-input {
    border-radius: 4px !important;
    background: #7480d2 !important;
    width: 300px !important;
    padding: 0px !important;
}
md-sidenav#note-details-drawer {
    max-width: 650px;
    width: 650px;
}
@media (max-width: 510px) {
    .note-drawer-obsevation-input {
        height: 70px;
    }
}
@media (max-width: 980px) {
    .drawer-observation-input {
        width: 175px !important;
    }
    md-sidenav#note-details-drawer {
        max-width: 500px;
        width: 500px;
    }
}
#drawer-tags-block md-chips md-chip {
    height: 30px;
    line-height: 30px;
}
#drawer-tags-block md-chips md-chip .md-chip-content {
    font-size: 13px;
}
.md-chips md-chip .md-chip-remove-container {
    line-height: 30px;
}
.tag-chip {
    position: relative;
    height: 20px;
    width: 20px;
    line-height: 30px;
    text-align: center;
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border: none;
    box-shadow: none;
    padding: 0;
    top: 5px;
    right: 2px;
    margin: 0;
    transition: background 0.15s linear;
    display: block;
}
.tag-chip i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.7);
    color: white;
    fill: white;
}
.tag-chip:hover {
    background: orangered;
}
@media (max-width: 510px) {
    md-sidenav#note-details-drawer {
        max-width: 400px;
        width: 400px;
    }
    #drawer-tags-block md-chips md-chip {
        height: 24px;
        line-height: 24px;
    }
    #drawer-tags-block md-chips md-chip .md-chip-content {
        font-size: 12px;
    }
}
@media (max-width: 420px) {
    md-sidenav#note-details-drawer {
        max-width: 300px;
        width: 300px;
    }
    #drawer-tags-block md-chips md-chip {
        height: 20px;
        line-height: 20px;
    }
    #drawer-tags-block md-chips md-chip .md-chip-content {
        font-size: 11px;
    }
}
@media (max-width: 310px) {
    md-sidenav#note-details-drawer {
        max-width: 250px;
        width: 250px;
    }
    #drawer-tags-block md-chips md-chip {
        height: 19px;
        line-height: 19px;
    }
    #drawer-tags-block md-chips md-chip .md-chip-content {
        font-size: 10px;
    }
}
#note-details-drawer md-card {
    margin: 0;
}
md-sidenav#note-details-drawer md-content {
    background: #f7f7f7f7;
}
.badge {
    text-shadow: none;
}
md-card md-card-header {
    z-index: 2;
    background: #e2e8ee;
}
md-card.md-default-theme .md-card-image,
md-card .md-card-image {
    z-index: 1;
}
.header-bar .md-nav-bar {
    height: 57px !important;
    display: inline-block !important;
    vertical-align: top;
}
.header-bar.header-bar-tablet .md-button._md-nav-button {
    padding: 9px 4px;
    min-width: 70px;
}
.header-bar .md-nav-bar .md-button._md-nav-button {
    padding: 16px 16px;
}
.md-button._md-nav-button {
    padding: 9px 16px;
}

.header-bar .md-button._md-nav-button.md-unselected {
    color: white !important;
}
.header-bar md-nav-ink-bar {
    color: #e1e4e7 !important;
    background: #e1e4e7 !important;
}
.header-bar > .md-nav-bar .md-button.md-greyLabelTheme-theme.md-accent {
    color: white !important;
}
.jodit-editor-container {
    margin-bottom: 10px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
}
.ql-container {
    height: 150px;
}
.jodit-editor-container.disabled-editor {
    opacity: 0.33;
}
.jodit-wrapper .disabled-editor-title {
    opacity: 0.33 !important;
}
.jodit-editor-container .ql-toolbar.ql-snow {
    border: none !important;
}
.jodit-editor-container .ql-container.ql-snow {
    border: none !important;
}
.project-admin-bar .md-nav-bar {
    margin-top: 25px;
    height: 44px !important;
    display: inline-block !important;
    vertical-align: top;
}
.project-admin-bar .md-button._md-nav-button.md-unselected {
    color: #01111b !important;
}
.project-admin-bar .md-button._md-nav-button {
    color: var(--primary-color) !important;
}
.project-admin-bar md-nav-ink-bar {
    color: var(--primary-color) !important;
    background: var(--primary-color) !important;
}
.project-admin-users-table .md-select-value > span:not(.md-select-icon) {
    text-align: center;
}
.project-admin-users-table tr:nth-child(even) {
    background-color: #f2f2f2;
}
.project-admin-users-table tr:nth-child(even):hover {
    background: #e7e7e7;
}
.project-admin-users-table tr:hover {
    background: #e7e7e7;
}
.project-admin-users-table p {
    margin: 0;
}
.project-admin-users-table-body {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    -moz-user-select: none;
         user-select: none; /* Standard syntax */
}
.project-admin-users-search div.md-errors-spacer,
.batch-emails-input div.md-errors-spacer,
.project-admin-users-table md-input-container div.md-errors-spacer {
    display: none;
}
#forgot-password-input div.md-errors-spacer {
    display: none;
}
md-input-container.md-no-error-spacer div.md-errors-spacer {
    display: none;
}
.project-admin-selected-group {
    background: #bbb;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
}
.project-admin-selected-group::after {
    content: '';
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #bbb;
    top: 24px;
    right: -12px;
}
.project-admin-users-table .md-icon-button {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
}
.project-admin-avatar {
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    height: 32px;
    width: 32px;
    background: #455a64;
    color: #eceff1;
    line-height: 32px;
    vertical-align: middle;
    text-align: center;
}
.project-admin-users-table md-select,
.project-admin-users-table md-input-container {
    margin-top: 5px;
    margin-bottom: 5px;
}
.project-admin-users-table tr:nth-child(even) {
    background-color: #f2f2f2;
}
.menu-zones-open-button,
.demo-menu-open-button {
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 9px;
    padding-left: 0;
    border-bottom: 1px solid black;
    cursor: pointer;
    margin: 8px;
    border-color: rgba(0, 0, 0, 0.12);
    position: relative;
    margin-top: 13px;
    font-size: 14px;
}
.project-date-filter {
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 3px;
    border-bottom: 1px solid black;
    cursor: pointer;
    margin: 8px;
    border-color: rgba(0, 0, 0, 0.12);
    position: relative;
    margin-top: 30px;
}
.md-select-menu-container {
    z-index: 9999;
}
.md-backdrop-select {
    z-index: 9999;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.note-filter-select {
    margin: 0;
    font-size: 14px;
}
.note-filter-select input {
    font-size: 14px;
}
.note-filter-select .md-errors-spacer {
    display: none;
}
.quick-filter > p {
    color: #01579b;
    text-decoration: underline;
}
.quick-filter > button {
    margin: 0;
}
.quick-filter > p:hover {
    color: rgb(255, 102, 0);
    cursor: pointer;
}
.quick-filter > p:active {
    color: rgb(255, 72, 0);
}
.md-button.md-accent {
    color: #e1e4e7;
}
.notes-list-toolbar {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #f7f7f7 !important;
    margin: 81px 0 0 0;
    padding: 10px !important;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
}
.bz-list-toolbar {
    height: 62px;
    position: fixed;
    top: 96px;
    width: 100%;
    z-index: 58;
    background: white;
}

.bz-list-toolbar-embedded {
    z-index: 58;
    background: white;
}
.empty-loader.form-field-loader {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 900;
    border-radius: 4px;
}
.empty-loader {
    background: rgb(0, 0, 0, 0.3);
}
.empty-loader svg,
.note-empty-loader svg {
    animation: rotation 1.4s linear infinite;
}

.empty-loader svg:nth-child(1) {
    stroke: var(--primary-color);
}

.note-empty-state {
    z-index: 20;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
}
.empty-state {
    z-index: 20;
    padding: 30px;
    margin: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.64);
    background-color: #ccc;
}
.note-empty-state span i,
.empty-state span i {
    color: white;
    background-color: #7c7c7c;
    border-radius: 50%;
    padding: 15px;
    opacity: 0.5;
    margin-bottom: 10px;
}
.note-empty-state span,
.empty-state span {
    text-align: center;
    display: block;
}

.bz-list-toolbar > div {
    background: white;
    height: 62px;
}
.bz-list-toolbar md-input-container .md-errors-spacer {
    display: none;
}
.bz-list-toolbar.projects {
    top: 57px;
}
.bz-list-toolbar .md-button.md-icon-button {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.bz-list-toolbar button span,
.bz-list-toolbar button i {
    vertical-align: middle;
}
.bz-list-toolbar button:not(.md-greyLabelTheme-theme) span,
.bz-list-toolbar button:not(.md-greyLabelTheme-theme) i {
    color: black;
}
.bz-list-toolbar button.btn-tag {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.bz-list-toolbar button.btn-document {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}
.chip-container .chip {
    cursor: pointer;
    margin: 0px;
    border: 1px solid;
    padding: 5px;
    font-size: 16px;
    border-radius: 25px;
    text-transform: uppercase;
}
.chip-container .chip:hover {
    background: #ddd;
}
.chip-container .chip.selected-chip {
    border: 1px solid orangered;
    background: #ff46008a;
    color: white;
}
.chip-container.chip-invalid .chip {
    border: 1px solid rgb(221, 44, 0) !important;
    background: rgb(229, 77, 39) !important;
    color: white;
}
@media (max-width: 979px) {
    .bz-list-toolbar .md-button {
        min-width: 0;
        line-height: 24px;
        padding: 8px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    /* .bz-list-toolbar .md-button span { */
    /* display:none; */
    /* } */
}
@media (max-width: 1080px) {
    .bz-list-toolbar .md-button.md-specific-button {
        min-width: 0;
        line-height: 24px;
        padding: 8px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .bz-list-toolbar .md-specific-button span {
        display: none;
    }
}
@media (max-width: 1280px) {
    .bz-list-toolbar .md-hide-label-button {
        min-width: 0;
        line-height: 24px;
        padding: 8px;
        width: 40px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .bz-list-toolbar .md-hide-label-button span {
        display: none;
    }
}
.bz-list-toolbar .notes-list-toolbar button i.material-icons {
    vertical-align: top;
    line-height: 20px;
}
@media (max-width: 785px) {
    .notes-list-toolbar span button span {
        display: none !important;
    }
    .notes-list-toolbar span div div span {
        display: none !important;
    }
}
/**
 * Block animation in noteview
 */

.animated-block {
    transition: 0.5s linear all;
}
.animated-block .animated-block-right-part {
    animation: fadeInRight 0.5s;
}
.animated-block .animated-block-left-part {
    animation: rotateInUpRight 0.5s;
}
.animated-block.ng-enter .animated-block-right-part,
.animated-block.ng-leave.ng-leave-active .animated-block-right-part {
    animation: fadeOutRight 0.5s;
}
.animated-block.ng-leave .animated-block-right-part,
.animated-block.ng-enter.ng-enter-active .animated-block-right-part {
    animation: fadeInRight 0.5s;
}
.animated-block.ng-enter .animated-block-left-part,
.animated-block.ng-leave.ng-leave-active .animated-block-left-part {
    animation: rotateOutDownRight 0.5s;
}
.animated-block.ng-leave .animated-block-left-part,
.animated-block.ng-enter.ng-enter-active .animated-block-left-part {
    animation: rotateInUpRight 0.5s;
}
/**
 * End block animation in noteview
 */

/**
 * Range slider
 */

.range {
    display: table;
    position: relative;
    height: 70px;
    margin-top: 20px;
    background-color: #f4f4f4;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.range input[type='range'] {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    display: table-cell;
    width: 100%;
    background-color: transparent;
    height: 70px;
    cursor: pointer;
}
.range input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    width: 70px;
    height: 70px;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0px;
    background-color: rgb(153, 153, 153);
}
.range input[type='range']::-moz-slider-thumb {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    width: 70px;
    height: 70px;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0px;
    background-color: rgb(153, 153, 153);
}
.range output {
    display: table-cell;
    padding: 3px 5px 2px;
    min-width: 70px;
    color: rgb(255, 255, 255);
    background-color: rgb(153, 153, 153);
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    transition: all 0.5s ease;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.range input[type='range'] {
    outline: none;
}
.range.range-primary input[type='range']::-webkit-slider-thumb {
    background-color: rgb(236, 103, 27);
}
.range.range-primary input[type='range']::-moz-slider-thumb {
    background-color: rgb(236, 103, 27);
}
.range.range-primary output {
    background-color: rgb(236, 103, 27);
}
.range.range-primary input[type='range'] {
    outline-color: rgb(236, 103, 27);
}
.range.range-success input[type='range']::-webkit-slider-thumb {
    background-color: rgb(92, 184, 92);
}
.range.range-success input[type='range']::-moz-slider-thumb {
    background-color: rgb(92, 184, 92);
}
.range.range-success output {
    background-color: rgb(92, 184, 92);
}
.range.range-success input[type='range'] {
    outline-color: rgb(92, 184, 92);
}
.range.range-info input[type='range']::-webkit-slider-thumb {
    background-color: rgb(91, 192, 222);
}
.range.range-info input[type='range']::-moz-slider-thumb {
    background-color: rgb(91, 192, 222);
}
.range.range-info output {
    background-color: rgb(91, 192, 222);
}
.range.range-info input[type='range'] {
    outline-color: rgb(91, 192, 222);
}
.range.range-warning input[type='range']::-webkit-slider-thumb {
    background-color: rgb(240, 173, 78);
}
.range.range-warning input[type='range']::-moz-slider-thumb {
    background-color: rgb(240, 173, 78);
}
.range.range-warning output {
    background-color: rgb(240, 173, 78);
}
.range.range-warning input[type='range'] {
    outline-color: rgb(240, 173, 78);
}
.range.range-danger input[type='range']::-webkit-slider-thumb {
    background-color: rgb(217, 83, 79);
}
.range.range-danger input[type='range']::-moz-slider-thumb {
    background-color: rgb(217, 83, 79);
}
.range.range-danger output {
    background-color: rgb(217, 83, 79);
}
.range.range-danger input[type='range'] {
    outline-color: rgb(217, 83, 79);
}
/**
 * End range slider
 */

/**
 * Canvas2D style (menu)
 */

#planCanvas:active {
    cursor: default;
}
.sepparatorMenuBBPlan {
    width: 1px;
    height: 40px;
    border-top: 10px solid #393a3a;
    border-bottom: 10px solid #393a3a;
    background-color: grey;
    float: left;
    position: relative;
}
.menuBBPlan {
    text-align: left;
    display: inline;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 58px;
    left: 42px;
}
.buttonBBPlan {
    width: 70px;
    height: 60px;
    background-color: #393a3a;
    background-position: center;
    background-repeat: no-repeat;
    font: bold 12px/18px sans-serif;
    display: inline-block;
    margin-right: -4px;
    position: relative;
    float: left;
    cursor: pointer;
    transition: all 0.2s;
}
.horizontalSelectedColorBar {
    border-top-width: 4px;
    border-top-style: solid;
    width: 70px;
    position: relative;
    float: left;
    cursor: pointer;
}
.buttonBBPlan:hover {
    background: #949494;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;
}
.BBPlanToolInUse {
    background: #949494;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;
}
.subMenuBBPlan {
    padding: 0;
    position: absolute;
    top: 60px;
    left: 0;
    width: 210px;
    display: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transiton: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -transition: opacity 0.2s;
    margin: 0px;
}
.subButtonBBPlan {
    display: block;
    color: #fff;
}
.subButtonBBPlan:hover {
    background: #949494;
    background-position: center;
    background-repeat: no-repeat;
}
.BBPlanSubToolInUse {
    background: #949494;
    background-position: center;
    background-repeat: no-repeat;
}
.buttonBBPlan:hover ul {
    display: block;
    opacity: 1;
    visibility: visible;
}
.buttonBBPlanThickness {
    width: 134px;
    text-align: center;
    line-height: 60px;
}
.buttonBBPlanColor {
    width: 40px;
    height: 36px;
    border-top: 10px solid #393a3a;
    border-left: 10px solid #393a3a;
}
.buttonBBPlanColor:hover {
    border-top: 10px solid #666;
    border-left: 10px solid #666;
}
.buttonBBPlanColorSelected {
    border-top: 10px solid #666;
    border-left: 10px solid #666;
}
.buttonBBPlanColor:nth-child(4),
.buttonBBPlanColor:last-child {
    border-right: 10px solid #393a3a;
}
.buttonBBPlanColor:nth-child(5),
.buttonBBPlanColor:nth-child(6),
.buttonBBPlanColor:nth-child(7),
.buttonBBPlanColor:nth-child(8) {
    border-bottom: 10px solid #393a3a;
}
.subMenuBBPlanColor {
    width: 200px;
}
/**
 * Canvas2D
 */
/**
 * Canvas3D style
 */
/**
 * Fin canvas 3D
 */
#content {
    height: 100%;
    top: 0;
    position: absolute;
    width: 100%;
}

#content .view-content-wrapper {
    position: relative;
    top: 57px;
    height: calc(100% - 144px);
}
#content .view-content-wrapper.topbar-breadcrumb {
    height: calc(100% - 56px);
}

#content .view-content-wrapper.topbar-forms-breadcrumb {
    height: calc(100% - 106px);
    margin-top: 48px;
}
#content .view-content-wrapper.topbar {
    height: calc(100% - 57px);
}
#content .view-content-wrapper.topbar-toolbar {
    height: calc(100% - 105px);
}

.md-small-avatar {
    width: 20px !important;
    height: 20px !important;
}
.tags-modal {
    border-radius: 5px;
}
.plans-upload-modal {
    border-radius: 5px;
}
.dragging {
    border: 5px dotted #333 !important;
    border-radius: 20px;
    opacity: 0.1;
    color: white;
    /*background: url('../vendor/img/download_icon.png') right top no-repeat;*/
}
/* Really important, this is what's make sidebar/infinit scrolling working in template with mobile size device*/

html,
body,
.container-fluid,
#wrapper {
    height: 100%;
}
.navbar.main .topnav > li.mega-menu .mega-sub-inner ul li {
    width: 400px;
    margin-bottom: 5px;
}
.maps-modal,
.zones-modal {
    margin: 0;
    width: 90%;
    height: 75%;
    left: 5%;
    border-radius: 5px;
}
.maps-modal .ng-scope,
.zones-modal .ng-scope {
    height: 95%;
}
.maps-modal .modal-body,
.zones-modal .modal-body {
    height: 97%;
    max-height: 4000px !important;
}
#intercom-container .intercom-launcher-frame {
    left: 20px !important;
    z-index: 10;
}
@media screen and (min-width: 451px) {
    #intercom-container .intercom-messenger-frame {
        left: 20px !important;
    }
}
#intercom-container .intercom-launcher-discovery-frame {
    left: 20px !important;
}
#intercom-container .intercom-borderless-frame {
    left: 20px !important;
}
#intercom-container .intercom-note {
    left: 20px !important;
}
#intercom-container .intercom-notifications-frame {
    left: 20px !important;
}
#intercom-container .intercom-launcher-badge-frame {
    left: 24px !important;
}
#content.ng-enter {
    animation: fadeIn 0.2s both ease;
}
.menu-dropdown {
    position: relative;
    display: none;
    vertical-align: top;
    color: white;
    text-transform: uppercase;
    line-height: 24px;
    margin: 0 4px;
    padding: 12px 16px;
}
.contactForm {
    margin: 0;
}
.contacts-modal {
    border-radius: 4px;
}
.contacts-modal .ng-scope {
    height: 95%;
}
.contacts-modal .modal-body {
    height: 97%;
    max-height: 4000px !important;
    overflow-y: auto !important;
}
.md-select-value .md-select-icon:after {
    color: #333;
}
md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
    color: rgba(0, 0, 0, 0.38);
}
@media (max-width: 979px) {
    .menu-dropdown {
        display: inline-block !important;
    }
}
.menu-dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--secondary-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}
.menu-dropdown-content a {
    color: whitesmoke;
}
.menu-dropdown:hover .menu-dropdown-content {
    display: block;
}
.menu-dropdown:focus .menu-dropdown-content {
    display: block;
}
/* Loading spinner */

.global-preloader {
    background: var(--secondary-color);
    background: linear-gradient(135deg, var(--secondary-color) 0%, var(--primary-color) 100%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: +2000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
}
.global-preloader > .progress-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
}
.global-preloader .progress-circle {
    stroke: white;
    width: 100%;
}
.progress-circle {
    stroke: black;
    width: 100%;
}
.spinner {
    top: 50%;
    left: 50%;
    z-index: 1;
    height: 30px;
    width: 30px;
    margin-left: -17.5px;
    margin-top: -60px;
    position: relative;
}
.widget-thumbnail.building-plan {
    opacity: 0.4;
}
.widget-thumbnail.failed-plan {
    opacity: 0.4;
}
.spinner.select {
    top: 100%;
    left: 100%;
    height: 25px;
    width: 25px;
    position: absolute;
    margin-left: -39px;
    margin-top: -39px;
}
.spinner span {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: block;
    position: absolute;
    opacity: 0.9;
}
.details-spinner {
    width: 50px;
    height: 50px;
    z-index: 99999999;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}
body {
    font-family: 'Roboto', sans-serif;
}
.spinner {
    animation: rotation 1.4s linear infinite;
}
.spinnerContainer {
    position: fixed;
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0, 0.3);
    bottom: 0;
    right: 0%;
    width: 100%;
    height: 50px;
    z-index: 1000;
}

.spinnerContainer.warning {
    background: rgb(255, 140, 0);
    background: rgb(255, 140, 0, 0.8);
}

.spinnerContainer.loader-fullscreen {
    height: calc(100% - 144px);
}

.spinnerBulkActionContainer {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0, 0.5);
    bottom: 0;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: 99999999;
}
.spinnerBulkActionContainer .spinner {
    margin-bottom: 10px;
}
.dashboard-table {
    padding: 10px;
    margin: 10px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
    position: relative;
    overflow-y: auto;
}
.dashboard-table-header {
    margin-left: 15px;
    margin-right: 15px;
}
.dashboard-table-header .clickable {
    text-decoration: underline;
    cursor: pointer;
}
.dashboard-table > h4 {
    color: #333;
}
.dashboard-table-line {
    max-height: 50px;
}
.dashboard-table-line:hover {
    background-color: #ddd;
}
.md-2-line.dashboard-table-line {
    min-height: 50px;
    max-height: 50px;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    display: none;
}
.leaflet-marker-icon {
    z-index: 1000 !important;
}
.leaflet-label {
    z-index: 1000 !important;
}
.leaflet-label {
    color: #333;
    background: mintcream;
    border: 1px #333;
    border-radius: 4px;
    display: block;
    font-weight: bold;
    padding: 1px 6px;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    z-index: 6;
}
.leaflet-label.leaflet-clickable {
    cursor: pointer;
    pointer-events: auto;
}
.leaflet-label-right:before,
.leaflet-label-left:after {
    content: '';
}
.uploadBarContainer {
    position: fixed;
    border: 1px;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.3);
    width: 500px;
    margin: 5% auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}
.circle {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: turn 1.4s ease-in-out infinite;
}
@keyframes turn {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
svg.spinner:nth-child(1) {
    stroke: var(--primary-color);
}
svg.fab-spinner:nth-child(1) {
    stroke: whitesmoke;
}
li.tooltips {
    position: relative;
    display: inline;
}
li.tooltips span {
    position: absolute;
    width: 100px;
    color: #ffffff;
    background: #000000;
    height: 30px;
    font-size: 13px;
    line-height: 30px;
    text-align: center;
    visibility: hidden !important;
    border-radius: 4px;
}
li.tooltips span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    width: 0;
    height: 0;
    border-bottom: 8px solid #000000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}
li:hover.tooltips span {
    visibility: visible !important;
    opacity: 0.8;
    top: 30px;
    left: 50%;
    margin-left: -56px;
    z-index: 99999999999;
}
.note-folder {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: #7c7c7c;
    position: relative;
}
@keyframes animateSway {
    0% {
        left: -20px;
    }
    50% {
        left: 20px;
    }
    100% {
        left: -20px;
    }
}
@keyframes animateScale {
    0% {
        transform: translate(50%, 50%) scale(0.5);
        z-index: -1;
    }
    25% {
        transform: translate(50%, 50%) scale(1);
        z-index: +1;
    }
    50% {
        transform: translate(50%, 50%) scale(0.5);
        z-index: -1;
    }
    75% {
        transform: translate(50%, 50%) scale(0.25);
        z-index: -2;
    }
    100% {
        transform: translate(50%, 50%) scale(0.5);
        z-index: -1;
    }
}
/* End loading spinner*/

/* Thumbnails */

.thumbnail {
    transition: none;
    border: solid;
    border-width: 1px;
    border-color: #cccccc;
    user-select: none; /* CSS3 (little to no support) */
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Gecko (Firefox) */
    -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}
#notes-table td {
    user-select: none; /* CSS3 (little to no support) */
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Gecko (Firefox) */
    -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}
.note-list-table {
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    width: 100%;
    cursor: pointer;
    background: #fff;
    user-select: none; /* CSS3 (little to no support) */
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Gecko (Firefox) */
    -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}
#note-list-content > div {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}
@media (max-width: 480px) {
    .note-list-table {
        padding: 10px 10px 0px 10px;
    }
    #note-list-content .md-subheader.category-header .md-subheader-inner {
        padding: 10px;
    }
}
.note-list-table-container {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 6px 6px 6px 6px !important;
    background: #fff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.caption.unread::before {
    content: '•';
    color: orangered;
    font-size: 32px;
    vertical-align: middle;
}
.note-list-table div.caption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.caption.note-caption {
    height: auto !important;
    width: 100% !important;
    padding: 5px !important;
    position: absolute !important;
    bottom: 0px !important;
    padding: 0 !important;
}
.note-list-table {
    border-bottom: 1px solid #ccc;
}
.note-list-table:only-child {
    border-bottom: none;
}
.note-list-table:last-child {
    border-bottom: none;
}
.note-list-table.unread {
    font-weight: bold;
}
.notes-list-toolbar button {
    margin: 0 0 10px 0;
}
.notes-list-toolbar .i,
.notes-list-toolbar .span {
    vertical-align: middle !important;
}
.notes-list-toolbar .md-classic-button i,
.notes-list-toolbar .md-classic-button span {
    color: black;
    vertical-align: middle !important;
}
.archived {
    opacity: 0.3;
}
.thumbnail .action {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    padding: 2px 0px 0px 0px;
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.75);
}
.entity-list-actions button,
.thumbnail span.action button {
    background: transparent;
    border: none !important;
    line-height: 0px;
    padding: 0;
}
.entity-list-actions button > i,
.thumbnail i {
    color: #7c7c7c;
    cursor: pointer;
}
.entity-list-actions.notes button > i:hover,
.thumbnail span.action.notes button > i:hover {
    color: var(--secondary-color);
}
.entity-list-actions.plans > button > i:hover,
.thumbnail span.action.plans button > i:hover {
    color: var(--secondary-color);
}
.entity-list-actions.tags > button > i:hover,
.thumbnail span.action.tags button > i:hover {
    color: var(--secondary-color);
}
.entity-list-actions.documents > button > i:hover,
.thumbnail span.action.documents button > i:hover {
    color: var(--secondary-color);
}
.thumbnail .project-actions {
    top: 20px;
}
.thumbnail .action .ng-hide-remove:not(.trigger) {
    animation: 0.5s flipInX ease;
}
.thumbnail .action i {
    cursor: pointer;
    color: #7c7c7c;
}
.thumbnail.highlight {
    box-shadow: -1px 5px 13px 0px rgba(50, 50, 50, 0.5);
    animation: pulse 0.4s;
}
.thumbnail.ng-enter {
    animation: flipInX 0.5s;
}
.thumbnail.ng-leave {
    animation: flipOutX 0.5s;
}
.striked {
    text-decoration: line-through;
}
#user-menu:hover #logoutDropdownMenu {
    display: block;
    max-height: 300px;
    overflow-y: auto;
}
#notifications-menu:hover #logoutDropdownMenu {
    display: block;
}
#notifications-menu #logoutDropdownMenu {
    max-height: 300px;
    overflow-y: scroll;
}
#invitations-menu:hover #logoutDropdownMenu {
    display: block;
    max-height: 300px;
    overflow-y: auto;
}
#logoutDropdownMenu {
    width: 330px;
}
.notifications-dropdown {
    width: 530px !important;
}
@media (max-width: 590px) {
    #user-menu .dropdown-menu {
        width: 330px !important;
    }
    #notifications-menu .dropdown-menu {
        width: 330px !important;
    }
    #invitations-menu .dropdown-menu {
        width: 330px !important;
    }
}
@media (max-width: 400px) {
    #user-menu .dropdown-menu {
        width: 200px !important;
    }
    #notifications-menu {
        display: none !important;
    }
    #invitations-menu {
        display: none !important;
    }
}
#logoutDropdownMenu > li:nth-child(2) > span > a {
    padding: 0px 4px 0px 4px;
    margin: 2px;
    background-color: #4b4b4b;
}
#logoutDropdownMenu li {
    padding: 0px 5px 0px 5px;
}
#logoutDropdownMenu li:hover {
    color: #333;
}
#logoutDropdownMenu li.profile {
    border-bottom: none;
    background-color: #393939;
}
#logoutDropdownMenu li:last-child {
    border-bottom: none;
}
#logoutDropdownMenu li div a div {
    overflow: hidden;
    /*padding-bottom:5px;*/
    padding: 5px 0px 5px 0px;
}
#logoutDropdownMenu .notif_author {
    font-weight: bolder;
    font-size: 15px;
    color: var(--secondary-color);
}
#logoutDropdownMenu .notif_title {
    color: var(--secondary-color);
    font-size: 15px;
    font-weight: bolder;
}
#logoutDropdownMenu .invit-author {
    font-weight: bolder;
    font-size: 15px;
    color: var(--secondary-color);
}
#logoutDropdownMenu .invit-title {
    color: var(--secondary-color);
    font-size: 15px;
    font-weight: bolder;
}
#logoutDropdownMenu .invit-text {
    color: whitesmoke;
    font-size: 15px;
    font-weight: bolder;
}
#logoutDropdownMenu .notif-text {
    color: whitesmoke;
    font-size: 15px;
    font-weight: bolder;
}
#logoutDropdownMenu li div a div span span {
    color: white;
}
@media (min-width: 768px) {
    .thumbnail {
        height: 175px;
        cursor: pointer;
        /*padding: 5px;*/
    }
    .thumbnail img {
        height: 60%;
        /*padding: 10px;*/
        width: auto;
        /*display: block;
        width: 100%;
        height:auto;
        max-height: 10%;*/
    }
    .thumbnail .caption {
        height: 30%;
        width: auto;
        text-align: center;
        overflow: hidden;
    }
}
.thumbnail .caption {
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.75);
}
.clickable {
    cursor: pointer;
}
.img-circle {
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
/*Handle project admin page responsive*/

/* END : Handle project admin page responsive*/

/*Handle note view modal*/

.modal.fade.in {
    top: 1% !important;
}
md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-width: 0 0 1px !important;
    border-style: solid !important;
}
md-select:not(.md-input-invalid).md-input-focused .md-input {
    border-width: 0 0 1px !important;
    border-style: solid !important;
}
.custom-date-chooser {
    width: 680px;
}
.custom-zone-chooser {
    width: 420px;
}
input[type='text'],
input[type='password'],
select,
textarea {
    color: #333;
}
.modal .modal-body {
    overflow: visible;
}
.tab-content {
    height: 100%;
}
/*#noteEditionModal .tab-content */

/*.tabContainingModifiedData span:after {
    content: "*";
}*/

/* END : Handle note view modal*/

/* Handle directFileInput */

.directFileInput {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    opacity: 0.2;
}
/* END : Handle directFileInput*/

/**
 * Header style
 */

#headerProjectList {
    max-height: 300px;
    overflow: auto;
}
/* Project admin page */

table td.text-center {
    text-align: center;
}
/* Removing the red border on the input field*/

input:required {
    border-color: #cccccc;
}
.icon-large:before {
    cursor: pointer;
}
/* End of Project admin page */

/*
 * This is for managing thumbnail resizing in all thumbnail list (projects, plans, docs, ...)
 */

@media (min-width: 1200px) {
    .container-fluid .row-fluid [class*='stackedSpan']:nth-child(6n + 1) {
        margin-left: 0;
        width: 16.1523%;
    }
}
@media (min-width: 1810px) {
    .container-fluid .row-fluid [class*='stackedSpan']:nth-child(6n + 1) {
        margin-left: 0;
        width: 12.1523%;
    }
}
@media (min-width: 2200px) {
    .container-fluid .row-fluid [class*='stackedSpan']:nth-child(6n + 1) {
        margin-left: 0;
        width: 9.7523%;
    }
}
@media (min-width: 768px) and (max-width: 893px) {
    .row-fluid .span2 {
        width: 32.45%;
    }
}
@media (min-width: 678px) and (max-width: 769px) {
    .row-fluid .span2 {
        width: 49%;
        height: 175px;
        float: left;
    }
}
@media (max-width: 678px) {
    .row-fluid .span2 {
        width: 48%;
        height: 175px;
        float: left;
    }
}
@media (max-width: 300px) {
    .row-fluid .span2 {
        width: 47%;
        height: 135px;
        float: left;
    }
}
@media (min-width: 893px) and (max-width: 979px) {
    .row-fluid .span2 {
        width: 24.3%;
    }
    .container-fluid .row-fluid [class*='stackedSpan']:nth-child(4n + 1) {
        margin-left: 0;
    }
    .container-fluid .row-fluid [class*='stackedSpan']:first-child {
        margin-left: 0;
    }
    .span2 {
        width: 100px;
    }
}
/*@media (min-width: 892px) {
 .row-fluid .span2 {
   width: 
 }}*/

@media (min-width: 979px) and (max-width: 1200px) {
    .row-fluid .span2 {
        width: 19.3%;
        *width: 19.3%;
    }
    .container-fluid .row-fluid [class*='stackedSpan']:nth-child(5n + 1) {
        margin-left: 0;
    }
    .container-fluid .row-fluid [class*='stackedSpan']:first-child {
        margin-left: 0;
    }
    .span2 {
        width: 60px;
    }
}
/* Modal css changed to have modal on top of other modal*/

.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.in:nth-of-type(even) {
    z-index: 1041 !important;
}
.dropdown-menu {
    z-index: 1040;
}
.relative {
    position: relative;
}
.centered-hv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.over-all {
    width: 100%;
    height: 100%;
}
.fa {
    font-family: FontAwesome;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-style: normal;
}
.fa-2x {
    font-size: 30px;
}
.fa-half {
    font-size: 20px;
}
.fa-small {
    font-size: 15px;
}
.fa-folder::before {
    content: '\f07b';
}
.fa-back::before {
    content: '\f04a';
}
.fa-file-o::before {
    content: '\f016';
}
.fa-circle-o::before {
    content: '\f10c';
}
.fa-check-circle-o {
    color: orangered;
}
.fa-check-circle-o::before {
    content: '\f05d';
}
.list-only {
    display: none;
}
.list-view .list-only {
    display: block;
}
.list-view .thumbnail {
    width: 100%;
    height: auto;
    padding: 5px;
    margin: 0;
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    position: relative;
    z-index: 0;
}
.list-view .thumbnail img {
    height: 50px;
    margin: 0;
    float: left;
}
.list-view .thumbnail .caption {
    width: 50%;
    float: left;
    text-align: left;
    background: #ccc;
    opacity: 0.6;
}
.list-view .thumbnail .action {
    float: right;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
}
.action.ng-hide-add-active {
    transition: 0.5s linear all;
    opacity: 0;
}
.list-view .thumbnail .action li {
    display: inline-block;
    padding: 5px 10px;
    background-color: #eee;
    cursor: pointer;
}
.list-view .thumbnail .action li * {
    cursor: pointer !important;
}
.list-view .thumbnail .action li i::before {
    cursor: pointer !important;
}
.list-view .thumbnail .action li:hover {
    background-color: #ddd;
}
.pin {
    height: 60px;
    width: 90px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0px, -30px);
}
.drawer-pin {
    height: 60px;
    width: 90px;
    position: relative;
    z-index: 10;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0px, 40px);
}
.drawer-pin img,
.pin img {
    width: 100%;
}
.drawer-pin span,
.pin span {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1em;
    font-size: 1.4em;
    font-weight: bold;
    color: #000;
    width: 65px;
    text-align: center;
}
.drawer-pin span.closed,
.pin span.closed {
    background-color: transparent;
}
.div-table {
    display: table;
}
.div-table .div-tr {
    display: table-row;
}
.div-table .div-th,
.div-table .div-td {
    display: table-cell;
}
.div-table .div-th {
    font-weight: bold;
}
.div-table.table-bordered .div-td,
.div-table.table-bordered .div-th {
    border-left: 1px solid #ddd;
    border-color: #efefef;
}
.div-table.table-condensed .div-td,
.div-table.table-condensed .div-th {
    padding: 4px 10px;
}
.div-table.table-striped .div-tr:nth-child(even) .div-td,
.div-table.table-striped .div-tr:nth-child(even) .div-th {
    background-color: #fafafa;
}
.hide {
    display: none !important;
}
.checkbox {
    cursor: pointer;
}
.thumbnail .checkbox {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    padding: 5px;
    text-align: right;
}
.checkbox.checked {
    color: var(--secondary-color);
}
input[type='checkbox'].fa {
    position: relative;
}
input[type='checkbox'].fa::before {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    font-size: 0.8em;
    color: #7c7c7c;
}
input[type='checkbox']:checked.fa::before {
    color: var(--secondary-color);
}
.thumbnail .caption h4 {
    font-size: 13px;
}
.thumbnail .default-img {
    height: auto !important;
    width: auto !important;
}
.thumbnail .default-img.docs {
    transform: translateY(40%) !important;
}
@media (max-width: 300px) {
    .thumbnail .default-img {
        height: auto !important;
        width: auto !important;
        transform: translateY(25%);
    }
}
.action-bottom-bar-container {
    background: #4a494a;
    font-family: 'Roboto', sans-serif;
}
.bottom-bar {
    position: fixed;
    background: #fff;
    width: 70%;
    line-height: 40px !important;
    left: 0;
    right: 0;
    z-index: 11;
    bottom: 0;
    margin: 10px auto;
    border-radius: 4px;
    padding: 15px 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@media (max-width: 400px) {
    .bottom-bar {
        width: 95%;
        padding: 7px 5px;
    }
}
.icon {
    display: inline-block;
    color: #636163;
    font-size: 20px;
    margin-right: 25px;
    cursor: pointer;
}
.part-left {
    float: left;
}
.part-right {
    float: right;
}
.fade-hide,
.fade-show {
    transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.fade-hide {
    opacity: 0;
}
.fade-hide.fade-hide-active {
    opacity: 0;
}
.fade-show {
    opacity: 1;
}
.fade-show.fade-show-active {
    opacity: 1;
}
.extra-margin-bottom {
    margin-bottom: 100px;
}
.note-list-table > div,
.note-list-table > div > div {
    margin: auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
}
.note-list-table .note-title {
    cursor: pointer;
    color: black;
    font-size: 15px;
}
.note-list-table .note-number {
    font-size: 15px;
    color: var(--secondary-color);
}
#note-list-content {
    overflow-y: scroll;
    height: calc(100% - 102px);
}
#note-list-content.note-list-content-template-banner-visible {
    height: calc(100% - 128px);
}
#note-list-content .md-subheader.category-header {
    z-index: 10 !important;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
    overflow: hidden;
    color: black;
    background-color: #f7f7f7;
}
#note-list-content .md-subheader.category-header.categories-header-hidden {
    opacity: 0.55;
    background-color: #ffffff;
}
#note-list-content .md-subheader.category-header .note-count {
    border-radius: 4px;
    padding: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0.54;
    text-align: center;
}
#note-list-content .md-subheader.category-header .group-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#note-list-content .md-subheader.category-header .note-count {
    padding: 5px;
    margin: 5px;
    opacity: 0.54;
}
@media (max-width: 599px) {
    #note-list-content .md-subheader.category-header .note-count {
        text-align: left;
    }
}
.md-subheader .user-avatar {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}
.md-subheader .zone-avatar {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
}
.selected-sort-menu {
    color: white;
    background: #333;
}
.pagination {
    display: inline-block;
}
.pagination a {
    color: #333;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
}
.pagination a.active {
    border-radius: 5px;
}
.pagination a.active {
    background-color: orangered;
    color: white;
}
.pagination a:hover:not(.active) {
    background-color: #ddd;
}
md-toast {
    z-index: 10000000000;
    max-width: 550px;
    padding: 2px;
}
.md-datepicker-calendar-pane.md-pane-open {
    z-index: 2002 !important;
}
.bz-avatar {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
}
.archive-button-list md-list-item .md-no-style {
    padding: 0 !important;
}
.project-details-drawer-image {
    position: relative;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    padding: 0;
    margin: 0;
}
.project-cover-details-drawer-image {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 80px;
    right: 30px;
    z-index: 10;
    box-shadow: 0px 4px 10px 0px rgba(50, 50, 50, 0.35);
    -o-object-fit: cover;
       object-fit: cover;
}
.project-client-details-drawer-image {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    margin: 0 0 30px 0;
    height: 150px;
    background-size: cover;
    background-position: center;
    padding: 0;
}
.md-title {
    color: black;
    opacity: 0.87;
}
.md-subhead {
    color: black;
    opacity: 0.87;
}
.md-body-1 {
    color: black;
    opacity: 0.54;
}
.note-report-thumbnail {
    width: 100px;
    height: 75px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
}
.custom-calendar label {
    width: calc(100% - 40px) !important;
}
.report-radio {
    box-shadow: none;
    transition: box-shadow 0.3s;
    padding: 5px;
    margin-right: 10px;
}
.report-radio.report-radio-full {
    width: 100%;
}
.report-radio-hover:hover {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.report-radio.selected {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.report-builder-image {
    border: 2px lightgray solid;
}
md-radio-button[disabled] {
    opacity: 0.3;
}
md-radio-button {
    margin-bottom: 5px;
}
md-radio-button div.md-off {
    margin-left: 10px;
}
md-radio-button .md-label {
    margin-left: 40px;
}
md-radio-button div.md-on {
    margin-left: 10px;
}
md-radio-button.md-align-top-left > div.md-container {
    top: 17px !important;
}
md-input-container div.md-resize-handle {
    display: none;
}
md-checkbox.md-checked .md-icon {
}
md-grid-list md-grid-tile figure {
    -ms-flex-align: normal !important;
        align-items: normal !important;
    display: block !important;
    right: auto !important;
}
.md-dialog-content .md-datepicker-input-container {
    width: 90%;
}
md-datepicker {
    margin-right: 0 !important;
    padding: 0 !important;
}
.md-icon-button + .md-datepicker-input-container {
    margin-left: 0;
}
#project-settings-drawer > div > md-content > div.flex-100 > md-list > md-list-item > div {
    padding: 0;
}
#project-settings-drawer > div > md-content > div.flex-100 > md-list > div > div > div > md-list-item {
    padding: 0;
}
.report-preview {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: fixed;
    bottom: 0;
    width: 100%;
}
.columns-selection md-checkbox .md-icon {
    margin-left: 6px;
}

.columns-selection md-checkbox .md-label span {
    margin-left: 12px;
}
.report-preview.close {
    top: 100%;
    height: 0;
}
.show-preview-button {
    position: absolute;
    top: 0;
    right: 0;
}
.report-builder md-input-container .md-errors-spacer {
    display: none;
}
.report-preview table {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px dashed #333;
}
tr.border_bottom th {
    border-bottom: 1pt solid black;
}
md-select-menu[multiple] md-option.md-select-option-hide-cb .md-container {
    display: none;
}
.wrap-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.md-body-2 {
    opacity: 0.54;
}
.first-login-card {
    background: whitesmoke;
    border-radius: 4px;
    margin-top: 15px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
}

.first-login-card .bulldozair-logo {
    display: block;
    margin: 0 auto;
}
.first-login-card div {
    color: black;
    opacity: 0.72;
}

.first-login-card div.md-caption {
    color: red;
    opacity: 1;
}
.first-login-card md-input-container {
    margin-bottom: 0;
    padding: 0;
}

.first-login-card md-input-container label {
    margin-left: -3px !important;
}

.first-login-card md-input-container input.disabled-field {
    border-width: 0 0 1px !important;
    border-style: dashed !important;
    border-color: #ccc !important;
}

.first-login-card button {
    margin: 0;
    margin-top: 15px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
}

.first-login-card md-input-container .md-errors-spacer {
    display: none;
}

.selected-report-setting {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.report-setting.report-setting-full {
    width: 100%;
    position: relative;
}
.report-setting-delete-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 !important;
}
.report-setting-delete-button i {
    color: black;
    opacity: 0.54;
}
a.custom-report-link {
    color: orangered;
}
a.custom-report-link:hover {
    color: orangered;
    text-decoration: underline;
}
md-dialog.small-dialog {
    max-width: 50%;
}
@media screen and (max-width: 450px) {
    md-dialog.fullscreen-dialog {
        max-width: 100%;
        width: 100%;
        border-radius: 0;
    }
}
@media screen and (max-width: 800px) {
    md-dialog.fullscreen-dialog {
        max-width: 80%;
        width: 80%;
    }
}
@media screen and (max-width: 1280px) {
    md-dialog.fullscreen-dialog {
        max-width: 75%;
        width: 75%;
    }
}
md-dialog.fullscreen-dialog {
    max-width: 75%;
    width: 75%;
}
md-dialog.fullscreen-dialog md-dialog-content {
    overflow-x: hidden;
}

.emphasis-text {
    color: black;
    font-weight: bold;
}
.google-map-label {
    color: black;
    background-color: white;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    padding: 2px;
    transform:  translateY(-150%);
}

.material-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
}
.material-table.no-shadow {
    box-shadow: none;
}
.material-table > thead > tr,
.material-table > tbody > tr,
.material-table > tfoot > tr {
    transition: all 0.1s ease;
}
.material-table > thead > tr > th,
.material-table > tbody > tr > th,
.material-table > tfoot > tr > th,
.material-table > thead > tr > td,
.material-table > tbody > tr > td,
.material-table > tfoot > tr > td {
    text-align: left;
    padding: 0.8rem;
    vertical-align: top;
    border-top: 0;
    transition: all 0.1s ease;
}
.material-table > thead > tr > th {
    font-weight: 400;
    color: #757575;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.material-table > caption + thead > tr:first-child > th,
.material-table > colgroup + thead > tr:first-child > th,
.material-table > thead:first-child > tr:first-child > th,
.material-table > caption + thead > tr:first-child > td,
.material-table > colgroup + thead > tr:first-child > td,
.material-table > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.material-table > tbody + tbody {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.material-table .material-table {
    background-color: #fff;
}
.material-table .no-border {
    border: 0;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
    padding: 0.8rem;
}
.table-bordered {
    border: 0;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f5f5f5;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 768px) {
    .table-responsive-vertical > .material-table {
        margin-bottom: 0;
        background-color: transparent;
    }
    .table-responsive-vertical > .material-table > thead,
    .table-responsive-vertical > .material-table > tfoot {
        display: none;
    }
    .table-responsive-vertical > .material-table > tbody {
        display: block;
    }
    .table-responsive-vertical > .material-table > tbody > tr {
        display: block;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        margin-bottom: 0.8rem;
    }
    .table-responsive-vertical > .material-table > tbody > tr > td {
        background-color: #fff;
        display: block;
        vertical-align: middle;
        text-align: right;
    }
    .table-responsive-vertical > .material-table > tbody > tr > td[data-title]:before {
        content: attr(data-title);
        float: left;
        font-size: inherit;
        font-weight: 400;
        color: #757575;
    }
    .table-responsive-vertical.shadow-z-1 {
        box-shadow: none;
    }
    .table-responsive-vertical.shadow-z-1 > .material-table > tbody > tr {
        border: none;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    }
    .table-responsive-vertical > .table-bordered {
        border: 0;
    }
    .table-responsive-vertical > .table-bordered > tbody > tr > td {
        border: 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .table-responsive-vertical > .table-bordered > tbody > tr > td:last-child {
        border-bottom: 0;
    }
    .table-responsive-vertical > .table-striped > tbody > tr > td,
    .table-responsive-vertical > .table-striped > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical > .table-striped > tbody > tr > td:nth-child(odd) {
        background-color: #f5f5f5;
    }
    .table-responsive-vertical > .table-hover > tbody > tr:hover > td,
    .table-responsive-vertical > .table-hover > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical > .table-hover > tbody > tr > td:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
    background-color: #fde0dc;
}
.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
    background-color: #f9bdbb;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td:nth-child(odd) {
        background-color: #fde0dc;
    }
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr > td:hover {
        background-color: #f9bdbb;
    }
}
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > th {
    background-color: #fce4ec;
}
.table-hover.table-mc-pink > tbody > tr:hover > td,
.table-hover.table-mc-pink > tbody > tr:hover > th {
    background-color: #f8bbd0;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td:nth-child(odd) {
        background-color: #fce4ec;
    }
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr > td:hover {
        background-color: #f8bbd0;
    }
}
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > th {
    background-color: #f3e5f5;
}
.table-hover.table-mc-purple > tbody > tr:hover > td,
.table-hover.table-mc-purple > tbody > tr:hover > th {
    background-color: #e1bee7;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td:nth-child(odd) {
        background-color: #f3e5f5;
    }
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr > td:hover {
        background-color: #e1bee7;
    }
}
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > th {
    background-color: #ede7f6;
}
.table-hover.table-mc-deep-purple > tbody > tr:hover > td,
.table-hover.table-mc-deep-purple > tbody > tr:hover > th {
    background-color: #d1c4e9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td:nth-child(odd) {
        background-color: #ede7f6;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr > td:hover {
        background-color: #d1c4e9;
    }
}
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > th {
    background-color: #e8eaf6;
}
.table-hover.table-mc-indigo > tbody > tr:hover > td,
.table-hover.table-mc-indigo > tbody > tr:hover > th {
    background-color: #c5cae9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td:nth-child(odd) {
        background-color: #e8eaf6;
    }
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr > td:hover {
        background-color: #c5cae9;
    }
}
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > th {
    background-color: #e7e9fd;
}
.table-hover.table-mc-blue > tbody > tr:hover > td,
.table-hover.table-mc-blue > tbody > tr:hover > th {
    background-color: #d0d9ff;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td:nth-child(odd) {
        background-color: #e7e9fd;
    }
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr > td:hover {
        background-color: #d0d9ff;
    }
}
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > th {
    background-color: #e1f5fe;
}
.table-hover.table-mc-light-blue > tbody > tr:hover > td,
.table-hover.table-mc-light-blue > tbody > tr:hover > th {
    background-color: #eeeeeeee;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td:nth-child(odd) {
        background-color: #e1f5fe;
    }
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr > td:hover {
        background-color: #eeeeeeee;
    }
}
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > th {
    background-color: #e0f7fa;
}
.table-hover.table-mc-cyan > tbody > tr:hover > td,
.table-hover.table-mc-cyan > tbody > tr:hover > th {
    background-color: #b2ebf2;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td:nth-child(odd) {
        background-color: #e0f7fa;
    }
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr > td:hover {
        background-color: #b2ebf2;
    }
}
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > th {
    background-color: #e0f2f1;
}
.table-hover.table-mc-teal > tbody > tr:hover > td,
.table-hover.table-mc-teal > tbody > tr:hover > th {
    background-color: #b2dfdb;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td:nth-child(odd) {
        background-color: #e0f2f1;
    }
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr > td:hover {
        background-color: #b2dfdb;
    }
}
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > th {
    background-color: #d0f8ce;
}
.table-hover.table-mc-green > tbody > tr:hover > td,
.table-hover.table-mc-green > tbody > tr:hover > th {
    background-color: #a3e9a4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td:nth-child(odd) {
        background-color: #d0f8ce;
    }
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr > td:hover {
        background-color: #a3e9a4;
    }
}
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > th {
    background-color: #f1f8e9;
}
.table-hover.table-mc-light-green > tbody > tr:hover > td,
.table-hover.table-mc-light-green > tbody > tr:hover > th {
    background-color: #dcedc8;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td:nth-child(odd) {
        background-color: #f1f8e9;
    }
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr > td:hover {
        background-color: #dcedc8;
    }
}
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > th {
    background-color: #f9fbe7;
}
.table-hover.table-mc-lime > tbody > tr:hover > td,
.table-hover.table-mc-lime > tbody > tr:hover > th {
    background-color: #f0f4c3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td:nth-child(odd) {
        background-color: #f9fbe7;
    }
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr > td:hover {
        background-color: #f0f4c3;
    }
}
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > th {
    background-color: #fffde7;
}
.table-hover.table-mc-yellow > tbody > tr:hover > td,
.table-hover.table-mc-yellow > tbody > tr:hover > th {
    background-color: #fff9c4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td:nth-child(odd) {
        background-color: #fffde7;
    }
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr > td:hover {
        background-color: #fff9c4;
    }
}
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > th {
    background-color: #fff8e1;
}
.table-hover.table-mc-amber > tbody > tr:hover > td,
.table-hover.table-mc-amber > tbody > tr:hover > th {
    background-color: #ffecb3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td:nth-child(odd) {
        background-color: #fff8e1;
    }
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr > td:hover {
        background-color: #ffecb3;
    }
}
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fff3e0;
}
.table-hover.table-mc-orange > tbody > tr:hover > td,
.table-hover.table-mc-orange > tbody > tr:hover > th {
    background-color: #ffe0b2;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td:nth-child(odd) {
        background-color: #fff3e0;
    }
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr > td:hover {
        background-color: #ffe0b2;
    }
}
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fbe9e7;
}
.table-hover.table-mc-deep-orange > tbody > tr:hover > td,
.table-hover.table-mc-deep-orange > tbody > tr:hover > th {
    background-color: #ffccbc;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) {
        background-color: #fff;
    }
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td:nth-child(odd) {
        background-color: #fbe9e7;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover {
        background-color: #fff;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr > td:hover {
        background-color: #ffccbc;
    }
}

/* New Note Drawer */
/* Overrides of menu z-indices which will apply across the app */
md-backdrop.md-menu-backdrop {
    z-index: 1300;
}
.md-open-menu-container {
    z-index: 1402;
}
.doc-drawer-wrapper,
.note-drawer-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100%;
}
.doc-drawer-wrapper md-backdrop,
.note-drawer-wrapper md-backdrop {
    z-index: 1199;
}

.note-drawer-modal,
.note-drawer-modal.modal:nth-of-type(even) {
    z-index: 1401 !important;
}

#content md-backdrop.md-dialog-backdrop {
    z-index: 1400;
}
div.md-dialog-container.ng-scope {
    z-index: 1401 !important;
}
.note-drawer-draw-dialog {
    width: 100%;
}
#content .innerLR .alert {
    margin: 0 15px;
}

.note-drawer-draw-dialog h2 {
    font-family: 'Work Sans', sans-serif;
    font-size: 16.91px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.15px;
    text-align: left;
    line-height: 24px;
}

.note-drawer-wrapper md-progress-linear.note-drawer-loading-file {
    position: absolute;
}
.note-drawer-wrapper md-progress-linear .md-container {
    background-color: #b0bec5;
}
.note-drawer-wrapper md-progress-linear .md-bar {
}

.note-drawer-menu md-menu-item .md-button .material-icons {
    vertical-align: middle;
}
.note-drawer-menu md-menu-item {
    width: 100%;
}
md-menu-item button img {
    width: 18px;
    height: 18px;
}
md-menu-item button.selected-sort-menu img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(118.6deg) brightness(100%) contrast(103.6%);
}

@keyframes fade-out-content {
    from {
        opacity: 1;
        visibility: visible;
    }

    33% {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes fade-back-in-content {
    from {
        opacity: 0;
        visibility: hidden;
    }

    66% {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fully-expand-note-drawer {
    from {
        transform: translate(-650px, 0);
    }

    33% {
        transform: translate(-650px, 0);
    }

    66% {
        transform: translate(-100%, 0);
    }

    to {
        transform: translate(-100%, 0);
    }
}

@keyframes fully-expand-project-settings-drawer {
    from {
        transform: translate(-400px, 0);
    }

    33% {
        transform: translate(-400px, 0);
    }

    66% {
        transform: translate(-100%, 0);
    }

    to {
        transform: translate(-100%, 0);
    }
}

@keyframes reduce-note-drawer {
    from {
        transform: translate(-100%, 0);
    }

    33% {
        transform: translate(-100%, 0);
    }

    66% {
        transform: translate(-650px, 0);
    }

    to {
        transform: translate(-650px, 0);
    }
}

@keyframes reduce-project-settings-drawer {
    from {
        transform: translate(-100%, 0);
    }

    33% {
        transform: translate(-100%, 0);
    }

    66% {
        transform: translate(-400px, 0);
    }

    to {
        transform: translate(-400px, 0);
    }
}

@keyframes fade-in-expanded-content {
    from {
        opacity: 0;
        visibility: hidden;
    }

    66% {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}
@keyframes fade-back-out-expanded-content {
    from {
        visibility: visible;
        opacity: 1;
    }

    33% {
        visibility: hidden;
        opacity: 0;
    }
    to {
        visibility: hidden;
        opacity: 0;
    }
}
/* short version used when no pause needed for movement of the drawer */
@keyframes short-fade-out-content {
    from {
        opacity: 1;
        visibility: visible;
    }

    50% {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes short-fade-back-in-content {
    from {
        opacity: 0;
        visibility: hidden;
    }

    50% {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}
@keyframes short-fade-in-expanded-content {
    from {
        opacity: 0;
        visibility: hidden;
    }

    50% {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}
@keyframes short-fade-back-out-expanded-content {
    from {
        visibility: visible;
        opacity: 1;
    }

    50% {
        visibility: hidden;
        opacity: 0;
    }
    to {
        visibility: hidden;
        opacity: 0;
    }
}

md-sidenav#doc-drawer,
md-sidenav#drawer-projects,
md-sidenav#note-drawer {
    max-width: 100%;
    width: 100%;
    z-index: 1200;
}
md-sidenav#project-settings-drawer {
    max-width: 100%;
    width: 100%;
    z-index: 1200;
}
@media (min-width: 775px) {
    md-sidenav#note-drawer,
    md-sidenav#drawer-projects {
        max-width: 90%;
        width: 90%;
    }
    md-sidenav#project-settings-drawer {
        max-width: 90%;
        width: 90%;
    }
}
@media (min-width: 750px) {
    md-sidenav#doc-drawer {
        max-width: 100%;
        width: 100%;
    }
}

md-sidenav#note-drawer.md-sidenav-right,
md-sidenav#drawer-projects {
    width: 650px;
    transition: width 0.5s ease-in-out;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out;
}
md-sidenav#doc-drawer.md-sidenav-right {
    width: 750px;
}
md-sidenav#note-drawer.md-sidenav-right.md-closed {
    transform: translate(0, 0);
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion,
md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded {
    animation-direction: normal;
    width: 100%;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion {
    animation: reduce-note-drawer 0.9s both;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded {
    animation: fully-expand-note-drawer 0.9s both;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion #note-drawer-scroll,
md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded #note-drawer-scroll {
    animation-direction: normal;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion #note-drawer-scroll {
    animation: fade-back-in-content 0.9s both;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded #note-drawer-scroll {
    animation: fade-out-content 0.9s both;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion .note-drawer-expanded {
    animation: fade-back-out-expanded-content 0.9s both;
}
md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded .note-drawer-expanded {
    animation: fade-in-expanded-content 0.9s both;
}

md-sidenav#project-settings-drawer.md-sidenav-right {
    width: 400px;
}
md-sidenav#project-settings-drawer.md-sidenav-right.md-closed {
    transform: translate(0, 0);
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-closing-expansion,
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-fully-exapanded {
    animation-direction: normal;
    width: 70%;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-closing-expansion {
    animation: reduce-project-settings-drawer 0.9s both;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-fully-exapanded {
    animation: fully-expand-project-settings-drawer 0.9s both;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-closing-expansion #project-settings-scroll,
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-fully-exapanded #project-settings-scroll {
    animation-direction: normal;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-closing-expansion #project-settings-scroll {
    animation: fade-back-in-content 0.9s both;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-fully-exapanded #project-settings-scroll {
    animation: fade-out-content 0.9s both;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-closing-expansion .note-drawer-expanded {
    animation: fade-back-out-expanded-content 0.9s both;
}
md-sidenav#project-settings-drawer.md-sidenav-right.note-drawer-fully-exapanded .note-drawer-expanded {
    animation: fade-in-expanded-content 0.9s both;
}

#note-drawer-scroll,
#project-settings-scroll {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 650px;
}
#project-settings-scroll {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 400px;
}
@media (max-width: 650px) {
    md-sidenav#note-drawer.md-sidenav-right {
        transform: translate(-100%);
    }

    #note-drawer-scroll {
        width: 100%;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion {
        animation: none;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded {
        animation: none;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion #note-drawer-scroll {
        animation: short-fade-back-in-content 0.6s both;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded #note-drawer-scroll {
        animation: short-fade-out-content 0.6s both;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-closing-expansion .note-drawer-expanded {
        animation: short-fade-back-out-expanded-content 0.6s both;
    }
    md-sidenav#note-drawer.md-sidenav-right.note-drawer-fully-exapanded .note-drawer-expanded {
        animation: short-fade-in-expanded-content 0.6s both;
    }
}
.note-drawer-expanded {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: flex;
        align-items: flex;
}
.note-drawer-expanded .note-drawer-expanded-top {
    height: 48px;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
}

.note-drawer-expanded-botton {
    width: 100%;
    position: fixed;
    display: -ms-flexbox;
    display: flex;
    bottom: 0px;
    z-index: 20000;
    background: white;
}
.note-drawer-toolbar .md-button.note-drawer-open-external {
    margin-left: auto;
    margin-right: 16px;
}
.note-drawer-expanded .note-drawer-expanded-content {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-item-align: center;
        align-self: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    position: relative;
}
.note-drawer-expanded .note-drawer-expanded-content svg {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
    animation: rotation 1.4s linear infinite;
    z-index: 900;
}
.note-drawer-expanded .note-drawer-expanded-content img {
    max-width: 86vw;
    max-height: 90vh;
    -ms-flex-item-align: center;
        align-self: center;
    cursor: pointer;
    z-index: 1000;
}
.note-drawer-expanded .note-drawer-expanded-content div {
    height: 20px;
    width: 20px;
    -ms-flex-item-align: center;
        align-self: center;
    cursor: pointer;
}
.note-drawer-expanded .note-drawer-expanded-content .note-drawer-expanded-map {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 100%;
    height: 100%;
}
.note-drawer-top-section,
.note-drawer-properties,
.note-drawer-properties-loading {
    background: #eceff1;
}

.note-drawer-top-section {
    position: relative;
    z-index: 1201;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.note-drawer-properties,
.note-drawer-properties-loading {
    padding-bottom: 8px;
    padding-top: 8px;
}

.note-drawer-toolbar {
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-toolbar .note-drawer-toolbar-main-section {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-toolbar .md-button {
    margin: 4px;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.note-drawer-toolbar .md-button:last-child {
    margin-right: 8px;
}

.note-drawer-toolbar .note-drawer-close-button {
    /* Margins to align the icon itself with 30px from the left edge and the following content 72px from the left edge.*/
    margin-left: 18px;
    margin-right: 16px;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
@media (max-width: 720px) {
    .note-drawer-toolbar .md-button {
        margin: 4px 2px;
    }
    .note-drawer-toolbar .note-drawer-close-button {
        margin-left: 8px;
    }
}
.note-drawer-toolbar .md-button.note-drawer-open-external {
    height: 40px;
}
.note-drawer-last-updated {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: rgba(35, 47, 52, 0.7);
    letter-spacing: 0.57px;
}
.note-drawer-last-updated-by {
    border-bottom: 1px rgba(35, 47, 52, 0.7) dashed;
}

.note-drawer-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
}

.note-drawer-title .note-drawer-number,
.note-drawer-title h1,
.note-drawer-title-edit-menu textarea {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.8px;
    line-height: 24px;
}

.note-drawer-title .note-drawer-number {
    display: inline-block;
    padding-left: 30px;
    min-width: 34px;
    margin-right: 4px;
    margin-top: 12px;
    color: var(--secondary-color);
}
@media (max-width: 720px) {
    .note-drawer-title .note-drawer-number {
        padding-left: 16px;
    }
}
.note-drawer-title-edit {
    height: 24px;
    margin: 6px 0;
}

.md-button.note-drawer-title-editable {
    padding-left: 4px;
    margin-left: 0;
    margin-right: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    background: rgba(255, 255, 255, 0);
    text-transform: none;
    white-space: normal;
    text-align: left;
}
.md-button.note-drawer-title-editable h1 {
    margin: 6px 0;
}
.md-button.note-drawer-title-editable:hover,
.md-button.note-drawer-title-editable.md-focused {
    background: rgba(255, 255, 255, 1);
}
.note-drawer-title-editable .material-icons {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin-left: 12px;
    color: rgba(97, 97, 97, 1);
    opacity: 0.4;
    /* easing curve matches that of the .md-button animation */
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-button.note-drawer-title-editable:hover .material-icons,
.md-button.note-drawer-title-editable.md-focused .material-icons {
    opacity: 1;
}
.note-drawer-title-edit-menu {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.note-rawer-title-edit-menu .note-drawer-text-field {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    margin-left: 12px;
}
.note-rawer-title-edit-menu .note-drawer-text-field-cancel {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}

.note-drawer-title h1 {
    color: #232f34;
    margin: 0;
    display: inline-block;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}

.note-drawer-error-banner {
    background: #fff;
    padding: 4px 12px;
    margin: 0 30px 8px;
    color: rgba(35, 47, 52, 0.87);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
}
.note-drawer-error-banner span {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}

.note-drawer-property-section {
    background: rgba(255, 255, 255, 0.5);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    min-height: 42px;
    width: 188px;
    margin: 8px 30px;
    color: rgba(0, 0, 0, 0.72);
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 0.47px;
    line-height: 16px;
    position: relative;
    overflow: hidden;
}
.note-drawer-status-priority-row {
    display: -ms-flexbox;
    display: flex;
    margin-left: 30px;
}
.note-drawer-status-priority-row .note-drawer-property-section {
    margin: 8px 16px 8px 0;
}
.note-drawer-property-section-hoverable:hover {
    background: rgba(255, 255, 255, 0.72);
    cursor: pointer;
}
@media (max-width: 720px) {
    .note-drawer-property-section {
        margin: 8px 16px;
    }
    .note-drawer-status-priority-row {
        margin-left: 16px;
    }
}

.note-drawer-property-section > .material-icons {
    color: rgba(0, 0, 0, 0.54);
}

.note-drawer-property-section > span > .material-icons:first-child,
.note-drawer-button-content-wrapper > span > .material-icons:first-child,
.note-drawer-property-section > .material-icons:first-child {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    text-align: center;
    width: 44px;
}
.md-button.note-drawer-property-section.note-drawer-status-button .note-drawer-status-icon {
    width: 32px;
    height: 42px;
    margin-right: 6px;
}

.note-drawer-property-section:last-child {
    margin-bottom: 0;
}

.note-drawer-property-section-full-width {
    width: calc(100% - 54px);
}
@media (max-width: 720px) {
    .note-drawer-property-section-full-width {
        width: calc(100% - 30px);
    }
}

.md-button.note-drawer-property-section {
    text-align: left;
    text-transform: none;
}
.md-button.note-drawer-property-section:hover,
.md-button.note-drawer-property-section.md-focused {
    background: rgba(255, 255, 255, 1);
}

.md-button.note-drawer-property-section.note-drawer-status-button {
    /*border-left: 6px solid;*/
    padding-left: 0;
}
.md-button.note-drawer-property-section.note-drawer-status-button .material-icons.note-drawer-status-icon {
    display: inline-block;
    min-width: 42px;
    line-height: 42px;
}
.md-button.note-drawer-property-section.note-drawer-status-button.in-progress .note-drawer-status-icon,
md-radio-button .note-drawer-edit-status-icon.in-progress {
    background-color: #ef9a9a;
}
.md-button.note-drawer-property-section.note-drawer-status-button.finished .note-drawer-status-icon,
md-radio-button .note-drawer-edit-status-icon.finished {
    background-color: #aed581;
}
.md-button.note-drawer-property-section.note-drawer-status-button.pending .note-drawer-status-icon,
md-radio-button .note-drawer-edit-status-icon.pending {
    background-color: #ffe082;
}
.note-drawer-status-priority-row .note-drawer-property-section {
    margin-top: 0;
    margin-bottom: 0;
    height: 42px;
}

.note-drawer-edit-status-icon {
    display: inline-block;
    width: 224px;
    padding: 5px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    margin: 2px 8px 2px 0;
    color: white;
    border-radius: 2px;
}

.note-drawer-labelled-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}

.note-drawer-labelled-item .note-drawer-label {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    color: rgba(35, 47, 52, 0.7);
    letter-spacing: 0.46px;
}

.note-drawer-labelled-item .note-drawer-item {
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: #232f34;
    letter-spacing: 0.57px;
}
md-menu-content.note-drawer-menu {
    -ms-flex-align: start;
        align-items: flex-start;
}
.navigation-menu-panel  {
    background: var(--secondary-color);
}
.user-menu-panel,
.notification-menu-panel,
.invitation-menu-panel,
.navigation-menu-panel {
    background: var(--secondary-color);
    width: 430px;
}
.project-menu-panel {
    background: var(--secondary-color);
    width: calc(250px + 100vw - 768px);
    max-width: 600px;
}
@media (max-width: 1000px) {
    .project-menu-panel {
        width: calc(100vw - 150px);
    }
}
@media (max-width: 550px) {
    .project-menu-panel {
        width: calc(100vw - 50px);
    }
}

@media (max-width: 440px) {
    .notification-menu-panel,
    .invitation-menu-panel,
    .project-menu-panel {
        width: 100%;
    }
    .notification-menu-parent-panel-class,
    .invitation-menu-parent-panel-class,
    .project-menu-parent-panel-class,
    .navigation-menu-parent-panel-class {
        left: 0 !important;
        right: 0 !important;
        width: 100%;
    }
}
.user-menu-panel .md-user-avatar,
.notification-menu-panel .md-user-avatar,
.invitation-menu-panel .md-user-avatar,
.project-menu-panel .md-user-avatar {
    width: 44px;
    height: 44px;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
    margin-right: 5px;
}

.project-menu-panel md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-width: 0 0 1px !important;
    border-style: solid !important;
    border-color: white !important;
}

@media (max-width: 440px) {
    .notification-menu-parent-panel-class,
    .invitation-menu-parent-panel-class {
        left: 0 !important;
        width: 100%;
    }
}
#top-menu-sheet {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: auto;
    padding: 8px 16px 8px;
    z-index: 1500;
    border-top-width: 0px;
    border-top-style: solid;
    transform: none;
    transition: none;
    transition-property: none;
    transition-property: none;
    transition-property: none;
    transition-property: none;
    background-color: var(--primary-color);
}
#top-menu-sheet .top-menu-list-entry {
    color: white;
    text-transform: uppercase;
    line-height: 43px;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-family: 'Work Sans', sans-serif;
}

#top-menu-sheet i {
    color: white;
}
.user-menu-panel div,
.navigation-menu-panel div {
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    padding: 0 16px;
    position: relative;
    transition: background 0.15s linear;
    width: auto;
    color: white;
}
.navigation-menu-panel div {
    text-transform: uppercase;
    font-size: 16px;
}
.user-menu-panel div.hoverable:hover,
.navigation-menu-panel div.hoverable:hover {
    color: #222;
    background-color: #eee;
    transition: all 0.5s ease-out;
}
.note-drawer-menu .note-drawer-menu-title,
.note-drawer-panel .note-drawer-menu-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: #000000;
    letter-spacing: 0.47px;
    text-align: left;
    line-height: 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    width: calc(100% - 40px);
}
.note-drawer-menu .note-drawer-menu-title,
.note-drawer-text-field .note-drawer-menu-title {
    margin: 0 0 8px 40px;
}
.note-drawer-menu .note-drawer-menu-title > span,
.note-drawer-panel .note-drawer-menu-title > span {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}
.note-drawer-menu .note-drawer-menu-label,
.note-drawer-panel .note-drawer-menu-label {
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 28px;
}
.note-drawer-menu .md-button.note-drawer-menu-save,
.note-drawer-panel .md-button.note-drawer-menu-save {
    margin-left: 40px;
}

.note-drawer-timeline {
    padding: 0 24px;
}

@media (max-width: 720px) {
    .note-drawer-timeline {
        padding: 0 8px;
    }
}

.note-drawer-timeline h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: rgba(35, 47, 52, 0.87);
    letter-spacing: 0.57px;
    margin: 14px 0 8px 14px;
}

@media (max-width: 720px) {
    .note-drawer-timeline h2 {
        margin-left: 8px;
    }
}

.note-drawer-user-avatar,
.note-drawer-group-avatar {
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    height: 32px;
    width: 32px;
}
.note-drawer-group-avatar {
    background: #455a64;
    color: #eceff1;
    line-height: 32px;
    vertical-align: middle;
    text-align: center;
}
.note-drawer-user-avatar-default {
    background-color: rgba(22, 50, 71, 0.2);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url('/vendor/img/user_picture_default.png');
}

.note-drawer-timeline-add-new-observation {
    display: -ms-flexbox;
    display: flex;
    padding-left: 14px;
    -ms-flex-align: center;
        align-items: center;
}
@media (max-width: 720px) {
    .note-drawer-timeline-add-new-observation {
        padding: 0 8px;
    }
}

.note-drawer-timeline-add-new-observation-box {
    border: rgba(0, 0, 0, 0.08) 2px solid;
    border-radius: 4px;
    padding: 8px 12px;
    margin-left: 12px;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: rgba(35, 47, 52, 0.87);
    letter-spacing: 0;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    cursor: pointer;
}
.note-drawer-timeline-add-new-observation-box:hover {
    color: #0288d1;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: rgba(20, 51, 71, 0.06);
}

.note-drawer-timeline-new-block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-right: -8px;
    padding-top: 4px;
}
@media (max-width: 720px) {
    .note-drawer-timeline-new-block {
        padding-right: 8px;
    }
}
.note-drawer-timeline-new-block .md-button {
    color: rgba(0, 0, 0, 0.7);
    margin: 0 2px;
}
.note-drawer-timeline h3 {
    margin: 42px 0 10px 14px;
}
.note-drawer-timeline h3.note-drawer-date-group-first {
    margin-top: 0;
}
@media (max-width: 720px) {
    .note-drawer-timeline h3 {
        margin-left: 8px;
    }
}
.note-drawer-timeline h3:not(.form-field-separator),
.note-drawer-timeline h3:not(.form-field-separator) span.ng-scope {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    color: #232f34;
    letter-spacing: 0.42px;
    text-transform: uppercase;
}
.note-drawer-timeline h3 .note-drawer-timeline-day-of-week {
    font-weight: 500;
    letter-spacing: 0.46px;
}
.note-drawer-block .md-button.note-drawer-more {
    margin: 0;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.note-drawer-block,
.note-drawer-timeline-new-observation {
    background: #f5f5f5;
    margin: 0 0 18px 0;
    position: relative;
}
.note-drawer-block.block-from-duplication {
    background: #00000015;
}
.note-drawer-block-meta {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.note-drawer-block-user {
    margin: 8px 10px 8px 14px;
}
@media (max-width: 720px) {
    .note-drawer-block-user {
        margin-left: 8px;
    }
}
.note-drawer-block-time {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    color: rgba(35, 47, 52, 0.7);
    letter-spacing: 0;
    margin-left: 24px;
}
.note-drawer-block-time.updated {
    font-style: italic;
    opacity: 0.75;
}
.note-drawer-block-user-name {
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: #232f34;
    letter-spacing: 0;
}
.note-drawer-block-content {
    margin: 0 0 0 58px;
    padding-bottom: 12px;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: rgba(35, 47, 52, 0.7);
    letter-spacing: 0;
    width: calc(100% - 58px - 16px);
    word-wrap: break-word;
}
@media (max-width: 720px) {
    .note-drawer-block-content {
        margin-left: 8px;
        width: calc(100% - 8px - 8px);
    }
}
.note-drawer-clickable-image {
    cursor: pointer;
}
.note-drawer-block-description {
    margin-top: 12px;
}
.note-drawer-description-button {
    display: block;
    margin: 4px 0 0 0;
}
.note-drawer-block-add-description {
    display: -ms-flexbox;
    display: flex;
    padding: 12px;
    background: #fff;
    margin-top: 6px;
}
.note-drawer-block-description .note-drawer-text-field-wrapper {
}
.note-drawer-block-description md-input-container label {
    color: rgba(0, 0, 0, 0.6);
}
.note-drawer-block-content img {
    max-width: 330px;
    max-height: 250px;
}
@media (max-width: 362px) {
    .note-drawer-block-content img {
        max-width: 100%;
    }
}
.note-drawer-timeline-new-observation {
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-text-field {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.note-drawer-text-field-wrapper {
    padding-top: 1px;
    border-radius: 4px 4px 0 0;
    margin: 12px 0;
}
.note-drawer-text-field md-input-container {
    padding: 0;
    margin-bottom: 0;
}
.note-drawer-text-field md-input-container label:not(.md-no-float):not(.md-container-ignore),
.note-drawer-text-field md-input-container label {
    padding-left: 12px;
    padding-right: 12px;
}
.note-drawer-text-field md-input-container textarea {
    margin-bottom: 0;
    padding: 0 12px;
}
.note-drawer-text-field md-input-container .md-errors-spacer {
    display: none;
}
md-input-container.no-errors .md-errors-spacer {
    display: none;
}
.project-menu-panel-title-input .md-errors-spacer {
    display: none;
}
.note-drawer-wrapper md-input-container:not(.md-input-invalid).md-input-focused .md-input,
.note-drawer-menu md-input-container:not(.md-input-invalid).md-input-focused .md-input,
.note-drawer-panel md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-style: solid;
    border-width: 2px;
}
.note-drawer-timeline-new-observation .note-drawer-block-user {
    padding-top: 12px;
}
.note-drawer-text-field .note-drawer-text-field-post {
    margin: 0 0 16px 0;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.md-button.note-drawer-text-field-cancel {
    margin: 16px 18px 0 16px;
}
@media (max-width: 550px) {
    .md-button.note-drawer-text-field-cancel {
        margin: 16px 8px 0 8px;
    }
}

.note-drawer-panel-top {
    padding: 1px 0 8px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.note-drawer-sharing-panel .note-drawer-panel-top {
    padding-top: 4px;
}
.note-drawer-panel-top .note-drawer-panel-icon {
    width: 44px;
    text-align: center;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.note-drawer-panel-top .note-drawer-menu-title {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    margin: 0;
}
.note-drawer-panel-no-icon .note-drawer-menu-title {
    margin-left: 16px;
}
.note-drawer-panel {
    margin: 12px 12px 12px 44px;
}
.note-drawer-panel-no-icon .md-button.note-drawer-panel-post {
    margin-left: 16px;
}
.note-drawer-panel .note-drawer-panel-cancel {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.note-drawer-panel md-radio-button .md-ripple-container,
.note-drawer-panel md-radio-button .md-container:before {
    /* This fixes a positioning bug where the focus disk of md-radio-button was being incorrectly placed. */
    transform: translate(10px, 0);
}
.note-drawer-wrapper .md-button.formblock-open-button {
    text-transform: none;
    margin: 0 0 0 -8px;
}
.note-drawer-signature-block p {
    margin: 12px 0;
}
.note-drawer-signature-block p:last-child {
    margin-bottom: 0;
}
.note-drawer-signature-block-name {
    color: rgba(0, 0, 0, 0.87);
}
.note-drawer-signature-warning {
    background: #fff;
    padding: 12px;
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-signature-warning-text {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}
.note-drawer-signature-warning p {
    margin: 0;
}
.note-drawer-signature-warning button {
    margin: 12px 0 0 0;
}
.note-drawer-signature-warning .material-icons {
    color: rgba(255, 69, 0, 0.87);
    margin-right: 8px;
}
.note-drawer-signed-items button {
    margin: 12px 0 0 14px;
}
.note-drawer-signed-items p {
    margin: 4px 0 24px 14px;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.44px;
    text-align: left;
    line-height: 20px;
}
.note-drawer-plan-wrapper {
    display: inline-block;
    position: relative;
    min-height: 100px;
}
.note-drawer-pin {
    height: 60px;
    width: 90px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0px, -30px);
}

.note-drawer-pin.form-pin { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
    transform: translate(0px, -20px);
}
.note-drawer-pin img,
.pin img,
.note-drawer-pin bb-icon-pin {
    width: 35px;
    height: 35px;
    position: absolute;
    left: 30%;
    top: 20px;
}
.note-drawer-pin span,
.pin span {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1em;
    font-weight: bold;
    color: #000;
    width: 65px;
    text-align: center;
}
.note-drawer-pin span.closed,
.pin span.closed {
    background-color: transparent;
}
.note-drawer-panel {
    background: #fefefe;
    min-height: 32px;
    max-width: 596px;
}
@media (max-width: 720px) {
    .note-drawer-panel {
        max-width: calc(100vw - 32px);
    }
}
.note-drawer-panel .note-drawer-text-field {
    margin-left: 12px;
}
.note-drawer-panel.note-drawer-title-edit-menu {
    min-width: 500px;
    margin-left: 24px;
}
@media (max-width: 550px) {
    .note-drawer-recipients-panel {
        margin-left: 0 !important;
    }
    .note-drawer-panel,
    .note-drawer-recipients-panel {
        max-width: calc(100vw - 32px) !important;
    }
    .note-drawer-panel.note-drawer-title-edit-menu {
        min-width: auto;
        width: calc(100vw - 32px);
    }
}
@media (max-width: 520px) {
    .note-drawer-panel.note-drawer-title-edit-menu {
        min-width: 300px;
    }
}
.note-drawer-panel.note-drawer-status-edit-menu {
    width: 300px;
    transition: ease-out 300ms;
}

.note-drawer-panel.note-drawer-status-edit-menu-history {
    width: 552px;
    transition: ease-in 300ms;
}

.note-drawer-panel.note-drawer-status-edit-menu md-ink-bar {
    right: 50% !important;
}

.note-drawer-panel.note-drawer-status-edit-menu-history md-ink-bar {
    background-color: #e95a0d;
    left: 50% !important;
}

.edit-status-history-list {
    width: 95%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
}

.note-drawer-panel.note-drawer-status-edit-menu-history .history-item {
    max-width: 469px;
    min-width: 469px;
}

.note-drawer-panel.note-drawer-status-edit-menu-history .history-item-label {
    background-color: green;
    border-radius: 3px;
    color: whitesmoke;
    padding: 5px;
    width: auto;
    margin-right: 10px;
    width: 100px;
    text-align: center;
}

.note-drawer-panel.note-drawer-status-edit-menu-history .history-item-label-placeholder {
    width: 110px;
    min-width: 110px;
    height: 34px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    animation: history-placeholder-animation 500ms infinite;
    animation-direction: alternate;
}

.note-drawer-panel.note-drawer-status-edit-menu-history .md-list-item-text-placeholder {
    width: 290px;
    height: 10px;
    margin-left: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    animation: history-placeholder-animation 500ms infinite;
    animation-direction: alternate;
}

@keyframes history-placeholder-animation {
    from {
        background-color: rgba(0, 0, 0, 0.1);
    }
    to {
        background-color: rgba(0, 0, 0, 0.18);
    }
}

.note-drawer-panel.note-drawer-add-assignees-menu,
.note-drawer-panel.note-drawer-add-tags-menu {
    width: 460px;
}
.note-drawer-sharing-panel .note-drawer-panel-help {
    margin-left: 44px;
    margin-bottom: 8px;
}
.note-drawer-panel md-radio-button {
    margin-left: 3px;
}
.note-drawer-panel-help,
.note-drawer-panel-none,
.note-drawer-panel-special-help {
    margin: 0 16px;
    font-family: 'Work Sans', sans-serif;
    font-size: 14.79px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
}
p.note-drawer-panel-special-help {
    margin: 8px 16px 8px 16px;
    display: -ms-flexbox;
    display: flex;
    background: #eeeeee;
    color: rgba(0, 0, 0, 0.7);
    padding: 8px;
}
.note-drawer-panel-special-help-icon {
    margin: -2px 8px 0 0;
    color: #0288d1;
}
p.note-drawer-panel-none {
    margin-left: 44px;
}
p.note-drawer-section-label {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin: 0 4px 0 0;
}
@media (max-width: 550px) {
    p.note-drawer-section-label {
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    }
}
.note-drawer-faded-list {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    padding-right: 16px;
    position: relative;
}
.note-drawer-faded-list-with-add {
    -ms-flex: 1;
        flex: 1;
}
.note-drawer-list-fade {
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 20px;
    background: linear-gradient(to right, rgba(244, 245, 247, 0), rgba(244, 245, 247, 1));
}
.note-drawer-property-section-hoverable:hover .note-drawer-list-fade {
    background: linear-gradient(to right, rgba(249, 250, 251, 0), rgba(249, 250, 251, 1));
}
.note-drawer-property-section .md-button {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.note-drawer-add-flexible-position {
    -ms-flex: 0 auto;
        flex: 0 auto;
}
.note-drawer-property-add-wrapper {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}
.md-button.note-drawer-add {
    background: rgba(22, 50, 71, 0.12);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    height: 30px;
    width: 30px;
    padding: 3px;
    min-height: 30px;
    margin: 0 0 0 16px;
    color: #0288d1;
}
.md-button.note-drawer-add:not([disabled]).md-icon-button:hover {
    background: rgba(22, 50, 71, 0.2);
}
.md-button.note-drawer-add .md-ripple-container {
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
}
.note-drawer-property-add-wrapper .md-button,
.note-drawer-panel-add-button {
    color: #0288d1;
    margin-left: 0;
}
.note-drawer-property-add-wrapper .md-button {
    margin: 2px 0;
}
.note-drawer-sharing {
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-sharing-avatar {
    display: inline-block;
    margin-left: 4px;
}
.md-button.note-drawer-share-button {
    background: #0288d1;
    color: #fff;
    margin-left: 0;
    margin-right: 0;
}
.note-drawer-share-button-wrapper {
    padding-left: 12px;
}
.md-button.note-drawer-share-button.md-raised:not([disabled]):hover {
    background-color: #01579b;
}
.note-drawer-chip {
    display: -ms-flexbox;
    display: flex;
    background: rgba(22, 50, 71, 0.06);
    height: 30px;
    border-radius: 15px;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.44px;
    line-height: 30px;
    padding: 0 12px;
    margin-left: 8px;
    position: relative;
    overflow: hidden;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
}
.task-duplication-wrapper .note-drawer-chip {
    margin: 4px;
}
.note-drawer-chip.note-drawer-chip-selected {
    background: var(--primary-color);
    color: white;
}
.note-drawer-chip-labelled {
    display: block;
    height: 48px;
    margin: 6px 0 6px 4px;
}
.note-drawer-chip.note-drawer-chip-with-avatar {
    padding: 0 12px 0 0;
}
.note-drawer-chip .note-drawer-user-avatar,
.note-drawer-chip .note-drawer-group-avatar {
    border-radius: 13px;
    height: 26px;
    width: 26px;
    line-height: 26px;
    vertical-align: top;
    margin: 2px 6px 0 3px;
}
.note-drawer-chip .note-drawer-user-avatar img {
    max-width: 26px;
    max-height: 26px;
}
.note-drawer-chip-editable {
    height: auto;
    display: -ms-flexbox;
    display: flex;
}
.note-drawer-chip-editable-indicator,
.note-drawer-chip-inline-editable-indicator {
    display: inline-block;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 14.79px;
    color: #0288d1;
    letter-spacing: 1.25px;
    line-height: 16px;
    text-transform: uppercase;
}
.note-drawer-chip-editable-indicator {
    padding: 0 16px 0 24px;
    -ms-flex-item-align: center;
        align-self: center;
}
.note-drawer-chip-editable-content {
    display: inline-block;
}
.note-drawer-chip-editable-content .note-drawer-chip-content {
    margin-bottom: 4px;
}
.note-drawer-chip-label {
    font-family: 'Work Sans', sans-serif;
    font-size: 12.68px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.4px;
    text-align: left;
    line-height: 16px;
    display: block;
    margin-top: 7px;
}
.note-drawer-chip-selected .note-drawer-chip-label {
    color: white;
}

.note-drawer-chip.removed {
    font-style: italic;
}
.note-drawer-chip-content {
    display: block;
    line-height: 22px;
}
.note-drawer-panel-chip-editable-list,
.note-drawer-panel-chip-list {
    padding-left: 36px;
    padding-right: 16px;
}
.note-drawer-panel-chip-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: scroll;
}
.note-drawer-panel-chip-editable-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
}
.note-drawer-panel-chip-editable-list .note-drawer-chip,
.note-drawer-panel-chip-list .note-drawer-chip {
    margin-bottom: 8px;
}
.note-drawer-panel-list .note-drawer-user-avatar,
.note-drawer-panel-list .note-drawer-group-avatar {
    height: 42px;
    width: 42px;
    line-height: 42px;
    margin-right: 12px;
}
.note-drawer-panel-list .note-drawer-user-avatar img {
    max-width: 42px;
    max-height: 42px;
}
.md-button.note-drawer-chip-delete {
    background: rgba(22, 50, 71, 0.32);
    border-radius: 50%;
    overflow: hidden;
    height: 26px;
    width: 26px;
    padding: 3px;
    line-height: 26px;
    min-height: 26px;
    margin: 2px -9px 0px 8px;
    color: #fff;
}
.note-drawer-chip-clickable:hover {
    cursor: pointer;
    background: rgba(22, 50, 71, 0.2);
}
.md-button.note-drawer-chip-delete:not([disabled]).md-icon-button:hover {
    background: rgba(22, 50, 71, 0.5);
}
.md-button.note-drawer-chip-delete .md-ripple-container {
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
}
.note-drawer-panel-list-name {
    font-family: 'Work Sans', sans-serif;
    font-size: 16.91px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.15px;
    line-height: 24px;
    word-wrap: break-word;
}
.note-drawer-panel-list .md-list-item-inner > md-checkbox {
    margin-right: 4px;
}
.note-drawer-panel-list {
    height: 294px;
}
.note-drawer-panel-list-divider-top {
    margin-top: 16px;
}
.note-drawer-panel-list-divider-bottom {
    margin-bottom: 16px;
}

@keyframes show-confirmation-ripple {
    from {
        transform: translate3d(300px, -300px, 0) scale3d(0, 0, 1);
        opacity: 0.35;
    }

    40% {
        opacity: 0.35;
    }

    50% {
        transform: translate3d(300px, -300px, 0) scale3d(2, 2, 1);
    }

    to {
        opacity: 0;
        transform: translate3d(300px, -300px, 0) scale3d(2, 2, 1);
    }
}

.note-drawer-section-edited-badge {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 14px;
    background: #00c853;
    color: #fff;
    text-align: center;
    line-height: 24px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    transform: translateY(-12px);
}
.note-drawer-section-edit-confirmed .note-drawer-section-edited-badge {
    transition: opacity 0.2s ease-in;
    opacity: 1;
}
.note-drawer-section-edited-badge i.material-icons {
    line-height: 24px;
}
.note-drawer-section-ripple {
    position: absolute;
    /*display: none;*/
    width: 600px;
    height: 600px;
    background: #81c784;
    border-radius: 50%;
    right: 22px;
    top: 50%;
    pointer-events: none;
    opacity: 0.35;
    transform: translate3d(300px, -300px, 0) scale3d(0, 0, 1);
    transform-origin: 50% 50%;
}
.note-drawer-section-edit-confirmed .note-drawer-section-ripple {
    /*transition: transform 0.4s ease-in;*/
    /*transform: translate3d(300px, -300px, 0) scale3d(1, 1, 1);*/
    animation: 0.5s show-confirmation-ripple ease-in both;
}
.note-drawer-button-content-wrapper {
    display: -ms-flexbox;
    display: flex;
    vertical-align: middle;
    -ms-flex-align: center;
        align-items: center;
    position: relative;
    width: 100%;
}
.note-drawer-button-content-wrapper > i.material-icons:first-child {
    text-align: center;
}
.note-drawer-button-content-wrapper .note-drawer-section-edited-badge {
    right: 0;
}
.note-drawer-status-button.in-progress .note-drawer-section-ripple {
    background: #e57373;
}
.note-drawer-status-button.pending .note-drawer-section-ripple {
    background: #ffd54f;
}

.note-drawer-recipients-panel {
    min-width: 0 !important;
    width: 522px;
    margin-left: 31px;
}

/* Report Recipients Section */

.recipients-property-section {
    background: rgba(255, 255, 255, 0.5);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    min-height: 42px;
    width: 188px;
    margin: 8px 30px;
    color: rgba(0, 0, 0, 0.72);
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 0.47px;
    line-height: 16px;
    position: relative;
    overflow: hidden;
}
.recipients-property-section-hoverable:hover {
    background: rgba(255, 255, 255, 0.72);
    cursor: pointer;
}
@media (max-width: 720px) {
    .recipients-property-section {
        margin: 8px 16px;
    }
}

.recipients-property-section > .material-icons {
    color: rgba(0, 0, 0, 0.54);
}

.recipients-property-section > span > .material-icons:first-child,
.recipients-property-section > .material-icons:first-child {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    text-align: center;
    width: 44px;
}

.recipients-property-section:last-child {
    margin-bottom: 0;
}

.md-button.recipients-property-section {
    text-align: left;
    text-transform: none;
}
.md-button.recipients-property-section:hover,
.md-button.recipients-property-section.md-focused {
    background: rgba(255, 255, 255, 1);
}

.md-button.recipients-property-section.recipients-status-button {
    /*border-left: 6px solid;*/
    padding-left: 0;
}

.recipients-labelled-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
}

.recipients-labelled-item .recipients-label {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    color: rgba(35, 47, 52, 0.7);
    letter-spacing: 0.46px;
}

.recipients-labelled-item .recipients-item {
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: #232f34;
    letter-spacing: 0.57px;
}

.recipients-panel .md-button.md-primary {
    background-color: rgba(233, 90, 13, 1);
}

.md-button.md-outlined {
    color: var(--primary-color) !important;
    background-color: transparent !important;
    background-image: none !important;
    border: 1px solid var(--primary-color) !important;
}
.md-button.md-button-small {
    min-height: 19px;
    line-height: 19px;
    font-weight: 200;
    font-size: 12px;
}
.md-button.md-outlined:hover {
    color: var(--primary-font-color) !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.md-button.md-outlined:disabled {
    background-color: #ccc !important;
    color: #fff !important;
    cursor: not-allowed !important;
}

.md-button.md-outlined.md-warn {
    color: darkred !important;
    background-color: transparent !important;
    background-image: none !important;
    border: 1px solid darkred !important;
}
.md-button.md-outlined.md-warn:hover {
    color: whitesmoke !important;
    background-color: darkred !important;
    border-color: darkred !important;
}

.recipients-panel .md-button.md-primary.md-raised:hover,
.recipients-panel .md-button.md-primary.md-raised.md-focused {
    background-color: #bf360c;
}
.recipients-panel .md-button.md-primary.md-raised[disabled] {
    background-color: rgba(233, 90, 13, 0.2);
}

.recipients-user-avatar,
.recipients-group-avatar {
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    height: 32px;
    width: 32px;
}
.recipients-group-avatar {
    background: #455a64;
    color: #eceff1;
    line-height: 32px;
    vertical-align: middle;
    text-align: center;
}
.recipients-user-avatar-default {
    background-color: rgba(22, 50, 71, 0.2);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url('/vendor/img/user_picture_default.png');
}

.recipients-panel .md-button.recipients-panel-post {
    margin: 12px 12px 12px 44px;
}
.recipients-panel {
    background: #fefefe;
    min-height: 32px;
    max-width: 596px;
}
@media (max-width: 720px) {
    .recipients-panel {
        max-width: calc(100vw - 32px);
    }
}
@media (max-width: 550px) {
    .recipients-panel {
        max-width: calc(100vw - 32px);
    }
}
p.recipients-section-label {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin: 0 4px 0 0;
}
@media (max-width: 550px) {
    p.recipients-section-label {
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    }
}
.recipients-faded-list {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    padding-right: 16px;
    position: relative;
}
.recipients-faded-list-with-add {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
}
.recipients-list-fade {
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 20px;
    background: linear-gradient(to right, rgba(244, 245, 247, 0), rgba(244, 245, 247, 1));
}
.recipients-property-section-hoverable:hover .recipients-list-fade {
    background: linear-gradient(to right, rgba(249, 250, 251, 0), rgba(249, 250, 251, 1));
}
.recipients-property-section .md-button {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.recipients-add-flexible-position {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}
.md-button.recipients-add {
    background: rgba(22, 50, 71, 0.12);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    height: 30px;
    width: 30px;
    padding: 3px;
    min-height: 30px;
    margin: 0 0 0 16px;
    color: #0288d1;
}
.md-button.recipients-add:not([disabled]).md-icon-button:hover {
    background: rgba(22, 50, 71, 0.2);
}
.md-button.recipients-add .md-ripple-container {
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
}
.recipients-property-add-wrapper .md-button,
.recipients-panel-add-button {
    color: #0288d1;
    margin-left: 0;
}
.recipients-property-add-wrapper .md-button {
    margin: 2px 0;
}
.recipients-sharing {
    display: -ms-flexbox;
    display: flex;
}
.recipients-sharing-avatar {
    display: inline-block;
    margin-left: 4px;
}
.md-button.recipients-share-button {
    background: #0288d1;
    color: #fff;
    margin-left: 0;
    margin-right: 0;
}
.recipients-share-button-wrapper {
    padding-left: 12px;
}
.md-button.recipients-share-button.md-raised:not([disabled]):hover {
    background-color: #01579b;
}
.recipients-chip {
    display: -ms-flexbox;
    display: flex;
    background: rgba(22, 50, 71, 0.06);
    height: 30px;
    border-radius: 15px;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.44px;
    line-height: 30px;
    padding: 0 12px;
    margin-left: 8px;
    position: relative;
    overflow: hidden;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}
.recipients-chip-labelled {
    display: block;
    height: 48px;
    margin: 6px 0 6px 4px;
}
.recipients-chip.recipients-chip-with-avatar {
    padding: 0 12px 0 0;
}
.recipients-chip .recipients-user-avatar,
.recipients-chip .recipients-group-avatar {
    border-radius: 13px;
    height: 26px;
    width: 26px;
    line-height: 26px;
    vertical-align: top;
    margin: 2px 6px 0 3px;
}
.recipients-chip .recipients-user-avatar img {
    max-width: 26px;
    max-height: 26px;
}
.recipients-chip-label {
    font-family: 'Work Sans', sans-serif;
    font-size: 12.68px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.4px;
    text-align: left;
    line-height: 16px;
    display: block;
    margin-top: 7px;
}
.recipients-panel-chip-list {
    padding-left: 36px;
    padding-right: 16px;
}
.recipients-panel-chip-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: scroll;
}
.recipients-panel-chip-list .recipients-chip {
    margin-bottom: 8px;
}
.recipients-panel-list .recipients-user-avatar,
.recipients-panel-list .recipients-group-avatar {
    height: 42px;
    width: 42px;
    line-height: 42px;
    margin-right: 12px;
}
.recipients-panel-list .recipients-user-avatar img {
    max-width: 42px;
    max-height: 42px;
}
.recipients-panel-list-name {
    font-family: 'Work Sans', sans-serif;
    font-size: 16.91px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.15px;
    line-height: 24px;
}
.recipients-panel-list .md-list-item-inner > md-checkbox {
    margin-right: 4px;
}
.recipients-panel-list {
    height: 294px;
}

.recipients-panel md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #e95a0d;
}

.recipients-panel md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-bottom: #e95a0d 2px solid !important;
}

.reminders-wrapper {
    min-width: 300px;
}
.reminders-buttons-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: middle;
        align-items: middle;
}

/*
 *
 * Custom Statuses Drawer
*/
p.custom-statuses-drawer-help {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    background: #eeeeee;
    padding: 10px 30px;
    font-family: 'Work Sans', sans-serif;
    font-size: 14.79px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
}
div.custom-statuses-drawer-help {
    background: #eeeeee;
    padding: 20px;
    font-family: 'Work Sans', sans-serif;
    font-size: 14.79px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.custom-statuses-drawer .custom-status-item:hover {
    background: rgba(255, 255, 255, 0.72);
    cursor: pointer;
}

.custom-statuses-drawer .custom-status-item {
    padding: 0 14px;
    position: relative;
    overflow: visible;
    background: rgba(255, 255, 255, 0.5);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    min-height: 42px;
    margin: 8px 30px;
    color: rgba(0, 0, 0, 0.72);
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 0.47px;
    line-height: 16px;
}

.custom-statuses-drawer .dndDraggingSource {
    display: none !important;
}

.custom-statuses-drawer .dndPlaceholder {
    min-height: 42px;
    height: 42px !important;
    margin: 8px 30px;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: 2px;
}

.note-filter-custom-status-container .md-text {
    width: 100%;
}
md-select-menu[multiple] md-option.note-filter-custom-status-container.md-checkbox-enabled .md-icon {
    border-color: inherit;
    background-color: inherit;
}
md-select-menu[multiple] md-option.note-filter-custom-status-container .md-container {
    border-color: inherit;
    background-color: inherit;
}
md-select-menu[multiple] md-option.note-filter-custom-status-container.md-checkbox-enabled .md-icon:after {
    border-color: inherit;
}

/* plan caption panel style */
.plan-caption-panel-item-wrapper {
    width: auto;
    height: auto;
    display: inline-block;
    margin: 3px;
    border-radius: 3px;
    position: relative;
    background-color: white;
}

.plan-caption-panel-item-color {
    width: 30px;
    display: inline-block;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 3px 0px 0px 3px;
}

.plan-caption-panel-item-text {
    margin-left: 29px;
    color: rgb(60, 60, 60);
    margin-left: 29px;
    display: block;
    color: rgb(60, 60, 60);
    text-overflow: ellipsis;
    width: -webkit-fill-available;
    overflow: hidden;
}
/* end */

.no-focus-trap {
    width: 0;
    height: 0;
}

/** Custom status management panel styles **/
.custom-statuses-drawer .move-icon {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 8px;
    cursor: pointer;
}

.custom-statuses-drawer .show-assigness-icon {
}

.custom-status-color {
    border-radius: 50%;
    min-height: 22px;
    min-width: 22px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.custom-status-name-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    position: relative;
    min-width: 120px;
    max-width: 120px;
    margin: 0 0 0 8px;
    min-height: 42px;
}

.custom-status-name-wrapper p,
.custom-status-name-wrapper input {
    position: absolute;
}

.custom-status-name-wrapper p {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin: 0 4px 0 0;
    width: 100%;
    word-break: break-word;
}

.custom-statuses-drawer input {
    width: 100%;
    color: rgba(0, 0, 0, 0.72);
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 0.47px;
    padding: 0;
    border: none;
    padding-bottom: 2px;
    background: transparent;
    top: 11px;
}

.custom-statuses-drawer input.input-error {
    animation: shake 0.52s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    border-bottom: solid 2px #d9534f !important;
}

.custom-statuses-drawer .edit-icon {
    font-size: 16px;
    position: absolute;
    right: 0;
}
.custom-status-item .show-authorizations-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    z-index: 1000;
}

.custom-statuses-authorizations-panel {
    position: absolute;
    top: -10px;
    left: -15px;
    width: 100%;
    z-index: 2000;
}

.custom-statuses-add-authorizations-panel {
    position: absolute;
    top: 0;
    right: 10px;
    width: 360px;
    z-index: 2001;
}

.custom-status-item md-progress-circular svg path {
    stroke: orangered;
}

.custom-status-item .custom-statuses-add-authorizations-button {
    background: rgba(22, 50, 71, 0.12);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    height: 30px;
    width: 30px;
    padding: 3px;
    min-height: 30px;
    margin: 0 0 0 16px;
    color: #0288d1;
    opacity: 0;
    display: none;
    transition: opacity 0.2s linear;
}

.custom-status-item:hover .custom-statuses-add-authorizations-button {
    display: inline-block;
    opacity: 1;
}
.custom-status-item:hover .custom-statuses-add-authorizations-button:hover {
    background: rgba(22, 50, 71, 0.1);
    display: inline-block;
    opacity: 1;
}

.fade-animation.ng-enter,
.fade-animation.ng-leave {
    transition: opacity cubic-bezier(0, 0, 0.2, 1) 0.3s;
}
.fade-animation.ng-enter,
.fade-animation.ng-leave.ng-leave-active {
    opacity: 0;
}
.fade-animation.ng-leave,
.fade-animation.ng-enter.ng-enter-active {
    opacity: 1;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.custom-statuses-color-picker:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fefefe;
    top: -8px;
    left: 41px;
}
.custom-statuses-color-picker {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 250px;
    position: absolute;
    top: 41px;
    left: 2px;
    z-index: 3002;
    background: #fefefe;
    border-radius: 2px;
    padding: 12px;
}

.custom-statuses-color-picker.color-form-field-picker {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 250px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 3002;
    background: #fefefe;
    border-radius: 2px;
    padding: 12px;
}

.color-picker-color-chip.color-form-field-chip {
    height: 20px;
    width: 20px;
    margin: 5px;
    border-radius: 10px;
    display: inline-block;
}
.color-picker-color-chip {
    height: 50px;
    width: 50px;
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    transition: all 0.2s ease;
}
.color-picker-color-chip:hover {
    opacity: 0.9;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.color-picker-color-chip > i {
    color: #fefefe;
}

.status-delete-button {
    position: absolute;
    right: -14px;
    font-size: 10px;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
}

.custom-status-item:hover .status-delete-button {
    visibility: visible;
    opacity: 1;
    color: grey;
}
#drawer-projects.status-management-drawer,
#drawer-projects.sharing-management-drawer,
#project-settings-drawer.status-management-drawer {
    background-color: #eceff1;
}
#drawer-projects.md-sidenav-left.sharing-management-drawer {
    width: 850px;
    transition: width 0.5s ease-in-out;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out;
}
#drawer-projects.md-sidenav-left.status-management-drawer {
    width: 750px;
    transition: width 0.5s ease-in-out;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out;
}

md-backdrop.md-select-backdrop {
    z-index: 2002;
}

@media (min-width: 450px) {
    .compact-md-select-menu-container {
        min-width: 300px !important;
        width: 300px !important;
        margin: auto;
    }
}

.note-status-item-disabled {
    opacity: 0.35 !important;
}

/**
 * New report design
 */

.report-wrapper {
}

.report-wrapper h3,
.report-wrapper h4,
.report-wrapper h5 {
    color: #1f1f1f;
}

.report-sections-margin {
    margin-bottom: 30px;
}

.chevron-back {
    height: 25px;
    width: 25px;
    opacity: 0.65;
    background-color: #000000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    color: #fff;
    cursor: pointer;
}

.report-header {
    display: -ms-flexbox;
    display: flex;
}

.report-header > div {
    margin-right: 20px;
}

.report-header .seperator {
    border-left: 1px solid #000000;
    opacity: 0.25;
    height: 25px;
}

.report-header .selected-tasks {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.report-header .selected-tasks > i {
    color: #70b62c;
}
.report-header .selected-tasks > span {
    font-weight: 600;
}

.reports-carousel {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    height: 283px;
}

.report-card {
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 20px;
    box-sizing: border-box;
    height: 220px;
    min-width: 220px;
    max-width: 220px;
    border: 1px solid #e4e3e2;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.report-card.report-card-last {
    margin-right: 66px;
}
.report-card.report-card-last:after {
    content: '';
    position: absolute;
    border-left: 1px solid black;
    right: -33px;
    height: 90%;
}
.report-card .report-list-subtitle {
    position: absolute;
    margin-top: -42px;
    margin-left: 100px;
    width: 320px;
}

.container-date-field label {
    width: 86% !important;
}

.note-wrapper {
    margin-left: 5px !important;
    margin-bottom: 5px !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center !important;
        align-items: center !important;
    -ms-flex-pack: center !important;
        justify-content: center !important;
}
.note-small-date {
    text-align: right;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 4px 0 0 4px;
    padding: 3px;
}
.report-card-fader {
    position: absolute;
    top: 0;
    right: -30px;
    height: 225px;
    width: 150px;
    background: linear-gradient(to right, rgba(244, 245, 247, 0), rgba(244, 245, 247, 1));
    z-index: 1000;
    pointer-events: none;
}

.report-card.selected {
    border: 1px solid #1deaa4;
    background-color: rgba(29, 234, 164, 0.1);
}

.carousel-arrow-right {
    position: absolute;
    right: 20px;
    height: 20px;
    z-index: 1199;
    top: 42%;
    cursor: pointer;
}
#slider {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
}
#slider::-webkit-scrollbar {
    display: none;
}
#slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.carousel-arrow-left {
    position: absolute;
    left: 20px;
    height: 20px;
    z-index: 1199;
    top: 42%;
    cursor: pointer;
}

.report-font-bold {
    font-weight: 600;
}
.md-button.md-icon-button[disabled] {
    opacity: 0.3;
}
.report-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.flex-column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.report-card .delete-user-template {
    position: absolute;
    top: 7px;
    right: 5px;
    visibility: hidden;
    cursor: pointer;
}

.report-card:hover .delete-user-template {
    visibility: visible;
}

.delete-user-template i {
    opacity: 1;
}

.report-name {
    text-align: center;
    height: 50px;
}

.report-card .report-description {
    text-align: center;
    height: 50px;
    color: #414142;
}

.report-image {
    height: 80px;
}

.report-card md-radio-button {
}

.report-card md-radio-button .md-off,
.report-card md-radio-button .md-on {
    width: 16px;
    height: 16px;
}

.report-configuration-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    margin-bottom: 30px;
}

.report-configuration,
.report-settings {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.13);
}

.report-configuration {
    width: 70%;
}

.report-configuration-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.report-configuration-header h3 {
    margin: 20px 0 20px 20px;
}

.report-configuration-section {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.report-section-heading {
    font-size: 16px;
}

.page-header-config button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    height: 82px;
    width: 82px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.02);
}

.page-header-section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.page-header-config .center-text {
    padding-left: 20px;
    padding-right: 20px;
}

.page-header-section button i {
    font-size: 40px;
    opacity: 0.4;
}

.page-header-section > span {
    display: block;
    color: #414142;
    font-size: 10px;
    letter-spacing: 0.77px;
    font-weight: bold;
    line-height: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.page-header-section md-input-container {
    margin: 0;
    padding: 0;
}

/**
 * Custom cover
 */

.report-custom-cover md-input-container {
    margin: 0;
    padding: 0;
    width: 213px;
}

.report-configuration-section .add-item-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    background: transparent;
    border: none;
}
.report-configuration-section .add-item-button > span {
    text-decoration: underline;
}

/**
 */
.report-options {
    display: -ms-flexbox;
    display: flex;
}
.report-options > .page-header-section > span {
    display: block;
    color: #414142;
    font-size: 12px;
    letter-spacing: 0.77px;
    font-weight: bold;
    line-height: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

/**
 * Report settings
 */

.report-settings::before {
    content: '';
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #1deaa4;
    top: 16px;
    left: -12px;
}

.report-settings {
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    margin-left: 18px;
    border-left: 2px solid #1deaa4;
    width: 450px;
}

.report-format-card {
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -ms-flex-align: center;
        align-items: center;
    box-sizing: border-box;
    height: 96px;
    border-radius: 5px;
    border: 1px solid #e4e3e2;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.report-format-card.selected {
    border: 1px solid #1deaa4;
    background-color: rgba(29, 234, 164, 0.1);
}
.report-format-card.disabled {
    cursor: default;
}

.report-settings md-button {
    height: 36px;
    width: 325px;
    border-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.tns-liveregion.tns-visually-hidden {
    display: none;
}

.custom-report-info {
    height: 40px;
    border: 1px solid #47b1ff;
    background-color: rgba(71, 177, 255, 0.2);
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    position: absolute;
    bottom: -60px;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
}
.custom-report-info i {
    vertical-align: center;
}

.custom-report-info i {
    margin-left: 15px;
    font-size: 22px;
    color: #2f80bc;
}
.custom-report-info a {
    margin-right: 15px;
    color: #2f80bc;
}
.custom-report-info span {
    margin-left: 7px;
}

.report-header-logo {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}

.breadcrumb .breadcrumb-active {
    color: white !important;
    opacity: 0.84 !important;
}

.breadcrumb .breadcrumb-inactive {
    color: white !important;
    opacity: 0.53 !important;
}

.project-template-banner-overlay {
    font-family: 'Work Sans', sans-serif;
    /* position: fixed; */
    /* top: 57px; */
    width: calc(100%);
    z-index: 100;
    background-color: var(--primary-color);
}
.project-template-banner-overlay .project-template-banner-title {
    text-align: center;
    vertical-align: middle;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-font-color);
}
.resignin-card {
    background: #eee;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
}
.resignin-card-wrapper img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 75ch;
    margin-bottom: 45px;
}

#drawer-projects {
    z-index: 10000;
    width: 500px;
}
.project-drawer-toolbar {
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.2);
}
.report-card.empty-state .report-font-bold {
    width: 150px;
}
.report-card.empty-state .report-font-bold,
.report-card.empty-state .report-font-bold,
.report-card.empty-state .report-description,
.project-drawer-wrapper md-list md-list-item.emtpy-state p,
.form-export-template-wrapper md-list md-list-item.emtpy-state p {
    width: 90%;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderRipple;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeeeb4 8%, #ddddddbd 18%, #eeeeee3f 33%);
}
div.note-placeholder span.title {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderRipple;
    animation-timing-function: linear;
    background: linear-gradient(90deg, var(--secondary-color), #cacaca70, #eaeaeaa6, #cacaca70, var(--secondary-color));
}
div.note-placeholder span.subtitle {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderRipple;
    animation-timing-function: linear;
    background: linear-gradient(90deg, #cacaca70, #eaeaeaa6, #cacaca70);
}
.project-drawer-wrapper md-list md-list-item button {
    padding-top: 8px;
    padding-bottom: 8px;
}
.project-drawer-wrapper md-list md-list-item.emtpy-state h3 {
    width: 60%;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderRipple;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeeeb4 8%, #ddddddbd 18%, #eeeeee3f 33%);
}
@keyframes placeholderRipple {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 100em 0;
    }
}
#drawer-projects md-list md-list-item div.md-button {
    padding-top: 8px;
    padding-bottom: 8px;
}
.project-drawer-wrapper md-backdrop {
    z-index: 1001;
}
#projects-menu-open-button {
    line-height: 56px;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    color: white;
}
#projects-menu-open-button img {
    border-radius: 5px;
    height: 35px;
    width: 35px;
    margin-top: 2px;
    margin-left: 4px;
}

#projects-menu-open-button .menu-title {
    text-transform: capitalize;
    font-family: 'Work Sans', sans-serif;
    cursor: pointer;
    color: white;
}
#projects-menu-open-button:hover {
    background: rgba(0, 0, 0, 0.5);
}
.project-drawer-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
.project-drawer-transition.ng-enter {
    transition: opacity 0.9s;
}

.project-drawer-transition.ng-enter,
.project-drawer-transition.ng-leave.ng-leave-active {
    opacity: 0;
}

.project-drawer-transition.ng-leave,
.project-drawer-transition.ng-enter.ng-enter-active {
    opacity: 1;
}

.report-button-animation.ng-enter {
    transition: opacity 0.5s;
}
.report-button-animation.ng-enter,
.report-button-animation.ng-leave.ng-leave-active {
    opacity: 0;
}

.report-button-animation.ng-leave,
.report-button-animation.ng-enter.ng-enter-active {
    opacity: 1;
}
.select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 16px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}
.select-header-search-bar {
    border: none !important;
    outline: none !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}
md-datepicker {
    display: inline-block;
    padding: 0;
    width: 100%;
}

.md-datepicker-button {
    margin-left: -12px;
}
.md-datepicker-input-container {
    display: -ms-flexbox;
    display: flex;
}
.md-datepicker-input {
    max-width: 100%;
    min-width: 100%;
}

.md-datepicker-triangle-button {
    right: 8px;
}
.empty-state-element-list {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px;
    border: 1px #333;
    border-radius: 4px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
    width: 70vw;
}
.project-drawer-wrapper {
    height: 100vh;
}
md-checkbox.semi-checked:not(.md-checked) .md-icon::after {
    box-sizing: border-box;
    transform: rotate(90deg);
    position: absolute;
    left: 4.66667px;
    top: 0.22222px;
    display: table;
    width: 6.66667px;
    height: 13.33333px;
    border-width: 2px;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    content: '';
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
}
md-checkbox.semi-checked:not(.md-checked) .md-icon {
    background-color: #333;
    color: white;
}
.form-picture-static-image {
    background-color: #ccc;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    fill: black;
}
.form-picture-upload-box {
    width: 150px;
    height: 150px;
    background-color: #ccc;
    border-radius: 4px;
    border-width: 4px;
    border-style: dashed;
    border-color: #aaa;
}
.form-picture-upload-box i {
    font-size: 38px;
    color: #aaa;
}
.note-menu-button button {
    text-transform: capitalize;
}
.note-menu-button i {
    vertical-align: middle;
}
.note-menu-button .note-menu-title {
    color: black;
    opacity: 0.54;
    line-height: 22px;
}
.note-menu-button > button {
    margin-left: 0;
    margin-right: 0;
}
.note-menu-button .note-menu-value {
    color: black;
    line-height: 22px;
}
.note-menu-button .note-menu-container {
    margin-left: 2px;
    margin-right: 2px;
}
.note-menu-button svg {
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

.note-menu-button svg,
.note-menu-button img,
.svg-button-icon img,
.svg-button-icon svg {
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

.note-menu-button .caret-down {
    color: black;
    opacity: 0.54;
}
.report-status-drawer-chip {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    height: 20px;
    border-radius: 5px;
    font-family: 'Work Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 0.44px;
    line-height: 30px;
    padding: 0 12px;
    margin-left: 8px;
    overflow: hidden;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    shadow: 1px 1px 2px black, 0 0 1em white;
}

.report-status-drawer-chip p {
    width: 100%;
    text-align: center;
    padding-top: 2px;
}
.report-status-color-chip-0 {
    color: white;
    background-color: #7730fd;
    text-shadow: 1px 1px 2px black, 0 0 1em white;
    background: linear-gradient(to right, #7730fd, #9d6aff);
    background-size: 10000% 10000%;
    animation: gradient 2s ease-in-out infinite;
}
.report-status-color-chip-1 {
    color: white;
    background-color: #7730fd;
    text-shadow: 1px 1px 2px black, 0 0 1em white;
    background: linear-gradient(to right, #7730fd, #9d6aff);
    background-size: 10000% 10000%;
    animation: gradient 2s ease-in-out infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.report-status-color-chip-2 {
    color: white;
    background-color: green;
    text-shadow: 1px 1px 2px black, 0 0 1em white;
}
.report-status-color-chip-3 {
    color: black;
}
.report-failed-icon-warning {
    font-size: 20px;
    color: coral;
}
.report-failed-icon-restart {
    padding-left: 8px;
    font-size: 20px;
    color: white;
    float: left;
    padding-top: 8px;
}
.report-failed-message {
    float: left;
    padding-top: 3px;
}
.gradient-background {
    background: var(--secondary-color);
    background: linear-gradient(135deg, var(--secondary-color) 0%, var(--primary-color) 100%);
}
md-input-container .hint {
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    color: rgba(0, 0, 0, 0.54);
}
md-input-container.report-filename-input .hint {
    position: absolute;
    left: 2px;
    right: auto;
    bottom: 7px;
}

md-input-container.report-filename-input .hint.error {
    color: rgba(255, 0, 0, 0.54);
}
.md-option-wrapper div.md-text {
    width: 100%;
}
.iti.iti--allow-dropdown {
    width: 100%;
}
md-select-value .md-text .note-folder-back-button {
    display: none;
}

.form-field-preview-transition.ng-enter {
    transition: opacity 200ms ease-in-out;
}

.form-field-preview-transition.ng-enter,
.form-field-preview-transition.ng-leave.ng-leave-active {
    opacity: 0;
}

.form-field-preview-transition.ng-leave,
.form-field-preview-transition.ng-enter.ng-enter-active {
    opacity: 1;
}
md-radio-button[disabled] {
    opacity: 0.7;
}
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
    z-index: 999999999;
}
md-toolbar.top-shadow-toolbar {
    box-shadow: 0px -4px 13px 1px rgba(0, 0, 0, 0.45);
    -webkit-box-shadow: 0px -4px 13px 1px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0px -4px 13px 1px rgba(0, 0, 0, 0.45);
}
.form-template-title-label {
    color: black;
    opacity: 0.72;
    margin-top: 15px;
}
.form-template-subtitle-label {
    color: black;
    opacity: 0.54;
    margin-top: 10px;
}
.forms-tab-subtitle {
    background-color: #33333321;
}
.ng-animate.no-animate {
    transition: 0s none;
    -webkit-transition: 0s none;
    animation: 0s none;
    -webkit-animation: 0s none;
}
#form-template-list .form-template:last-child {
    margin-bottom: 150px;
}
.vl {
    border-left: 1px solid #333;
    height: 18px;
    position: absolute;
    left: 50%;
    margin-left: 3px;
    margin-right: 5px;
    top: 12px;
}
md-toast .md-toast-content {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}
.duplication-subheader-helper {
    line-height: 1.75em;
    font-size: 16px;
    color: #004085 !important;
    background-color: #cce5ff !important;
    border: 1px solid #00408533 !important;
    border-radius: 4px;
}

.subheader-warning {
    line-height: 1.75em;
    font-size: 16px;
    color: #856404 !important;
    background-color: #fff3cd !important;
    border: 1px solid #ffeeba !important;
    border-radius: 4px;
}
.dynamic-list {
    padding: 10px;
    margin-bottom: 5px;
}
.task-import-job.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
}
.dynamic-list.failed,
.task-import-job.failed,
.form-export-template.failed {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}
.dynamic-list.warning,
.task-import-job.warning,
.form-export-template.warning {
    border: 1px solid #ffeeba;
    border-radius: 4px;
    color: #856404;
    background-color: #fff3cd;
}

.dynamic-list.pending,
.task-import-job.pending,
.form-export-template.pending {
    color: #004085;
    background-color: #cce5ff;
    border: 1px solid #b8daff;
    border-radius: 4px;
}
pre.inline {
    display: inline;
    padding: 1px;
}
.jodit-status-bar {
    display: none;
}
ol.form-export-template-creation-dialog-breadcrumb {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0;
    padding: 0;
    min-height: initial !important;
}

ol.form-export-template-creation-dialog-breadcrumb li.step {
    counter-increment: my-awesome-counter;
    color: #ccc;
    cursor: pointer;
    font-size: 14px;
}
ol.form-export-template-creation-dialog-breadcrumb li.step.completed {
    color: var(--primary-color) !important;
}
ol.form-export-template-creation-dialog-breadcrumb li.step::before {
    content: counter(my-awesome-counter);
    color: white;
    background: #ccc;
    border-radius: 50%;
    padding: 4px 8px;
    font-weight: bold;
}

ol.form-export-template-creation-dialog-breadcrumb li.step.current::before {
    content: counter(my-awesome-counter);
    color: white;
    background: var(--primary-color) !important;
}

ol.form-export-template-creation-dialog-breadcrumb li.step.current {
    content: counter(my-awesome-counter);
    color: var(--primary-color) !important;
}

ol.form-export-template-creation-dialog-breadcrumb li.step.completed::before {
    content: '✓';
    color: white;
    background: var(--primary-color) !important;
}

.form-export-template-creation-dialog-breadcrumb {
    color: #333;
    font-size: 12px;
    height: 55px;
    text-align: center;
}
.form-export-template-creation-dialog-content {
    margin: 20px 26px;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
}

.form-export-template-creation-dialog-content.note-batch-import {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 30px;
    margin: 0;
}
.form-export-template-creation-dialog-content .selector {
    border: 1px solid rgb(162, 162, 162);
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    max-width: 272px;
    color: black;
    opacity: 0.54;
    min-height: 100px;
}

.form-export-template-creation-dialog-content .selector.primary {
    color: black;
}

.form-export-template-creation-dialog-content .selector.selected {
    background-color: #cce5ff;
    border: 1px solid #b8daff;
    color: black;
    opacity: 0.72;
}
.ripple {
    position: relative;
}
.ripple:active > .wave {
    animation: ripple 0.25s;
}
.wave {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, #5797ff 10%, #cce5ff 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 0 0;
    top: 0;
    left: 0;
    transform: scale(0);
    opacity: 0;
}
@keyframes ripple {
    0% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
        opacity: 0.3;
    }
    100% {
        transform: scaleX(1);
        opacity: 0;
        background-size: 1000% 1000%;
    }
}
.form-export-creation-panel {
    padding: 20px;
    width: 75vw;
}
.noshadow {
    box-shadow: none;
}
.form-export-template-radio-button {
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}
.form-export-template-radio-button button {
    border: none;
    background-color: transparent;
    border-radius: 4px;
}
.form-export-template-radio-button img:not(.icon) {
    padding: 15px;
    margin-left: -10px;
}

md-checkbox[disabled] {
    cursor: default;
    color: black;
    opacity: 0.54;
}

.note-drawer-panel-sharing-help {
    color: #004085;
    background-color: #cce5ff;
    border: 1px solid #b8daff;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}
.note-drawer-panel-sharing-help md-checkbox {
    margin-bottom: 0px;
    margin-left: 1px;
}
.note-drawer-panel-sharing-help span.note-drawer-panel-sharing-help-text {
    margin-left: 9px;
    font-weight: bold;
    font-size: 14px;
}
.form-builder-card.form-field.large {
    /* padding-left: 30px;
    padding-right: 30px; */
}
.form-field-title-container {
    margin-bottom: 15px;
}
.form-builder-card.form-field .form-field-icon {
    color: var(--primary-color);
    opacity: 1 !important;
}
.form-builder-card.form-field .form-field-icon.field-dirty {
    color: black;
    opacity: 0.38;
}

.form-builder-card.form-field .form-field-icon.field-has-errors {
    color: rgb(213, 0, 0) !important;
    opacity: 1 !important;
}

.form-export-template-creation-dialog-content.ng-enter {
    transition: opacity 0.9s;
}

.form-export-template-creation-dialog-content.ng-enter,
.form-export-template-creation-dialog-content.ng-leave.ng-leave-active {
    opacity: 0;
}

.form-export-template-creation-dialog-content.ng-leave,
.form-export-template-creation-dialog-content.ng-enter.ng-enter-active {
    opacity: 1;
}
.dynamic-list-wrapper {
    position: relative;
    transition: height 0.3s ease-out;
}
.dynamic-list-input-wrapper > input {
    border: none;
    background: transparent;
    height: 40px;
    font-size: 19px;
    padding: 5px;
    margin: 0;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.dynamic-list-panel {
    height: 300px;
    width: 99%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    background-color: white;
    position: absolute;
    top: 52px;
    left: 5px;
    z-index: 100000;
    transition: top 0.3s ease-out;
    overflow-y: scroll;
}
.dynamic-list-input-wrapper {
    min-height: 50px;
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.md-input-invalid .dynamic-list-input-wrapper {
    border: 1px solid #d50000;
    background: #f5bfbf;
}

.md-input-invalid .dynamic-list-input-wrapper > input::-moz-placeholder {
    color: #d50000;
}

.md-input-invalid .dynamic-list-input-wrapper > input:-ms-input-placeholder {
    color: #d50000;
}

.md-input-invalid .dynamic-list-input-wrapper > input::placeholder {
    color: #d50000;
}
.dynamic-list-value-wrapper {
    padding-left: 5px;
    padding-bottom: 6px;
    padding-top: 6px;
    width: 100%;
}
.dynamic-list-value-wrapper .key {
    color: var(--primary-color);
    font-size: 12px;
}
.dynamic-list-value-wrapper .value {
    color: black;
    opacity: 0.72;
    font-size: 16px;
}
.dynamic-list-actions-panel {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.dynamic-list-example {
    opacity: 1;
}

.dynamic-list-example.ng-enter {
    transition: opacity 0.9s ease-out;
}

.dynamic-list-example.ng-enter,
.dynamic-list-example.ng-leave.ng-leave-active {
    opacity: 0;
}

.dynamic-list-example.ng-leave,
.dynamic-list-example.ng-enter.ng-enter-active {
    opacity: 1;
}

button.md-button.md-link-button {
    color: var(--secondary-color);
    font-size: 15px;
    line-height: 18px;
    text-transform: none;
    text-decoration: underline;
    font-weight: normal !important;
    outline: 0 !important;
}
button.md-button.md-link-button:hover {
    color: var(--primary-color);
    background-color: transparent !important;
}
em.em-title-primary {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}
#geolocate-on-map {
    /* z-index: 1000000; */
}
.search-result {
    color: var(--primary-color);
    font-weight: bold;
}
.form-template .md-list-item-inner {
    width: 80% !important;
}
button:disabled {
    background-color: #ccc !important;
    color: #fff !important;
    cursor: not-allowed !important;
}
label.duplication-count-label.duplication-count-label-invalid {
    color: rgb(213, 0, 0) !important;
}
.note-drawer-property-section .note-drawer-clickable-path-element {
    color: var(--secondary-color);
    font-weight: bold;
    cursor: pointer;
}
.note-drawer-property-section .note-drawer-clickable-path-element:hover {
    text-decoration: underline;
    color: var(--primary-color);
}
md-dialog.note-batch-creation-dialog {
    z-index: 1201 !important;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}
md-dialog.note-batch-creation-dialog .md-actions,
md-dialog.note-batch-creation-dialog md-dialog-actions {
    padding: 0px 8px;
}
md-dialog.note-batch-creation-dialog .md-actions .md-button,
md-dialog.note-batch-creation-dialog md-dialog-actions .md-button {
    margin: 0;
}

.note-batch-creation-dialog .element-table {
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    overflow-x: scroll;
}
.note-batch-creation-dialog .element-table-column {
    margin: 0;
    padding: 10px;
    background-color: #fff;
    min-width: 200px;
    position: relative;
}
.note-batch-creation-dialog .element-table-column.add-column {
    min-width: 40px;
    position: fixed;
    right: 62px;
    height: 112px;
    background-color: rgba(0, 0, 0, 0.36);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}
.note-batch-creation-dialog .element-table-column.add-column:hover {
    background-color: rgba(0, 0, 0, 0.73);
}
.note-batch-creation-dialog .element-table-column .remove-button {
    position: absolute;
    right: -3px;
    top: 0px;
    margin: 0;
    color: darkred;
}

.note-batch-creation-dialog .element-table-column:nth-child(even) {
    background-color: #eee;
}

md-button[disabled] {
    cursor: default;
    color: black;
    opacity: 0.54;
}

button.md-button.md-button-sibling {
    border-radius: 0%;
    height: 36px;
    min-width: 28px;
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
button.md-button.md-button-sibling.md-button-sibling-left {
    margin-right: 0 !important;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

button.md-button.md-button-sibling.md-button-sibling-right {
    margin-left: 1px !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.sign-container .sign-canvas {
    z-index: 10000;
}
.category-header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
.md-button-group .md-button-group-member {
    font-size: 12px;
    margin: 5px 0;
    padding: 3px 10px 3px 10px;
    background-color: var(--primary-font-color);
    color: #333;
    text-transform: none;
    font-weight: 400;
    line-height: 28px;
    min-width: 113px;
}

.md-button-group .md-button-group-member.left {
    border-radius: 10px 0 0 10px;
}

.md-button-group .md-button-group-member.middle {
    border-radius: 0;
}

.md-button-group .md-button-group-member.right {
    border-radius: 0 10px 10px 0;
}

.md-button-group .md-button-group-member:not([disabled]):hover {
    color: var(--primary-font-color);
    background-color: var(--secondary-color);
    transition: 0.3s;
}

.md-button-group .md-button-group-member.active {
    color: var(--primary-font-color);
    background-color: var(--primary-color);
}

.sharing-table-header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 1px solid #eee;
    font-size: 18px;
    margin-top: 5px;
}

.project-template-users-onboarding {
    line-height: 1.75em;
    font-size: 16px;
    color: #004085 !important;
    background-color: #cce5ff !important;
    border: 1px solid #00408533 !important;
    border-radius: 4px;
    padding: 15px;
}

.report-template-empty-state {
    height: calc(100vh - 123px);
}
.report-template-sharing-card {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 20px;
    border-left: 2px solid #1deaa4;
    margin-left: 30px;
}
.report-template-sharing-card::before {
    content: '';
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    top: 16px;
    left: -12px;
}
#project-users-list-fab {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2%;
    transition: bottom 0.3s ease-in-out;
    -webkit-transition: bottom 0.3s ease-in-out;
    -moz-transition: bottom 0.3s ease-in-out;
    -o-transition: bottom 0.3s ease-in-out;
    -ms-transition: bottom 0.3s ease-in-out;
}
#project-users-list-fab.transition-hide-down {
    bottom: -550px;
    transition: bottom 0.3s ease-in-out;
    -webkit-transition: bottom 0.3s ease-in-out;
    -moz-transition: bottom 0.3s ease-in-out;
    -o-transition: bottom 0.3s ease-in-out;
    -ms-transition: bottom 0.3s ease-in-out;
}
.dynamic-list-help-button {
    color: var(--primary-color);
}
.dynamic-list-help-panel-content a {
    color: var(--secondary-color);
    padding: 8px;
}
.dynamic-list-help-panel-content p {
    padding: 8px;
    margin: 0;
}
.dynamic-list-help-panel-content table {
    padding: 8px;
    margin: 0;
}
.dynamic-list-help-panel-content tr,
.dynamic-list-help-panel-content td {
    padding: 8px;
    margin: 0;
}

.dynamic-list-help-panel-content td.code {
    font-weight: bold;
}
.cookie-consent-banner {
    background-color: var(--primary-color);
}
.cookie-consent-banner-accept-button {
    background-color: var(--primary-color);
}
.app-switcher-menu-panel {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    height: 100%;
}

.app-switcher-menu-panel-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 340px;
    padding: 10px;
}

.app-switcher-menu-panel-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #e9e9e9;
    cursor: pointer;
    padding: 5px;
    margin: 10px;
    border-radius: 4px;
    font-size: 14px;
}

.app-switcher-menu-panel-header:hover {
    background-color: #e5e5e5;
}

.app-switcher-menu-panel-header div {
    color: #005378;
}
.app-switcher-menu-panel-header .app-switcher-menu-panel-menu-icon {
    font-size: 16px;
}

.app-switcher-menu-panel-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}
a.app-switcher-menu-panel-item:focus {
    text-decoration: none;
}
.app-switcher-menu-panel-item:hover,
.app-switcher-menu-panel-item.active {
    background-color: #e9e9e9;
}

.app-switcher-menu-panel-item:last-child {
    margin-bottom: 0;
}

.app-switcher-menu-panel-icon {
    width: 40px;
    height: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    color: #ffffff;
    font-size: 20px;
    margin-right: 15px;
    -ms-flex-negative: 0;
        flex-shrink: 0; /* Prevent shrinking */
}

.app-switcher-menu-panel-eProject-icon {
    background-color: #00bcd4;
}

.app-switcher-menu-panel-mezzoteam-icon {
    background-color: #00acc1;
}

.app-switcher-menu-panel-bulldozair-icon {
    background-color: #00838f;
}

.app-switcher-menu-panel-text {
    -ms-flex: 1;
        flex: 1;
}

.app-switcher-menu-panel-text h2 {
    font-size: 16px;
    font-weight: 900;
    margin: 0 0 5px 0;
    color: #005378;
}

.app-switcher-menu-panel-text p {
    font-size: 14px;
    margin: 0;
    color: black;
    opacity: 0.54;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
    /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
    line-height: 30px;
    color: black;
    font-weight: bold;
}
.form-creation-drawer-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 100vh;
}
.form-creation-toolbar {
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.form-content-container {
    -ms-flex-positive: 1;
        flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
}
.form-toolbar-container {
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.bulldozair-annotation {
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    cursor: pointer;
    margin-top: -52px;
    margin-left: -17.5px;
    width: 35px;
    height: 52px;
}
.bulldozair-annotation .bulldozair-annotation-label {
    background: white;
    padding: 2px;
    border-radius: 4px;
    width: 35px;
    text-align: center;
}
.bulldozair-annotation .bulldozair-annotation-label.strike-through {
    text-decoration: line-through;
}
.bulldozair-annotation.grabbing {
    cursor: grabbing;
}
.bulldozair-annotation:focus {
    border: 2px solid var(--color-high);
    background-color: var(--color-warning);
}
.sso-authentication-error-container {
    background: var(--secondary-color);
    background: linear-gradient(135deg, var(--secondary-color) 0%, var(--primary-color) 100%);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 100vh;
    width: 100vw;
}

.sso-authentication-error-centered {
    width: 100%;
    background: #eee;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

@media (min-width: 768px) {
    .sso-authentication-error-centered {
        width: auto;
        min-width: 33vw;
    }
}

.cookie-consent-banner,
.cookie-consent-modal {
    -webkit-font-smoothing: antialiased;
    font-family: 'Work Sans', sans-serif;
}
.cookie-consent-banner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    position: fixed;
    transform: translateY(50px);
    bottom: 0;
    opacity: 0;
    width: 100%;
    min-height: 70px;
    background-color: var(--primary-color);
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
    transition: transform .4s ease;
}

.cookie-consent-banner.banner-show {
    transform: translateY(0px);
    opacity: 1;
    z-index: 10;
}

.cookie-consent-banner-message {
    text-align: center;
    vertical-align: middle;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 600;
}

.cookie-consent-banner > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-right: 30px;
}

.cookie-consent-banner button,
.cookie-consent-modal button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    text-align: center;
    font-style: normal;
    color: #fff;
    text-transform: uppercase;
    border: none;
    margin-left: 0.4rem;
    line-height: 1px;
}

.cookie-consent-banner button {
    font-size: 13px;
    font-weight: 800;
    height: 35px;
    width: 130px;
    margin-left: 20px;
    text-transform: none;
    letter-spacing: 0.0357143em;
}
.cookie-consent-banner button:hover {
    opacity: 1;
}
.cookie-consent-banner-accept-button {
    width: 130px;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;
}
.cookie-consent-banner-personnalise-button {
    background-color: transparent !important;
    color: #fff;
}
.cookie-consent-banner-personnalise-button:hover {
    text-decoration: underline;
}

@media (max-width: 916px) {
    .cookie-consent-banner {
        -ms-flex-pack: center;
            justify-content: center;
        box-sizing: border-box;
        padding: 12px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .cookie-consent-banner-message {
        margin-left: 0;
        margin-bottom: 10px;
    }
    .cookie-consent-banner > div {
        margin: 0
    }
    .cookie-consent-banner button {
        margin-left: 0;
        height: 30px;
    }
}

.cookie-consent-overlay {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 888888;
    background-color: #000;
    opacity: 0.25;
    visibility: hidden;
    transition: all .2s ease;
}

.cookie-consent-modal {
    z-index: 9999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    background-color: #F3F3F3;
    color: #000;
    border-radius: 3px;
    height: 600px;
    width: 800px;
    text-align: center;
    visibility: hidden;
    opacity: 0;;
    transition: all .2s cubic-bezier(.25, .46, .45, .94);;
    overflow-y: hidden;
    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.cookie-consent-modal svg {
    stroke: #000;
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.9;
    cursor: pointer;
}
.cookie-consent-modal svg:hover {
    opacity: 1;
}

.cookie-consent-modal-show {
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}
.cookie-consent-modal .cc-title,
.cookie-consent-modal .cc-description {
    margin-bottom: 30px;
}
.cookie-consent-modal .cc-title {
    display: block;
    font-weight: 600;
    font-size: 32px;
}

.cookie-consent-description.scroll-shadow {
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
}

.cookie-consent-modal p.cc-description, p.category-description {
    font-weight: 400;
    color: #000;
    font-size: 14px;
}
.cookie-consent-modal .cc-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.cc-buttons button {
    background: linear-gradient(0deg, rgba(79, 79, 79, 0.26), rgba(79, 79, 79, 0.26));
    color: #fff;
    height: 27px;
    min-width: 120px;
    max-width: 150px;
    line-height: 1px;
    opacity: 0.9;
    font-weight: 600;
    font-size: 11px;
}
.cc-buttons button:hover {
    opacity: 1
}

.categories-wrapper {
    overflow: auto;
    padding: 50px;
}

.category-and-services {
    position: absolute;
    text-align: left; 
    padding-top: 10px;
    margin-bottom: 15px;
    display: none;
}

.divider {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin-top: -4px;
    margin-bottom: 12px;
}

.category .category-heading {
    display: block;
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 5px;
}

.category-and-services .service {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin-bottom: 12px;
}

.service-heading > a {
    color: #000;
    text-decoration: none !important;
    border-bottom: 1px solid #000;
    transition: all .3s ease;
}

.service .service-heading {
    font-size: 14px;
    font-weight: 500;
    width: 200px;
}

@media (max-width: 800px) {
  .cookie-consent-modal {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
  }
}

.cc-buttons .cc-allow-all-btn {
    background-color: #5fc591;
}
.cc-buttons .cc-deny-all-btn {
    background-color: #d9534f;
}

.material-toggle {
	display: block;
}
.material-toggle input[type="checkbox"] {
	display: none;
}
.material-toggle label {
	position: relative; 
	cursor: pointer;
	height: 0px;
	width: 40px; 
    background: linear-gradient(0deg, rgba(79, 79, 79, 0.26), rgba(79, 79, 79, 0.26));
    background-color: #5fc591;
}
.material-toggle > label::before {
	position:absolute;
    margin-top: -4px;
    height: 14px;
	width: 40px;
	content: '';
	background: rgb(0, 0, 0);
	box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
	border-radius: 8px;
	opacity: 0.3;
	transition: all 0.3s ease;
}
.material-toggle > label::after {
    position: absolute;
    margin-top: -2px;
    top: -5px;
    left: 0px;
    height: 20px;
    width: 20px;
    content: '';
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
}
.material-toggle > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-toggle > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}
